<ptg-dialog class="edit-payment-status flex flex-col" 
  [class.adj-component]="isShowAdjComponent"
  [title]="isShowAdjComponent ? '' : dialogTitle" 
  [isFullScreen]="true"
>
  <!-- DIALOG CONTENT HEADER -->
  <ng-template dialogContentHeader *ngIf="!isShowAdjComponent">
    <ptg-payment-header
      [targetIdFromDialog]="data.memberId ?? ''"
      [isShowInteractionComponents]="false"
    ></ptg-payment-header>
  </ng-template>

  <!-- DIALOG CONTENT BODY -->
  <ng-template dialogBody>
    <div class="header" *ngIf="isShowAdjComponent">
      <span class="title">Edit Status</span>
    </div> 
    <div class="flex flex-col gap-[34px]">
      <div class="flex gap-2" *ngIf="!isShowAdjComponent">
        <ptg-button
          classInput="primary"
          buttonName="Save"
          (clickButton)="onSave()"
          [isDisabled]="errorOccurs"
        ></ptg-button>
        <ptg-button classInput="tertiary" buttonName="Cancel" (clickButton)="onCancel()"></ptg-button>
      </div>

      <ng-container *ngIf="!errorOccurs">
        <div class="flex gap-4" *ngIf="!isShowAdjComponent">
          <div class="flex flex-col gap-4">
            <ptg-checkbox
              label="Suspend"
              [controlField]="isSuspendedControl"
              [isDisabled]="isDisabledSuspendCheckbox"
              customError="customError"
              asyncError="inValidAsync"
              *ngIf="isShownSuspendCheckbox"
            ></ptg-checkbox>

            <div class="flex gap-4" *ngIf="isShownSuspendReason">
              <ptg-select
                placeholder="Suspension Reason"
                class="w-[327px]"
                [controlField]="suspensionReasonControl"
                [isSetMaxWidthSelect]="true"
                [isOptionObj]="true"
                [listData]="suspensionReasonList"
                [isClearError]="false"
                errorRequire="Suspension Reason is required."
              ></ptg-select>

              <ptg-button
                classInput="icon-only"
                [isDisabled]="!suspensionReasonControl.value"
                (clickButton)="onAddChip()"
              >
                <mat-icon class="add-button">add_circle</mat-icon>
              </ptg-button>
            </div>

            <ptg-toggle-button
              label="Create Payment For Suspended Periods"
              [controlField]="isCreatePaymentControl"
              [disabled]="isDisabledCreatePaymentToggle"
              customError="customError"
              *ngIf="isShownCreatePayment"
            ></ptg-toggle-button>

            <ptg-checkbox
              label="Terminate"
              [controlField]="isTerminatedControl"
              [isDisabled]="isDisabledTerminateCheckbox"
              customError="customError"
              *ngIf="isShownTerminateCheckbox"
            ></ptg-checkbox>

            <ptg-datepicker
              class="w-[327px]"
              (changeValue)="onChangeBenefitEndDate($event)"
              *ngIf="isRecurring"
              [controlField]="benefitEndDateControl"
              [isDisabled]="isDisabledBenefitEndDate"
              [maxDate]="maxEndDate"
              [errorMaxDate]="'Benefit End Date is invalid.'"
              [customError]="'customError'"
              placeholder="Benefit End Date"
            ></ptg-datepicker>
            <ptg-select
              *ngIf="isRecurring"
              placeholder="Last Payroll Cycle"
              class="w-[327px]"
              [controlField]="lastPayrollCycleControl"
              [isSetMaxWidthSelect]="true"
              [isOptionObj]="true"
              [listData]="lastPayrollCycleDateList"
              [hasNoneValue]="!isTerminatedControl?.value"
              [isRequired]="isTerminatedControl?.value"
              [errorRequire]="isTerminatedControl?.value ? 'Last Payroll Cycle is required.' : ''"
              [customError]="'customError'"
              [compareWithFunction]="compareWithFunction"
            ></ptg-select>

            <ptg-select
              placeholder="Termination Reason"
              class="w-[327px]"
              [controlField]="terminationReasonControl"
              [isSetMaxWidthSelect]="true"
              [isOptionObj]="true"
              [listData]="terminationReasonList"
              [isClearError]="false"
              errorRequire="Termination Reason is required."
              *ngIf="isShownTerminationReason"
            ></ptg-select>
          </div>
          <div class="mt-[43px]">
            <mat-chip-list *ngIf="suspensionReasonChipArray.length">
              <mat-chip *ngFor="let chip of suspensionReasonChipArray.controls; index as i" (removed)="onRemoveChip(i)">
                <span class="truncate">{{ chip.value?.displayValue }}</span>
                <button matChipRemove><mat-icon>close</mat-icon></button>
              </mat-chip>
            </mat-chip-list>
          </div>
        </div>
        <!-- for adjustment -->
        <div class="flex flex-col gap-4 adjustment" *ngIf="isShowAdjComponent">
          <ptg-checkbox
            label="Approve"
            [controlField]="isApprovedControl"
            [isDisabled]="isDisabledApprovedCheckbox"
            customError="customError"
            (changeValue)="onSelectApproved($event)"
          ></ptg-checkbox>
          <ptg-checkbox
            label="Reject"
            [controlField]="isRejectedControl"
            [isDisabled]="isDisabledRejectedCheckbox"
            customError="customError"
            (changeValue)="onSelectRejected($event)"
          ></ptg-checkbox>
          <ptg-input-area
            *ngIf="isShowAdjReason"
            [controlField]="adjReasonControl"
            placeholder="Reason"
            class="reason-input-area"
            [maxLength]="4000"
            errorMaxLength="Exceed the 4000 character limit."
            [minRows]="5"
            [maxRows]="5"
          ></ptg-input-area>
          <div *ngIf="adjustmentError" class="adj-error">
            {{ adjustmentError }}
          </div>
        </div>
      </ng-container>
    </div>
  </ng-template>

  <ng-template dialogFooter *ngIf="isShowAdjComponent">
    <div class="wrap-btn">
      <ptg-button
        classInput="primary"
        buttonName="Save"
        (clickButton)="onSave()"
        [isDisabled]="errorOccurs"
      ></ptg-button>
      <ptg-button classInput="tertiary" buttonName="Cancel" (clickButton)="onCancel()"></ptg-button>
    </div>
  </ng-template>
</ptg-dialog>
