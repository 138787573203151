import { AbstractControl, AsyncValidatorFn, ValidationErrors } from '@angular/forms';
import { EntityPropertyType } from '@ptg-entity-management/types/enums';
import { getValueWithoutFormat } from '@ptg-shared/utils/string.util';
import { Observable, of, timer } from 'rxjs';
import { finalize, map, switchMap } from 'rxjs/operators';

export function checkApiValidator(
  serviceFn: any,
  field: string,
  editValue?: string,
  configs?: {ignoreCase?: boolean, params?: any, typeControl?: any, responseKey?: string},
  finishedFunc?: (result?: any) => void,
): AsyncValidatorFn {
  // Need obs to call api for check
  return (control: AbstractControl): Observable<ValidationErrors | null> => {
    if (editValue && editValue.trim() === control.value.trim()) {
      return of(null);
    }
    if (configs?.ignoreCase && editValue && editValue.toLowerCase().trim() === control.value.toLowerCase().trim()) {
      return of(null);
    }

    let valueCheck = control.value?.toString()?.trim();
    if(configs?.typeControl === EntityPropertyType.Phone) {
      valueCheck = getValueWithoutFormat(control.value)
    }

    return timer(300).pipe(
      switchMap((): Observable<ValidationErrors | null> => {
        let result: any;
        return serviceFn({
          [field]: valueCheck,
          ...configs?.params,
        }).pipe(
          map((response: any) => {
            result = response;
            if (
              response &&
              (response.exists ||
                response.isExisted ||
                response.isExists ||
                response.isExist ||
                response.isValid === false ||
                response.currentExists ||
                response.invalid ||
                response[configs?.responseKey ?? '']
              )
            ) {
              return { inValidAsync: true, message: response?.message };
            }
            return null;
          }),
          finalize(() => {
            if (finishedFunc) finishedFunc(result);
          })
        )
      })
    );
  };
}

export function checkApiValidatorGetMessage(
  serviceFn: any, 
  field: string, 
  editValue?: string, 
  configs?: {ignoreCase?: boolean, params?: any, typeControl?: any, responseKey?: string},
  finishedFunc?: (result?: any) => void,): AsyncValidatorFn {
  // Need obs to call api for check
  return (control: AbstractControl): Observable<ValidationErrors | null> => {
    if (editValue && editValue.trim() === control.value.trim()) {
      return of(null);
    }
    let valueCheck = control.value?.toString()?.trim();
    return timer(300).pipe(
      switchMap((): Observable<ValidationErrors | null> =>
        serviceFn({
        [field]: valueCheck,
        ...configs?.params,
        }).pipe(
          map((response: any) => {
            if (response && !response.isValid) {
              return { inValidAsync: response?.message };
            }
            return null;
          }),
          finalize(() => {
            if (finishedFunc) finishedFunc();
          })
        )
      )
    );
  };
}
