<div class="register-container" [ngClass]="{ 'has-banner': bannerType }">
  <div class="header-container">
    <div class="filter-select-container">
      <button mat-button class="filter-button" (click)="expanedFilter = !expanedFilter">
        <div class="filter-button-content">
          <mat-icon>filter_alt</mat-icon>
          <mat-icon>arrow_drop_down</mat-icon>
        </div>
      </button>
    </div>
    <ptg-breadcrumb
      [listBreadcrumbs]="listBreadcrumbs"
      [functionButtons]="functionButtons"
      (emitFunction)="emitFunction($event)"
    ></ptg-breadcrumb>
  </div>

  <div class="register-content flex flex-col">
    <div *ngIf="bannerType" class="p-6">
      <ptg-banner [(bannerType)]="bannerType" [message]="message"></ptg-banner>
    </div>
    <div class="member-profile-container" *ngIf="payeeRecordId">
      <ptg-overview-header-entity
      ></ptg-overview-header-entity>
    </div>
    <div class="filter-container">
      <ptg-register-filter [expanedFilter]="expanedFilter" [payeeRecordId]="payeeRecordId" [isConfigOST]="isConfigOST"></ptg-register-filter>
    </div>
    <div class="expanded-container">
      <div class="expaned-list">
        <ptg-toggle-button *ngIf="!hideComponentForFirstTime" label="Expanded" [controlField]="expandedControl"></ptg-toggle-button>
      </div>
      <div class="filter-buttons" *ngIf="expanedFilter">
        <ptg-button classInput="primary" [isDisabled]="!registerFilterComponent" buttonName="Apply" (click)="applyFilter()"></ptg-button>
        <ptg-button classInput="secondary" [isDisabled]="!registerFilterComponent" buttonName="Clear Filter" (click)="resetFilter()"></ptg-button>
      </div>
    </div>
    <div class="flex-grow overflow-hidden" *ngIf="!hideComponentForFirstTime">
      <ng-container *ngIf="!expandedControl.value; else expandedView">
        <ptg-grid #gridRegister [data]="registerData" [columns]="columns" [errorMessage]="errorMsg"
          [isLoading]="isLoading" [totalRecords]="totalRecords" [fitToParent]="true" [pageSize]="pageSize"
          [hideScrollbar]="false" [pageNumber]="pageNumber" (pageChange)="onChangePage($event)"
          (sortChange)="onChangeSort($event)" keyColumn="id" [notFoundMessage]="notFoundMessage"
          [matSortActive]="sortInfo?.active" [matSortDirection]="sortInfo?.direction">
          <ng-template cellContent columnName="status" let-row>
            <div *ngIf="row.statusColumnStyle.iconName" #tooltip="matTooltip"
              [matTooltip]="row.statusColumnStyle?.tooltipMessage"
              [matTooltipDisabled]="!row.statusColumnStyle?.tooltipMessage"
              [ngClass]="row.statusColumnStyle?.tooltipMessage ? 'cursor-pointer' : ''"
              (click)="row.statusColumnStyle?.hasClickEvent ? showErrorPopup(row) : null" matTooltipPosition="below"
              class="icon-status-container w-fit" [matTooltipClass]="
                'custom-tooltip with-arrow !max-w-[40vw]' +
                (sidebarOpenedStatus === SidebarOpenedStatus.Opened
                  ? ''
                  : ' before:!left-[24%]')
              ">
              <mat-icon [style]="{ color: row.statusColumnStyle.color }">{{ row.statusColumnStyle.iconName }}</mat-icon>
              <div *ngIf="row.statusColumnStyle?.hasError" class="status-error-icon">
                <span><strong>&#33;</strong></span>
              </div>
            </div>
          </ng-template>
          <ng-template cellContent columnName="benefitPeriod" let-row>
            <ng-container
              *ngIf="row.startDate && row.endDate && row.paymentInstructionType !== PaymentInstructionType.PeriodicLumpsumPayment; else benefitPeriodEmpty">
              <span>{{ row?.startDate | date: DATE_FORMAT }} - {{ row?.endDate | date: DATE_FORMAT }}</span>
            </ng-container>
            <ng-template #benefitPeriodEmpty>-</ng-template>
          </ng-template>
          <ng-template cellContent columnName="datePosted" let-row let-col="col">
            <ptg-datetime-column
              [value]="getDateString(row.adjustmentType !== null ? row.postToRegister : row.datePosted)"
              [format]="col.templateArgs?.format || 'MM/dd/yyyy'"
              [emptyString]="col.templateArgs?.emptyString || ''"
            ></ptg-datetime-column>
          </ng-template>
          <ng-template cellContent columnName="amount" let-row let-col="col">
            {{ (row.adjustmentType !== null ? row.netAmount : row.amount) || 0 | numberLocalDecimal: '$' : { accountingFormat: true } }}
          </ng-template>
        </ptg-grid>
      </ng-container>
      <ng-template #expandedView>
        <ptg-register-expanded [registerData]="registerData" [totalRecords]="totalRecords" [maxLength]="maxLength" [pageSize]="pageSize"
          [pageNumber]="pageNumber" (pageChange)="onChangePage($event)" [errorMessage]="errorMsg"
          [notFoundMessage]="notFoundMessage" [isLoading]="isLoading" [showErrorPopup]="showErrorPopup"
          [sidebarOpened]="sidebarOpenedStatus === SidebarOpenedStatus.Opened" (showBanner)="showBanner($event)"
          [payeeRecordId]="payeeRecordId" [isConfigOST]="isConfigOST">
        </ptg-register-expanded>
      </ng-template>
    </div>
  </div>
</div>
