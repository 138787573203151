export const MODULE_KEY = 'processingModule';

export const northernTrustBankAccount = '071000152';

export const chicagoParkDepositDate: Date = new Date(2023, 5, 1);

export const ProcessingMessage = {
  // payroll
  CommonProcessingPayments: 'Processing payments. Please wait for a few seconds.',
  PayrollUpdateTransactionFail: 'Error occurred updating Transactions. Please try again',
  PayrollUpdateTransaction:
    'Updating transactions for the current Payroll could take some time. System will send a notification email when it completes.',
  PayrollFinalize:
    'Finalizing the current Payroll could take some time. System will send a notification email when it completes.',
  PayrollSendToBank:
    'The payments were submitted, and the system is waiting for confirmation from the bank. This payroll will be finalized when this information is received.',
  PayrollSendToBankError:
    'Error occurred submitting to bank. Please try again.',
    PayrollResetting: 'Resetting the current recurring payroll run. It will take some time.',
  PayrollResetSuccess: 'Recurring payroll run successfully reset.',
  PayrollResetFailed: 'Error occurred resetting recurring payroll run. Please try again.',
  PayrollCancelling: 'Cancelling the current recurring payroll run. You will be taken to Next Payroll List screen once completed.',
  PayrollCancelled: 'Recurring payroll run cancelled successfully.',
  ErrorIssuePayment: 'Error occurred issuing Payments. Please try again',
  MissingPaymentAddress: 'Missing Payment Address',
  MissingRepresentative: 'Missing Representative Info',
  // service provider
  ServiceProviderUpdateTransaction:
    'Updating transactions for the current Service Provider Payment could take some time. System will send a notification email when it completes.',
  ServiceProviderFinalize:
    'Finalizing the current Service Provider Payment could take some time. System will send a notification email when it completes.',
  ServiceProviderSendToBank:
    'The payments were submitted, and the system is waiting for confirmation from the bank. This Service Provider Payment will be finalized when this information is received.',
  // off-cycle
  OffCycleSelectBank: 'Processing bank information.',
  OffCycleFinalize:
    'Finalizing the current Payments could take some time. You will be redirected back to View off-cycle Payment screen when it is finished.',
  OneTimePaymentSendToBank:
    'The payments were submitted, and the system is waiting for confirmation from the bank. These One time payments will be finalized when this information is received.',
  OneTimePaymentFinalize: 'Finalizing the current Payments could take some time. You will be redirected back to View One-time Payment screen when it is finished.',
  OffCycleCancel:
    'Canceling the Payment order. You will be redirected back to View off-cycle Payment screen when it is finished.',
  OneTimeCancelling: 'Canceling the One-Time Payment run. You will be redirected back to One-Time Payment screen when it is finished.',
  OneTimeCancellingError: 'Error occurred cancelling One-Time Payment run. Please try again.',
  OneTimeCancelled: 'One-Time Payment run cancelled successfully.',
  CheckAndDirectDepositPaymentSendToBank: 'The payments were submitted, and the system is waiting for confirmation from the bank.  These Deduction Payee payments will be finalized when this information is received.',
  CheckAndDirectDepositPaymentFinalize: 'Finalizing the current Payments could take some time. You will be redirected back to View Deduction Payment screen when it is finished.',
  CheckAndDirectDepositPaymentFinalizeError: 'Error occurred finalizing Deduction Payee Payments. Please try again.',
  CheckAndDirectDepositPaymentCancelling: 'Cancelling the Deduction Payee Payment run. You will be redirected back to previous screen when it is finished.',
  CheckAndDirectDepositPaymentCancellingError: 'Error occurred cancelling Deduction Payee Payment run. Please try again.',
  CheckAndDirectDepositPaymentCancelled: 'Deduction Payee Payment run cancelled successfully.',
  OstSubmittedToBankError: 'The payments had been sent to OST but error had occurred with some files, please resend them in Payment Files Register.',
  ConfirmFinalize: 'There are unresolved errors when sending payments. Are you sure you want to finalize this process?',
  SubmittingToBank: 'System is processing the payments. This could take some time.'
};

export const TransactionTypeList = ['Benefit Payee', 'Deduction Payee'];
