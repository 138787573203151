import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { InputType } from '@ptg-member/constance/metadataPropertyType.const';
import { BaseComponent } from '@ptg-shared/components';
import { MAX_VALUE_NUMBER } from '@ptg-shared/constance';
import { BannerType } from '@ptg-shared/controls/banner/types/banner.model';
import { showBanner, showCancelDialog } from '@ptg-shared/utils/common.util';
import { CourtOrderDeduction, EarningInfos, GetDeductionFundingSourcesResponse, SubType } from '../../services/models';
import { AbstractControl, FormArray, FormControl, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import { Option } from '@ptg-shared/controls/select/select.component';
import { clearEditDeductionAsFundingSourceStateAction, editDeductionAsFundingSourceAction, getDeductionDataAction } from '../../store/actions';
import { editDeductionFundingSourcesSelector } from '../../store/selectors/edit-funding-source.selector';
import { filter, map, switchMap, take, takeUntil } from 'rxjs/operators';
import { DeductionSubType, DeductionType, DeductionTypeRevert, TaxSettingType } from '../../types/enums';
import { ConfirmPopupComponent } from '@ptg-shared/controls/confirm-popup/confirm-popup.component';
import { ConfirmType } from '@ptg-shared/constance/confirm-type.const';
import { Observable, of, timer } from 'rxjs';
import { AddOneTimeService } from '../../services';
import { deductionDataSelector } from '../../store';
import { DatePipe } from '@angular/common';
import { CONTROL_NAME_QDRO, CREATE_OFFSET_DEDUCTION_LABEL } from '../../types/constants';
import { RadioOption } from '@ptg-shared/controls/radio-button/radio-button.component';

const datePipe = new DatePipe('en-US');

@Component({
  selector: 'ptg-edit-deduction-funding-source',
  templateUrl: './edit-deduction-funding-source.component.html',
  styleUrls: ['./edit-deduction-funding-source.component.scss'],
})
export class EditDeductionFundingSourceComponent extends BaseComponent {
  // Default constants
  readonly InputType = InputType;
  readonly minValueForCurrency = 0;
  readonly MAX_VALUE_NUMBER = MAX_VALUE_NUMBER;
  readonly DeductionTypeRevert = DeductionTypeRevert;
  readonly DeductionType = DeductionType;
  readonly CREATE_OFFSET_DEDUCTION_LABEL = CREATE_OFFSET_DEDUCTION_LABEL;

  // Banner
  bannerType: BannerType = BannerType.Hidden;
  message = '';

  // Loading
  isLoading = false;

  DeductionLabels: Record<DeductionType, string> = DeductionTypeRevert;

  dialogTitle = '';

  listChip: Option[] = [];

  deductionFormGroup = new FormGroup({
    deductionType: new FormControl('', { validators: this._requiredChip('Deduction Type') }),
  });

  listInsuranceDeduction: Option[] = [];

  listOthersDeduction: Option[] = [];

  listDeductionPayee: Option[] = [];

  listDeductionType: Option[] = [];

  listCourtOrder: Option[] = [];

  listCourtOrderQdro: Option[] = [];

  postOrPretaxOptions: RadioOption[] = [
    {
      label: 'Pre Tax',
      value: TaxSettingType.PreTax,
    },
    {
      label: 'Post Tax',
      value: TaxSettingType.PostTax,
    },
  ];

  chipNotRemove: any = {};

  // Saved deductions
  listDeductionLinked = {
    tax: [] as SubType[],
    insurance: [] as SubType[],
    others: [] as SubType[],
    garnishments: [] as SubType[],
    qdro: [] as SubType[],
  };

  offsetPaymentDeductionId?: string;

  cancelStatus = 5;

  amount: {
    grossPayment: number;
    totalDeductions: number;
    netPayment: number;
  } = {
    grossPayment: this.data.earningInfo?.grossPayment ?? 0,
    totalDeductions: this.data.earningInfo?.totalDeductions ?? 0,
    netPayment: this.data.earningInfo?.netPayment ?? 0,
  };

  constructor(
    private readonly dialog: MatDialog,
    private readonly dialogRef: MatDialogRef<EditDeductionFundingSourceComponent>,
    private store: Store,
    @Inject(MAT_DIALOG_DATA) public data: { earningInfo: EarningInfos },
    private addOneTimeService: AddOneTimeService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.setTitle();
    this.store.dispatch(
      getDeductionDataAction({
        benefitCode: this.data.earningInfo?.selectedHeaderBenefit?.benefitId || '',
        memberId: this.data.earningInfo?.memberId,
        queryParams: {
          startDate: datePipe.transform(this.data.earningInfo?.benefitPeriodStartDate, 'yyyy-MM-dd') || '',
          endDate: datePipe.transform(this.data.earningInfo?.benefitPeriodEndDate, 'yyyy-MM-dd') || '',
          benefitTypeName: this.data.earningInfo?.selectedHeaderBenefit?.benefitTypeName,
          isDeductionAsFundingSource: true,
          paymentInstructionId: this.data.earningInfo?.paymentInstructionId,
        },
      }),
    );
    this.store
      .select(deductionDataSelector)
      .pipe(
        filter((res) => !!res && !res.isLoading && !!res?.payload),
        take(1),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((res) => {
        this.setList(res.payload!);
        this.setFormValueInit(res.payload!);
        this.offsetPaymentDeductionId = res.payload!.offsetPaymentDeductionId;
        this.checkFormValueChanges();
      });
    this.selectorSaveEditEarning();
  }

  private checkFormValueChanges() {
    this.deductionFormGroup.valueChanges
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((value) => {
      this.getCalculatedAmount();
    });
  }

  private getCalculatedAmount() {
    const stateTaxAmount = this.taxFormGroup?.get('stateTax')?.value ?? 0;
    const federalTaxAmount = this.taxFormGroup?.get('federalTax')?.value ?? 0;
    const grossPayment =
      stateTaxAmount +
      federalTaxAmount +
      this.getTotalAmount(this.garnishmentsFormArray) +
      this.getTotalAmount(this.insuranceFormArray) +
      this.getTotalAmount(this.othersFormArray) +
      this.getTotalAmount(this.qdroFormArray);
    const netPayment = grossPayment - this.amount.totalDeductions;
    this.amount = {
      ...this.amount,
      grossPayment,
      netPayment,
    };
  }

  getTotalAmount(formArray: FormArray) {
    return (formArray?.getRawValue() ?? []).reduce((result, item) => {
      result += item.amount ?? 0;
      return result;
    }, 0);
  }

  selectorSaveEditEarning() {
    this.store
      .select(editDeductionFundingSourcesSelector)
      .pipe(
        filter((res) => !!res && !res.isLoading),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((addedItems) => {
        this.dialogRef.close({ resultStatus: addedItems?.success });
        this.store.dispatch(clearEditDeductionAsFundingSourceStateAction());
      });
  }

  setFormValueInit(data: GetDeductionFundingSourcesResponse) {
    data?.listTypeOfDeductions.forEach((el) => {
      switch (el.deductionType) {
        case DeductionType.Tax:
          el.deductionSubTypes.forEach((item) => {
            this.listDeductionLinked.tax.push(item);
            if (item.payrollBenefitDeductionId) {
              this.deductionFormGroup.get('deductionType')?.setValue(DeductionType.Tax);
              this.addType();
              switch (item.subTypeId) {
                case DeductionSubType.FederalTax:
                  this.taxFormGroup.get('federalTax')?.setValue(Math.abs(item.amount));
                  this.taxFormGroup
                    .get('collectDeductionsFederal')
                    ?.setValue(!!item.offsetDeductionItemId && item.offsetDeductionItemStatus !== this.cancelStatus);
                  if (item.isDisabled) {
                    this.taxFormGroup.get('collectDeductionsFederal')?.disable();
                    this.taxFormGroup.get('federalTax')?.disable();
                    this.chipNotRemove[DeductionType.Tax] = true;
                  }
                  break;
                case DeductionSubType.StateTax:
                  this.taxFormGroup.get('stateTax')?.setValue(Math.abs(item.amount));
                  break;
                default:
                  break;
              }
            }
          });
          break;
        case DeductionType.Insurance:
          el.deductionSubTypes.forEach((item, i) => {
            if (item.payrollBenefitDeductionId) {
              this.listDeductionLinked.insurance.push({
                ...item,
                subTypeId: (item.subTypeId || '')?.toString() + ':' + i,
              });
              if (!this.insuranceFormArray) {
                this.deductionFormGroup.get('deductionType')?.setValue(DeductionType.Insurance);
                this.addType();
                (this.insuranceFormArray as FormArray)?.controls[0]
                  ?.get('deductionSubTypeId')
                  ?.setValue((item.subTypeId || '')?.toString() + ':' + i);
                (this.insuranceFormArray as FormArray)?.controls[0]?.get('amount')?.setValue(Math.abs(item.amount));
                (this.insuranceFormArray as FormArray)?.controls[0]?.get('deductionSubTypeId')?.disable();
                (this.insuranceFormArray as FormArray)?.controls[0]
                  .get('collectDeductionsFromDP')
                  ?.setValue(!!item.offsetDeductionItemId && item.offsetDeductionItemStatus !== this.cancelStatus);
                if (item.isDisabled) {
                  (this.insuranceFormArray as FormArray)?.controls[0].get('collectDeductionsFromDP')?.disable();
                  (this.insuranceFormArray as FormArray)?.controls[0]?.get('amount')?.disable();
                }
              } else {
                this.insuranceFormArray.push(
                  this._createFormGroupFollowType(DeductionType.Insurance, {
                    selectOption: (item.subTypeId || '')?.toString() + ':' + i,
                    amount: item.amount,
                    toggleDP: !!item.offsetDeductionItemId && item.offsetDeductionItemStatus !== this.cancelStatus,
                    toggleDisabled: item.isDisabled,
                  }),
                );
              }

              if (item.isDisabled) {
                this.chipNotRemove[DeductionType.Insurance] = true;
              }
            }
          });
          break;
        case DeductionType.Others:
          el.deductionSubTypes.forEach((item, i) => {
            if (item.payrollBenefitDeductionId) {
              this.listDeductionLinked.others.push({
                ...item,
                subTypeId: (item.subTypeId || '')?.toString() + ':' + i,
              });
              if (!this.othersFormArray) {
                this.deductionFormGroup.get('deductionType')?.setValue(DeductionType.Others);
                this.addType();
                (this.othersFormArray as FormArray)?.controls[0]
                  ?.get('deductionSubTypeId')
                  ?.setValue((item.subTypeId || '')?.toString() + ':' + i);
                (this.othersFormArray as FormArray)?.controls[0]?.get('amount')?.setValue(Math.abs(item.amount));
                (this.othersFormArray as FormArray)?.controls[0]?.get('deductionSubTypeId')?.disable();
                (this.othersFormArray as FormArray)?.controls[0]
                  ?.get('collectDeductionsFromDP')
                  ?.setValue(!!item.offsetDeductionItemId && item.offsetDeductionItemStatus !== this.cancelStatus);
                if (item.isDisabled) {
                  (this.othersFormArray as FormArray)?.controls[0]?.get('collectDeductionsFromDP')?.disable();
                  (this.othersFormArray as FormArray)?.controls[0]?.get('amount')?.disable();
                }
              } else {
                this.othersFormArray.push(
                  this._createFormGroupFollowType(DeductionType.Others, {
                    selectOption: (item.subTypeId || '')?.toString() + ':' + i,
                    amount: item.amount,
                    toggleDP: !!item.offsetDeductionItemId && item.offsetDeductionItemStatus !== this.cancelStatus,
                    toggleDisabled: item.isDisabled,
                  }),
                );
              }

              if (item.isDisabled) {
                this.chipNotRemove[DeductionType.Others] = true;
              }
            }
          });
          break;
        case DeductionType.Garnishment:
          (el.courtOrderDeductions || []).forEach((item, i) => {
            if (item.payrollBenefitDeductionId) {
              this.listDeductionLinked.garnishments.push({
                ...item,
                subTypeId: (item.subTypeId || '')?.toString() + ':' + i,
              });
              if (!this.garnishmentsFormArray) {
                this.deductionFormGroup.get('deductionType')?.setValue(DeductionType.Garnishment);
                this.addType();
                (this.garnishmentsFormArray as FormArray)?.controls[0]
                  ?.get('courtOrder')
                  ?.setValue((item.subTypeId || '')?.toString() + ':' + i);
                (this.garnishmentsFormArray as FormArray)?.controls[0]?.get('amount')?.setValue(Math.abs(item.amount));
                (this.garnishmentsFormArray as FormArray)?.controls[0]?.get('deductionPayee')?.setValue(item.payee);
                (this.garnishmentsFormArray as FormArray)?.controls[0]?.get('courtOrder')?.disable();
                (this.garnishmentsFormArray as FormArray)?.controls[0]
                  ?.get('collectDeductionsFromDP')
                  ?.setValue(!!item.offsetDeductionItemId && item.offsetDeductionItemStatus !== this.cancelStatus);
                if ((item as CourtOrderDeduction)?.preOrPostTax !== null) {
                  (this.garnishmentsFormArray as FormArray)?.controls[0]
                    ?.get('postOrPretax')
                    ?.setValue((item as CourtOrderDeduction)?.preOrPostTax ? TaxSettingType.PreTax : TaxSettingType.PostTax);
                }
                if (item.isDisabled) {
                  (this.garnishmentsFormArray as FormArray)?.controls[0]?.get('collectDeductionsFromDP')?.disable();
                  (this.garnishmentsFormArray as FormArray)?.controls[0]?.get('amount')?.disable();
                }
              } else {
                this.garnishmentsFormArray.push(
                  this._createFormGroupFollowType(DeductionType.Garnishment, {
                    selectOption: (item.subTypeId || '')?.toString() + ':' + i,
                    amount: item.amount,
                    toggleDP: !!item.offsetDeductionItemId && item.offsetDeductionItemStatus !== this.cancelStatus,
                    toggleDisabled: item.isDisabled,
                  }),
                );
              }

              if (item.isDisabled) {
                this.chipNotRemove[DeductionType.Garnishment] = true;
              }
            }
          });
          break;
        case DeductionType.Qdro:
          (el.courtOrderDeductions || []).forEach((item, i) => {
            if (item.payrollBenefitDeductionId) {
              this.listDeductionLinked.qdro.push({
                ...item,
                subTypeId: (item.subTypeId || '')?.toString() + ':' + i,
              });

              const savedValues = {
                collectDeductionsFromDP: !!item.offsetDeductionItemId && item.offsetDeductionItemStatus !== this.cancelStatus,
                courtOrder: item.caseNumber,
                deductionPayee: item.payee,
                amount: Math.abs(item.amount)
              };

              if (!this.qdroFormArray) {
                this.deductionFormGroup.get('deductionType')?.setValue(DeductionType.Qdro);
                this.addType();
                const firstFormGroup = (this.qdroFormArray as FormArray)?.controls[0] as FormGroup;
                firstFormGroup.patchValue(savedValues);
                firstFormGroup.get('courtOrder')?.disable();
                if (item.isDisabled) {
                  firstFormGroup.get('collectDeductionsFromDP')?.disable();
                  firstFormGroup.get('amount')?.disable();
                }
              } else {
                this.qdroFormArray.push(
                  this._createFormGroupFollowType(DeductionType.Qdro, {
                    selectOption: savedValues.courtOrder,
                    amount: savedValues.amount,
                    toggleDP: savedValues.collectDeductionsFromDP,
                    toggleDisabled: item.isDisabled,
                    payee: savedValues.deductionPayee
                  }),
                );
              }

              if (item.isDisabled) {
                this.chipNotRemove[DeductionType.Qdro] = true;
              }
            }
          });
          break;
        default:
          break;
      }
    });
  }

  setList(data: GetDeductionFundingSourcesResponse) {
    const result = this.addOneTimeService.mapDeductionTypesFromResponse(data, {
      mapFunctionFactory({deductionType}) {
        switch (deductionType) {
          case DeductionType.Insurance: {
            return (item, i) => {
              return {
                displayValue: item.subTypeName || '',
                value: (item.subTypeId || '')?.toString() + ':' + i,
                extraData: item,
              };
            };
          }
          case DeductionType.Others: {
            return (item, i) => {
              return {
                displayValue: item.subTypeName || '',
                value: (item.subTypeId || '')?.toString() + ':' + i,
                extraData: item,
              };
            };
          }
          case DeductionType.Garnishment: {
            return (item, i) => ({
              displayValue: item.caseNumber || '',
              value: (item.subTypeId || '')?.toString() + ':' + i,
              extraData: item,
            });
          }
          case DeductionType.Qdro: {
            return (item, i) => ({
              displayValue: item.caseNumber || '',
              value: item.caseNumber || '',
              extraData: item,
            });
          }
          default: {
            return null;
          }
        }
      }
    });
    this.listDeductionType = result.listDeductionType;
    this.listInsuranceDeduction = result.listInsuranceDeduction;
    this.listOthersDeduction = result.listOthersDeduction;
    this.listCourtOrder = result.listCourtOrder;
    this.listCourtOrderQdro = result.listCourtOrderQdro;
    this.DeductionLabels = result.deductionLabels;
  }

  setTitle() {
    let message = '';
    if (this.data.earningInfo?.isTitleFundingSource) {
      this.dialogTitle = 'Edit Funding Sources';
      message = 'Funding Sources for the selected One-Time Payment are from recovery of the following Deductions';
    } else {
      this.dialogTitle = 'Edit Earnings';
      message = 'Earnings for the selected One-Time Payment are from recovery of the following Deductions';
    }
    showBanner.call(this, BannerType.Info, '', '', {
      customMessage: message,
    });
  }

  private _validateAmount(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (control.value <= 0) {
        return {
          errorAmountMessage: 'Amount must be positive',
        };
      }
      return null;
    };
  }

  private _requiredChip(label: string) {
    return (c: AbstractControl) => {
      return this.listChip?.length
        ? null
        : {
            requiredChip: `Deduction Items as Funding Sources are required. Please add at least one Deduction Item as Funding Source to the One Time Payment.`,
          };
    };
  }

  private _showWarningPopup() {
    this.dialog.open(ConfirmPopupComponent, {
      panelClass: 'confirm-popup',
      autoFocus: false,
      disableClose: true,
      data: {
        title: 'Warning',
        text: 'Cannot remove this deduction since its corresponding Offset Deduction Payee Payment is included in Deduction Payee Run',
        type: ConfirmType.Warning,
        hideSaveButton: true,
        hideConfirmButton: true,
      },
    });
  }

  onSave(): void {
    this.deductionFormGroup.markAllAsTouched();
    if (this.deductionFormGroup.invalid) {
      return;
    }
    const request: any = {
      offsetPaymentDeductionId: this.offsetPaymentDeductionId,
      listInforOfDeduction: [],
    };

    const tax = this.taxFormGroup?.getRawValue();
    const insurance = this.insuranceFormArray?.getRawValue() || [];
    const others = this.othersFormArray?.getRawValue() || [];
    const garnishments = this.garnishmentsFormArray?.getRawValue() || [];
    const qdro = this.qdroFormArray?.getRawValue() || [];

    if (tax) {
      request.listInforOfDeduction.push({
        amount: 0 - Number(tax.federalTax || 0),
        deductionType: DeductionType.Tax,
        deductionSubTypeId: DeductionSubType.FederalTax,
        payrollDeductionId: this.listDeductionLinked.tax[0]?.payrollDeductionId,
        payrollBenefitDeductionId: this.listDeductionLinked.tax[0]?.payrollBenefitDeductionId,
        payeeId: this.listDeductionLinked.tax[0]?.payeeId,
        isCreatingOffsetDp: this.taxFormGroup.get('collectDeductionsFederal')?.value,
        deductionItemId: this.listDeductionLinked.tax[0]?.offsetDeductionItemId,
        existPayrollBenefitDeductionId: this.listDeductionLinked.tax[0]?.existPayrollBenefitDeductionId,
      });
    }

    insurance.forEach((item) => {
      const tmp = this.listInsuranceDeduction.find((el) => el.value === item.deductionSubTypeId);
      if (!tmp) {
        return;
      }
      const deductionItem = {
        amount: 0 - Number(item.amount || 0),
        deductionType: DeductionType.Insurance,
        deductionSubTypeId: Number(item.deductionSubTypeId.split(':')[0]),
        payrollDeductionId: tmp?.extraData?.payrollDeductionId,
        payrollBenefitDeductionId: tmp?.extraData?.payrollBenefitDeductionId,
        payeeId: tmp?.extraData?.payeeId,
        isCreatingOffsetDp: item.collectDeductionsFromDP,
        deductionItemId: tmp?.extraData?.offsetDeductionItemId,
        existPayrollBenefitDeductionId: tmp?.extraData?.existPayrollBenefitDeductionId,
      };
      request.listInforOfDeduction.push(deductionItem);
    });
    others.forEach((item) => {
      const tmp = this.listOthersDeduction.find((el) => el.value === item.deductionSubTypeId);
      if (!tmp) {
        return;
      }
      const deductionItem = {
        amount: 0 - Number(item.amount || 0),
        deductionType: DeductionType.Others,
        deductionSubTypeId: Number(item.deductionSubTypeId.split(':')[0]),
        payrollDeductionId: tmp?.extraData?.payrollDeductionId,
        payrollBenefitDeductionId: tmp?.extraData?.payrollBenefitDeductionId,
        payeeId: tmp?.extraData?.payeeId,
        isCreatingOffsetDp: item.collectDeductionsFromDP,
        deductionItemId: tmp?.extraData?.offsetDeductionItemId,
        existPayrollBenefitDeductionId: tmp?.extraData?.existPayrollBenefitDeductionId,
      };
      request.listInforOfDeduction.push(deductionItem);
    });
    garnishments.forEach((item) => {
      const tmp = this.listCourtOrder.find((el) => el.value === item.courtOrder);
      if (!tmp) {
        return;
      }
      const deductionItem = {
        amount: 0 - Number(item.amount || 0),
        caseNumber: tmp?.extraData?.caseNumber,
        deductionDescription: tmp?.extraData?.description,
        deductionType: DeductionType.Garnishment,
        payrollDeductionId: tmp?.extraData?.payrollDeductionId,
        payrollBenefitDeductionId: tmp?.extraData?.payrollBenefitDeductionId,
        payee: tmp?.extraData?.payee,
        payeeId: tmp?.extraData?.payeeId,
        isCreatingOffsetDp: item.collectDeductionsFromDP,
        deductionItemId: tmp?.extraData?.offsetDeductionItemId,
        existPayrollBenefitDeductionId: tmp?.extraData?.existPayrollBenefitDeductionId,
        postOrPretax: item?.postOrPretax,
      };
      request.listInforOfDeduction.push(deductionItem);
    });
    qdro.forEach(item => {
      const tmp = this.listCourtOrderQdro.find((el) => el.value === item.courtOrder);
      if (!tmp) {
        return;
      }
      const deductionItem = {
        amount: 0 - Number(item.amount || 0),
        caseNumber: tmp?.extraData?.caseNumber,
        deductionDescription: tmp?.extraData?.description,
        deductionType: DeductionType.Qdro,
        payrollDeductionId: tmp?.extraData?.payrollDeductionId,
        payrollBenefitDeductionId: tmp?.extraData?.payrollBenefitDeductionId,
        payee: tmp?.extraData?.payee,
        payeeId: tmp?.extraData?.payeeId,
        isCreatingOffsetDp: item.collectDeductionsFromDP,
        deductionItemId: tmp?.extraData?.offsetDeductionItemId,
        existPayrollBenefitDeductionId: tmp?.extraData?.existPayrollBenefitDeductionId,
      };
      request.listInforOfDeduction.push(deductionItem);
    })
    // Add information of item removed
    if (this.listDeductionLinked.tax[0]?.payrollBenefitDeductionId && !tax) {
      request.listInforOfDeduction.push({
        amount: this.listDeductionLinked.tax[0]?.amount,
        deductionType: DeductionType.Tax,
        deductionSubTypeId: Number((this.listDeductionLinked.tax[0]?.subTypeId?.toString() || '').split(':')[0]),
        payrollDeductionId: this.listDeductionLinked.tax[0]?.payrollDeductionId,
        payrollBenefitDeductionId: this.listDeductionLinked.tax[0]?.payrollBenefitDeductionId,
        isDeleted: true,
        payeeId: this.listDeductionLinked.tax[0]?.payeeId,
        deductionItemId: this.listDeductionLinked.tax[0]?.offsetDeductionItemId,
        existPayrollBenefitDeductionId: this.listDeductionLinked.tax[0]?.existPayrollBenefitDeductionId,
      });
    }

    this.listDeductionLinked.insurance.forEach((el) => {
      const temp = insurance.find((item) => item.deductionSubTypeId === el.subTypeId);
      if (!temp) {
        request.listInforOfDeduction.push({
          amount: el.amount,
          deductionType: DeductionType.Insurance,
          deductionSubTypeId: Number((el.subTypeId?.toString() || '').split(':')[0]),
          payrollDeductionId: el.payrollDeductionId,
          payrollBenefitDeductionId: el.payrollBenefitDeductionId,
          isDeleted: true,
          payeeId: el.payeeId,
          deductionItemId: el.offsetDeductionItemId,
          existPayrollBenefitDeductionId: el?.existPayrollBenefitDeductionId,
        });
      }
    });
    this.listDeductionLinked.others.forEach((el) => {
      const temp = others.find((item) => item.deductionSubTypeId === el.subTypeId);
      if (!temp) {
        request.listInforOfDeduction.push({
          amount: el.amount,
          deductionType: DeductionType.Insurance,
          deductionSubTypeId: Number((el.subTypeId?.toString() || '').split(':')[0]),
          payrollDeductionId: el.payrollDeductionId,
          payrollBenefitDeductionId: el.payrollBenefitDeductionId,
          isDeleted: true,
          payeeId: el.payeeId,
          deductionItemId: el.offsetDeductionItemId,
          existPayrollBenefitDeductionId: el.existPayrollBenefitDeductionId,
        });
      }
    });
    this.listDeductionLinked.garnishments.forEach((el) => {
      const temp = garnishments.find((item) => item.courtOrder === el.subTypeId);
      if (!temp) {
        request.listInforOfDeduction.push({
          amount: el.amount,
          deductionType: DeductionType.Insurance,
          caseNumber: el.caseNumber,
          deductionDescription: el.description,
          payrollDeductionId: el.payrollDeductionId,
          payrollBenefitDeductionId: el.payrollBenefitDeductionId,
          payee: el.payee,
          payeeId: el.payeeId,
          isDeleted: true,
          deductionItemId: el.offsetDeductionItemId,
          existPayrollBenefitDeductionId: el.existPayrollBenefitDeductionId,
        });
      }
    });
    this.listDeductionLinked.qdro.forEach((el) => {
      const temp = qdro.find((item) => item.courtOrder === el.caseNumber);
      if (!temp) {
        request.listInforOfDeduction.push({
          amount: el.amount,
          deductionType: DeductionType.Qdro,
          caseNumber: el.caseNumber,
          deductionDescription: el.description,
          payrollDeductionId: el.payrollDeductionId,
          payrollBenefitDeductionId: el.payrollBenefitDeductionId,
          payee: el.payee,
          payeeId: el.payeeId,
          isDeleted: true,
          deductionItemId: el.offsetDeductionItemId,
          existPayrollBenefitDeductionId: el.existPayrollBenefitDeductionId,
        });
      }
    });
    this.store.dispatch(
      editDeductionAsFundingSourceAction({ paymentInstructionId: this.data.earningInfo?.paymentId || '', request }),
    );
  }

  onCancel(): void {
    showCancelDialog(this.dialog, this.dialogRef);
  }

  onRemoveRowDeduction(idx: number, formArray: FormArray, deductionType: DeductionType) {
    const index = this.listChip.findIndex((el) => el.value === deductionType);
    if (formArray.controls[idx]?.get('collectDeductionsFromDP')?.disabled) {
      this._showWarningPopup();
      return;
    }
    formArray.removeAt(idx);
    if (formArray.controls.length === 0) {
      this.removeChip(index);
    }
    this.getCalculatedAmount();
  }

  addNewRowDeduction(formArray: FormArray, deductionType: DeductionType) {
    const control = this._createFormGroupFollowType(deductionType);
    formArray.push(control);
  }

  private _createFormGroupFollowType(
    type: DeductionType,
    initialValue?: { selectOption: any; amount: number; payee?: string; toggleDP?: boolean; toggleDisabled?: boolean },
  ) {
    switch (type) {
      case DeductionType.Garnishment:
        return new FormGroup({
          collectDeductionsFromDP: new FormControl({
            value: initialValue ? initialValue.toggleDP : true,
            disabled: initialValue?.toggleDisabled,
          }),
          courtOrder: new FormControl({
            value: initialValue?.selectOption,
            disabled: !!initialValue,
          }),
          deductionPayee: new FormControl(initialValue?.payee),
          amount: new FormControl(
            {
              value: initialValue ? Math.abs(initialValue?.amount) : null,
              disabled: initialValue?.toggleDisabled,
            },
            {
              validators: this._validateAmount(),
              asyncValidators: this._checkAmount('courtOrder', this.listCourtOrder),
            },
          ),
          postOrPretax: new FormControl(TaxSettingType.PostTax),
        });

      case DeductionType.Others:
        return new FormGroup({
          collectDeductionsFromDP: new FormControl({
            value: initialValue ? initialValue.toggleDP : true,
            disabled: initialValue?.toggleDisabled,
          }),
          deductionSubTypeId: new FormControl({
            value: initialValue?.selectOption,
            disabled: !!initialValue,
          }),
          amount: new FormControl(
            {
              value: initialValue ? Math.abs(initialValue?.amount) : null,
              disabled: initialValue?.toggleDisabled,
            },
            {
              validators: this._validateAmount(),
              asyncValidators: this._checkAmount('deductionSubTypeId', this.listOthersDeduction),
            },
          ),
        });

      case DeductionType.Insurance:
        return new FormGroup({
          collectDeductionsFromDP: new FormControl({
            value: initialValue ? initialValue.toggleDP : true,
            disabled: initialValue?.toggleDisabled,
          }),
          deductionSubTypeId: new FormControl({
            value: initialValue?.selectOption,
            disabled: !!initialValue,
          }),
          amount: new FormControl(
            {
              value: initialValue ? Math.abs(initialValue?.amount) : null,
              disabled: initialValue?.toggleDisabled,
            },
            {
              validators: this._validateAmount(),
              asyncValidators: this._checkAmount('deductionSubTypeId', this.listInsuranceDeduction),
            },
          ),
        });

      case DeductionType.Qdro:
        const formGroup = new FormGroup({
          collectDeductionsFromDP: new FormControl({
            value: initialValue ? initialValue.toggleDP : true,
            disabled: initialValue?.toggleDisabled,
          }),
          courtOrder: new FormControl({
            value: initialValue?.selectOption,
            disabled: !!initialValue,
          }),
          deductionPayee: new FormControl(initialValue?.payee),
          amount: new FormControl(
            {
              value: initialValue ? Math.abs(initialValue?.amount) : null,
              disabled: initialValue?.toggleDisabled,
            },
            {
              validators: this._validateAmount(),
              asyncValidators: this._checkAmount('courtOrder', this.listCourtOrderQdro),
            },
          ),
          postOrPretax: new FormControl(TaxSettingType.PreTax),
        });
        this.addOneTimeService.subscribeQdroForm(formGroup, this.unsubscribe$);
        return formGroup;

      default:
        return new FormGroup({});
    }
  }

  _createListData(value: string, formArray: FormArray, arrayList: Option[], field: string, subType?: 'qdro') {
    const arr = formArray?.getRawValue()?.map((el) => el[field]);
    if (subType === 'qdro') {
      arrayList = arrayList.slice().sort((a, b) => a.displayValue.localeCompare(b.displayValue ?? '') ?? 0);
    }
    return arrayList
      .filter((el) => {
        if ((value?.toString() && el.value === value) || !arr.includes(el.value)) {
          return true;
        } else {
          return false;
        }
      });
  }

  addType() {
    const deductionType = this.deductionFormGroup?.get('deductionType');
    let selectedItem = this.listDeductionType.find((el, i) => {
      if (el.value === deductionType?.value) {
        return true;
      }
      return false;
    });

    // Add item to listChip, clear selector, remove option out of list
    if (selectedItem) {
      this.listChip.push(selectedItem);
      deductionType?.setValue('');
      selectedItem.isHide = true;
    }

    this.generateControlFollowDeductionType(selectedItem, true);
  }

  removeChip(index: number) {
    if (this.chipNotRemove[this.listChip[index].value]) {
      this._showWarningPopup();
      return;
    }
    const deductionType = this.deductionFormGroup?.get('deductionType');
    let item = this.listChip.splice(index, 1);
    const idx = this.listDeductionType.findIndex((el) => {
      return el.value === item[0].value;
    });
    this.listDeductionType[idx].isHide = false;
    if (this.listChip.length === 0) {
      deductionType?.markAsTouched();
      deductionType?.setErrors({
        requiredChip: `Deduction Items as Funding Sources are required. Please add at least one Deduction Item as Funding Source to the One Time Payment.`,
      });
    }

    this.generateControlFollowDeductionType(item[0], false);
  }

  generateControlFollowDeductionType(element: Option | undefined, isAdd: boolean) {
    if (isAdd) {
      switch (element?.value as DeductionType) {
        case DeductionType.Tax:
          this.deductionFormGroup.addControl(
            'tax',
            new FormGroup({
              collectDeductionsFederal: new FormControl(true),
              federalTax: new FormControl(null, {
                validators: this._validateAmount(),
                // asyncValidators: this._checkAmount('federalTax', []),
              }),
              // collectDeductionsState: new FormControl(true),
              // stateTax: new FormControl(null, this._validateAmount()),
            }),
          );
          break;

        case DeductionType.Insurance:
          this.deductionFormGroup.addControl(
            'insurance',
            new FormArray([this._createFormGroupFollowType(DeductionType.Insurance)]),
          );
          break;

        case DeductionType.Others:
          this.deductionFormGroup.addControl(
            'others',
            new FormArray([this._createFormGroupFollowType(DeductionType.Others)])
          );
          break;

        case DeductionType.Garnishment:
          this.deductionFormGroup.addControl(
            'garnishments',
            new FormArray([this._createFormGroupFollowType(DeductionType.Garnishment)]),
          );
          break;

        case DeductionType.Qdro:
          this.deductionFormGroup.addControl(
            CONTROL_NAME_QDRO,
            new FormArray([this._createFormGroupFollowType(element?.value)]),
          );
          break;
      }
    } else {
      switch (element?.value as DeductionType) {
        case DeductionType.Tax:
          this.deductionFormGroup.removeControl('tax');
          break;

        case DeductionType.Insurance:
          this.deductionFormGroup.removeControl('insurance');
          break;

        case DeductionType.Others:
          this.deductionFormGroup.removeControl('others');
          break;

        case DeductionType.Garnishment:
          this.deductionFormGroup.removeControl('garnishments');
          break;

        case DeductionType.Qdro:
          this.deductionFormGroup.removeControl(CONTROL_NAME_QDRO);
          break;

        default:
          break;
      }
    }
  }

  onFederalValueChange(event: string) {}

  onChangeInsuranceDeductionValue(event: Option, control: AbstractControl) {
    this.resetAmountValue(control);
  }

  onChangeOthersDeductionValue(event: Option, control: AbstractControl) {
    this.resetAmountValue(control);
  }

  onChangeGarnishmentValue(event: Option, control: AbstractControl) {
    control.get('deductionPayee')?.setValue(event?.extraData?.payee || 'N/A');
    this.resetAmountValue(control);
  }

  resetAmountValue(control: AbstractControl) {
    const temp = control.get('amount')?.value;
    control.get('amount')?.setValue('');
    control.get('amount')?.setValue(temp);
  }

  checkConditionToShowAddButton(type: DeductionType) {
    switch (type) {
      case DeductionType.Insurance:
        const lastInsurance = this.insuranceFormArray?.get((this.insuranceFormArray.length - 1)?.toString());
        return this.insuranceFormArray.length < this.listInsuranceDeduction.length && lastInsurance?.valid;

      case DeductionType.Others:
        const lastOthers = this.othersFormArray?.get((this.othersFormArray.length - 1)?.toString());
        return this.othersFormArray.length < this.listOthersDeduction.length && lastOthers?.valid;

      case DeductionType.Garnishment:
        const lastGarnishment = this.garnishmentsFormArray?.get((this.garnishmentsFormArray.length - 1)?.toString());
        return this.garnishmentsFormArray.length < this.listCourtOrder.length && lastGarnishment?.valid;

      case DeductionType.Qdro:
        const lastQdro = this.qdroFormArray?.get((this.qdroFormArray.length - 1)?.toString());
        return this.qdroFormArray.length < this.listCourtOrderQdro.length && lastQdro?.valid;

      default:
        return false;
    }
  }

  private _checkAmount(sub: string, arr: Option[]) {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      let valueCheck = control.value?.toString()?.trim();
      let temp: Option | SubType | any =
        sub === 'federalTax'
          ? this.listDeductionLinked.tax[0]
          : arr.find((item) => item?.value === control.parent?.get(sub)?.value);
      if (!temp) {
        return of(null);
      }

      return timer(300).pipe(
        switchMap(
          (): Observable<ValidationErrors | null> =>
            this.addOneTimeService
              .checkValidDateAmount({
                queryParams: {
                  benefitCode: this.data.earningInfo?.selectedHeaderBenefit?.benefitId || '',
                  BenefitTypeName: this.data.earningInfo?.selectedHeaderBenefit?.benefitTypeName || '',
                  ...(temp?.extraData?.existPayrollBenefitDeductionId && {
                    deductionId: sub === 'federalTax' ? temp.existPayrollBenefitDeductionId : temp?.extraData?.existPayrollBenefitDeductionId,
                  }),
                  amount: Number(valueCheck),
                  paymentInstructionId: this.data.earningInfo?.paymentId || '',
                },
                memberId: this.data.earningInfo?.memberId,
              })
              .pipe(
                map((response: any) => {
                  if (
                    response &&
                    (response.exists ||
                      response.isExisted ||
                      response.isExists ||
                      response.isExist ||
                      response.isValid === false ||
                      response.currentExists)
                  ) {
                    return {
                      inValidAsync: `Entered amount is greater than previously taken amounts of the ${
                        sub === 'federalTax' ? 'Federal Tax' : temp.displayValue
                      } in Recurring and One time payments for the benefit period ${response.message}.`,
                    };
                  }
                  return null;
                }),
              ),
        ),
      );
    };
  }

  get taxFormGroup() {
    return this.deductionFormGroup.get('tax') as FormGroup;
  }

  get insuranceFormArray(): FormArray {
    return this.deductionFormGroup?.get('insurance') as FormArray;
  }

  get othersFormArray() {
    return this.deductionFormGroup?.get('others') as FormArray;
  }

  get garnishmentsFormArray() {
    return this.deductionFormGroup?.get('garnishments') as FormArray;
  }

  get qdroFormArray() {
    return this.deductionFormGroup?.get(CONTROL_NAME_QDRO) as FormArray;
  }
}
