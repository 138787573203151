export const menuCloseIcon = `<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#ffffff"><path d="M0 0h24v24H0z" fill="none"/><path d="M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z"/></svg>`;
export const switchAccountIcon = `<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px"><path d="M0 0h24v24H0z" fill="none"/><path d="M4 6H2v14c0 1.1.9 2 2 2h14v-2H4V6zm16-4H8c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zm-6 2c1.66 0 3 1.34 3 3s-1.34 3-3 3-3-1.34-3-3 1.34-3 3-3zm6 12H8v-1.5c0-1.99 4-3 6-3s6 1.01 6 3V16z"/></svg>`;
export const settingIcon = `<svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px"><g><path d="M0,0h24v24H0V0z" fill="none"/><path d="M19.14,12.94c0.04-0.3,0.06-0.61,0.06-0.94c0-0.32-0.02-0.64-0.07-0.94l2.03-1.58c0.18-0.14,0.23-0.41,0.12-0.61 l-1.92-3.32c-0.12-0.22-0.37-0.29-0.59-0.22l-2.39,0.96c-0.5-0.38-1.03-0.7-1.62-0.94L14.4,2.81c-0.04-0.24-0.24-0.41-0.48-0.41 h-3.84c-0.24,0-0.43,0.17-0.47,0.41L9.25,5.35C8.66,5.59,8.12,5.92,7.63,6.29L5.24,5.33c-0.22-0.08-0.47,0-0.59,0.22L2.74,8.87 C2.62,9.08,2.66,9.34,2.86,9.48l2.03,1.58C4.84,11.36,4.8,11.69,4.8,12s0.02,0.64,0.07,0.94l-2.03,1.58 c-0.18,0.14-0.23,0.41-0.12,0.61l1.92,3.32c0.12,0.22,0.37,0.29,0.59,0.22l2.39-0.96c0.5,0.38,1.03,0.7,1.62,0.94l0.36,2.54 c0.05,0.24,0.24,0.41,0.48,0.41h3.84c0.24,0,0.44-0.17,0.47-0.41l0.36-2.54c0.59-0.24,1.13-0.56,1.62-0.94l2.39,0.96 c0.22,0.08,0.47,0,0.59-0.22l1.92-3.32c0.12-0.22,0.07-0.47-0.12-0.61L19.14,12.94z M12,15.6c-1.98,0-3.6-1.62-3.6-3.6 s1.62-3.6,3.6-3.6s3.6,1.62,3.6,3.6S13.98,15.6,12,15.6z"/></g></svg>`;
export const menuOpenIcon = `<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M3 18h13v-2H3v2zm0-5h10v-2H3v2zm0-7v2h13V6H3zm18 9.59L17.42 12 21 8.41 19.59 7l-5 5 5 5L21 15.59z"/></svg>`;
export const logoutIcon = `<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px"><path d="M0 0h24v24H0z" fill="none"/><path d="M17 7l-1.41 1.41L18.17 11H8v2h10.17l-2.58 2.58L17 17l5-5zM4 5h8V3H4c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h8v-2H4V5z"/></svg>`;
export const list = `<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px"><path d="M0 0h24v24H0z" fill="none"/><path d="M3 13h2v-2H3v2zm0 4h2v-2H3v2zm0-8h2V7H3v2zm4 4h14v-2H7v2zm0 4h14v-2H7v2zM7 7v2h14V7H7z"/></svg>`;
export const personSearch = `<svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill="#acacac"><g><rect fill="none" height="24" width="24"/><rect fill="none" height="24" width="24"/></g><g><g><circle cx="10" cy="8" r="4"/><path d="M10.35,14.01C7.62,13.91,2,15.27,2,18v1c0,0.55,0.45,1,1,1h8.54C9.07,17.24,10.31,14.11,10.35,14.01z"/><path d="M19.43,18.02c0.47-0.8,0.7-1.77,0.48-2.82c-0.34-1.64-1.72-2.95-3.38-3.16c-2.63-0.34-4.85,1.87-4.5,4.5 c0.22,1.66,1.52,3.04,3.16,3.38c1.05,0.22,2.02-0.01,2.82-0.48l1.86,1.86c0.39,0.39,1.02,0.39,1.41,0l0,0 c0.39-0.39,0.39-1.02,0-1.41L19.43,18.02z M16,18c-1.1,0-2-0.9-2-2c0-1.1,0.9-2,2-2s2,0.9,2,2C18,17.1,17.1,18,16,18z"/></g></g></svg>`;
export const shieldCheckIcon = `<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#63ce33"><path d="M0 0h24v24H0z" fill="none"/><path d="M12 1L3 5v6c0 5.55 3.84 10.74 9 12 5.16-1.26 9-6.45 9-12V5l-9-4zm-2 16l-4-4 1.41-1.41L10 14.17l6.59-6.59L18 9l-8 8z"/></svg>`;
export const removeAccountIcon = `<svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill="#629dba"><g><rect fill="none" height="24" width="24"/><rect fill="none" height="24" width="24"/></g><g><path d="M14,8c0-2.21-1.79-4-4-4S6,5.79,6,8s1.79,4,4,4S14,10.21,14,8z M2,18v1c0,0.55,0.45,1,1,1h14c0.55,0,1-0.45,1-1v-1 c0-2.66-5.33-4-8-4S2,15.34,2,18z M18,10h4c0.55,0,1,0.45,1,1v0c0,0.55-0.45,1-1,1h-4c-0.55,0-1-0.45-1-1v0 C17,10.45,17.45,10,18,10z"/></g></svg>`;
export const fundIcon = `<svg
xmlns:dc="http://purl.org/dc/elements/1.1/"
xmlns:cc="http://creativecommons.org/ns#"
xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
xmlns:svg="http://www.w3.org/2000/svg"
xmlns="http://www.w3.org/2000/svg"
xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"
xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape"
width="30"
height="30"
id="svg2"
sodipodi:version="0.32"
inkscape:version="0.48.4 r9939"
sodipodi:docname="minus.svg"
inkscape:output_extension="org.inkscape.output.svg.inkscape"
version="1.1">
<defs
  id="defs4">
 <clipPath
    clipPathUnits="userSpaceOnUse"
    id="clipPath17670-8">
   <path
      inkscape:connector-curvature="0"
      d="m 0,288.75 201.25,0 L 201.25,0 0,0 0,288.75 z"
      id="path17672-4" />
 </clipPath>
 <clipPath
    clipPathUnits="userSpaceOnUse"
    id="clipPath18098-9">
   <path
      inkscape:connector-curvature="0"
      d="m 0,288.75 201.25,0 L 201.25,0 0,0 0,288.75 z"
      id="path18100-2" />
 </clipPath>
 <clipPath
    clipPathUnits="userSpaceOnUse"
    id="clipPath17356-3">
   <path
      inkscape:connector-curvature="0"
      d="m 0,288.75 201.25,0 L 201.25,0 0,0 0,288.75 z"
      id="path17358-6" />
 </clipPath>
 <clipPath
    clipPathUnits="userSpaceOnUse"
    id="clipPath18256-8">
   <path
      inkscape:connector-curvature="0"
      d="m 0,288.75 201.25,0 L 201.25,0 0,0 0,288.75 z"
      id="path18258-5" />
 </clipPath>
</defs>
<sodipodi:namedview
  id="base"
  pagecolor="#ffffff"
  bordercolor="#666666"
  borderopacity="1.0"
  inkscape:pageopacity="0.0"
  inkscape:pageshadow="2"
  inkscape:zoom="10.276087"
  inkscape:cx="15.344828"
  inkscape:cy="14.482759"
  inkscape:document-units="px"
  inkscape:current-layer="layer1"
  inkscape:window-width="656"
  inkscape:window-height="1029"
  inkscape:window-x="0"
  inkscape:window-y="19"
  showgrid="false"
  inkscape:window-maximized="0" />
<metadata
  id="metadata7">
 <rdf:RDF>
   <cc:Work
      rdf:about="">
     <dc:format>image/svg+xml</dc:format>
     <dc:type
        rdf:resource="http://purl.org/dc/dcmitype/StillImage" />
     <dc:title></dc:title>
   </cc:Work>
 </rdf:RDF>
</metadata>
<g
  inkscape:label="Capa 1"
  inkscape:groupmode="layer"
  id="layer1"
  transform="translate(0,-1022.3622)">
 <path
    sodipodi:type="arc"
    style="color:#124462;fill:#1124462;stroke:#d4d4d4;stroke-width:1.02777779;stroke-opacity:1;marker:none;visibility:visible;display:inline;overflow:visible;enable-background:accumulate"
    id="path16617-6-6"
    sodipodi:cx="15.16226"
    sodipodi:cy="16.56851"
    sodipodi:rx="14.008413"
    sodipodi:ry="14.008413"
    d="m 29.170673,16.56851 a 14.008413,14.008413 0 1 1 -28.0168263,0 14.008413,14.008413 0 1 1 28.0168263,0 z"
    transform="matrix(0.97297297,0,0,0.97297297,330.50795,953.63241)" />
 <path
    style="fill:#1124462;stroke:#d4d4d4;stroke-width:1px;stroke-linecap:butt;stroke-linejoin:miter;stroke-opacity:1"
    d="m 345.29648,957.89011 0,11.863 0,0"
    id="path16623-6-2"
    inkscape:connector-curvature="0"
    inkscape:transform-center-y="-5.9314904" />
 <path
    style="fill:#1124462;stroke:#d4d4d4;stroke-width:1px;stroke-linecap:butt;stroke-linejoin:miter;stroke-opacity:1"
    d="m 357.15947,969.75311 -1.70877,0 0,0"
    id="path16623-4-2-0"
    inkscape:connector-curvature="0"
    inkscape:transform-center-x="-0.854382" />
 <path
    style="fill:#1124462;stroke:#d4d4d4;stroke-width:0.99999994px;stroke-linecap:butt;stroke-linejoin:miter;stroke-opacity:1"
    d="m 345.29648,981.61611 0,-2.0181 0,0"
    id="path16623-4-3-9-0"
    inkscape:connector-curvature="0"
    inkscape:transform-center-y="1.0091015" />
 <path
    style="fill:#1124462;stroke:#d4d4d4;stroke-width:1px;stroke-linecap:butt;stroke-linejoin:miter;stroke-opacity:1"
    d="m 333.43348,969.75311 11.863,0 0,0"
    id="path16623-4-3-4-1-0"
    inkscape:connector-curvature="0"
    inkscape:transform-center-x="5.9315" />
 <path
    inkscape:connector-curvature="0"
    sodipodi:nodetypes="cccccccccccccc"
    id="path9518-0-7"
    d="m 14.999994,1025.9915 -11.2422196,9.9235 2.2484301,0 0,12.8177 6.7453475,0 0,-7.5644 4.651059,0 1.3e-5,7.5644 6.591159,0 0,-12.8177 2.248444,0 -11.242237,-9.9235 0,0 0,0 z"
    style="font-size:12px;fill:none;stroke:none" />
 <g
    style="fill:#1124462;fill-opacity:1"
    clip-path="url(#clipPath18256-8)"
    id="g18254-3"
    transform="matrix(2.3677885,0,0,-2.3677885,-58.40026,1690.2799)">
   <g
      style="fill:#1124462;fill-opacity:1"
      transform="translate(31.9995,274.75)"
      id="g18260-9">
     <path
        id="path18262-1"
        style="fill:#1124462;fill-opacity:1;fill-rule:nonzero;stroke:none"
        d="m 0,0 -0.5,0 0,4.5 C -0.5,4.776 -0.723,5 -1,5 -1.275,5 -1.5,4.776 -1.5,4.5 l 0,-4.5 -0.5,0 0,-1 0.5,0 0,-1.5 c 0,-0.276 0.225,-0.5 0.5,-0.5 0.277,0 0.5,0.224 0.5,0.5 l 0,1.5 0.5,0 0,1 z"
        inkscape:connector-curvature="0" />
   </g>
   <g
      style="fill:#1124462;fill-opacity:1"
      transform="translate(34.9995,277.75)"
      id="g18264-2">
     <path
        id="path18266-5"
        style="fill:#1124462;fill-opacity:1;fill-rule:nonzero;stroke:none"
        d="m 0,0 -0.5,0 0,1.5 C -0.5,1.776 -0.723,2 -1,2 -1.275,2 -1.5,1.776 -1.5,1.5 l 0,-1.5 -0.5,0 0,-1 0.5,0 0,-4.5 c 0,-0.276 0.225,-0.5 0.5,-0.5 0.277,0 0.5,0.224 0.5,0.5 l 0,4.5 0.5,0 0,1 z"
        inkscape:connector-curvature="0" />
   </g>
   <g
      style="fill:#1124462;fill-opacity:1"
      transform="translate(28.9995,276.75)"
      id="g18268-0">
     <path
        id="path18270-8"
        style="fill:#1124462;fill-opacity:1;fill-rule:nonzero;stroke:none"
        d="m 0,0 -0.5,0 0,2.5 C -0.5,2.776 -0.723,3 -1,3 -1.275,3 -1.5,2.776 -1.5,2.5 l 0,-2.5 -0.5,0 0,-1 0.5,0 0,-3.5 c 0,-0.276 0.225,-0.5 0.5,-0.5 0.277,0 0.5,0.224 0.5,0.5 l 0,3.5 0.5,0 0,1 z"
        inkscape:connector-curvature="0" />
   </g>
 </g>
</g>
</svg>`;
export const portrait = `<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><path d="M0 0h24v24H0z" fill="none"/><path d="M12 12.25c1.24 0 2.25-1.01 2.25-2.25S13.24 7.75 12 7.75 9.75 8.76 9.75 10s1.01 2.25 2.25 2.25zm4.5 4c0-1.5-3-2.25-4.5-2.25s-4.5.75-4.5 2.25V17h9v-.75zM19 3H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 16H5V5h14v14z"/></svg>`;
export const uploadFile = `<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="auto"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M14 2H6c-1.1 0-1.99.9-1.99 2L4 20c0 1.1.89 2 1.99 2H18c1.1 0 2-.9 2-2V8l-6-6zm4 18H6V4h7v5h5v11zM8 15.01l1.41 1.41L11 14.84V19h2v-4.16l1.59 1.59L16 15.01 12.01 11z"/></svg>`;
export const uploadFileBlue = `<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#60a5ce"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M14 2H6c-1.1 0-1.99.9-1.99 2L4 20c0 1.1.89 2 1.99 2H18c1.1 0 2-.9 2-2V8l-6-6zm4 18H6V4h7v5h5v11zM8 15.01l1.41 1.41L11 14.84V19h2v-4.16l1.59 1.59L16 15.01 12.01 11z"/></svg>`;
export const summary = `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M20 3H4C2.9 3 2 3.9 2 5V19C2 20.1 2.9 21 4 21H20C21.1 21 22 20.1 22 19V5C22 3.9 21.1 3 20 3ZM10 17H5V15H10V17ZM10 13H5V11H10V13ZM10 9H5V7H10V9ZM14.82 15L12 12.16L13.41 10.75L14.82 12.17L17.99 9L19.41 10.42L14.82 15Z" fill="#408BF9"/></svg>`;
export const warning = `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 21.5H23L12 2.5L1 21.5ZM13 18.5H11V16.5H13V18.5ZM13 14.5H11V10.5H13V14.5Z" fill="#FBC94A"/></svg>`;
export const error = `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M15.73 3H8.27L3 8.27V15.73L8.27 21H15.73L21 15.73V8.27L15.73 3ZM17 15.74L15.74 17L12 13.26L8.26 17L7 15.74L10.74 12L7 8.26L8.26 7L12 10.74L15.74 7L17 8.26L13.26 12L17 15.74Z" fill="#E02E2E"/></svg>`;
export const dangerous = `<svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill="#ff0000"><g><path d="M0,0h24v24H0V0z" fill="none"/></g><g><path d="M14.9,3H9.1C8.57,3,8.06,3.21,7.68,3.59l-4.1,4.1C3.21,8.06,3,8.57,3,9.1v5.8c0,0.53,0.21,1.04,0.59,1.41l4.1,4.1 C8.06,20.79,8.57,21,9.1,21h5.8c0.53,0,1.04-0.21,1.41-0.59l4.1-4.1C20.79,15.94,21,15.43,21,14.9V9.1c0-0.53-0.21-1.04-0.59-1.41 l-4.1-4.1C15.94,3.21,15.43,3,14.9,3z M15.54,15.54L15.54,15.54c-0.39,0.39-1.02,0.39-1.41,0L12,13.41l-2.12,2.12 c-0.39,0.39-1.02,0.39-1.41,0l0,0c-0.39-0.39-0.39-1.02,0-1.41L10.59,12L8.46,9.88c-0.39-0.39-0.39-1.02,0-1.41l0,0 c0.39-0.39,1.02-0.39,1.41,0L12,10.59l2.12-2.12c0.39-0.39,1.02-0.39,1.41,0l0,0c0.39,0.39,0.39,1.02,0,1.41L13.41,12l2.12,2.12 C15.93,14.51,15.93,15.15,15.54,15.54z"/></g></svg>`;
export const insertDriveFile = `<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#124462"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M14 2H6c-1.1 0-1.99.9-1.99 2L4 20c0 1.1.89 2 1.99 2H18c1.1 0 2-.9 2-2V8l-6-6zm4 18H6V4h7v5h5v11zM8 15.01l1.41 11z"/></svg>`;
export const filePresent = `<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="auto"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M15 2H6c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V7l-5-5zM6 20V4h8v4h4v12H6zm10-10v5c0 2.21-1.79 4-4 4s-4-1.79-4-4V8.5c0-1.47 1.26-2.64 2.76-2.49 1.3.13 2.24 1.32 2.24 2.63V15h-2V8.5c0-.28-.22-.5-.5-.5s-.5.22-.5.5V15c0 1.1.9 2 2 2s2-.9 2-2v-5h2z"/></svg>`;
export const addNotification = `<svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><rect fill="none" height="24" width="24" x="0" y="0"/><g><path d="M16,14v3H8v-7c0-2.21,1.79-4,4-4c0.85,0,1.64,0.26,2.28,0.72l1.43-1.43c-0.64-0.51-1.39-0.88-2.21-1.09V3.5 C13.5,2.67,12.83,2,12,2s-1.5,0.67-1.5,1.5v0.7C7.91,4.86,6,7.21,6,10v7H4v2h16v-2h-2v-3H16z M12,22c1.1,0,2-0.9,2-2h-4 C10,21.1,10.9,22,12,22z M24,8h-3V5h-2v3h-3v2h3v3h2v-3h3V8z"/></g></svg>`;
export const themeFix = `<svg width="20" height="22" viewBox="0 0 20 22" fill="auto" xmlns="http://www.w3.org/2000/svg">
<path d="M19.6895 17.669L14.3895 12.369H13.3995L10.8595 14.909V15.899L16.1595 21.199C16.5495 21.589 17.1795 21.589 17.5695 21.199L19.6895 19.079C20.0795 18.699 20.0795 18.059 19.6895 17.669Z" fill="auto"/>
<path d="M15.3595 9.68902L16.7695 8.27902L18.8895 10.399C20.0595 9.22902 20.0595 7.32902 18.8895 6.15902L15.3495 2.61902L13.9395 4.02902V1.20902L13.2395 0.499023L9.69953 4.03902L10.4095 4.74902H13.2395L11.8295 6.15902L12.8895 7.21902L9.99953 10.109L5.86953 5.97902V4.55902L2.84953 1.53902L0.0195312 4.36902L3.04953 7.39902H4.45953L8.58953 11.529L7.73953 12.379H5.61953L0.319531 17.679C-0.0704688 18.069 -0.0704688 18.699 0.319531 19.089L2.43953 21.209C2.82953 21.599 3.45953 21.599 3.84953 21.209L9.14953 15.909V13.789L14.2995 8.63902L15.3595 9.68902Z" fill="auto"/>
</svg>`;
export const tableLayout = `<svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M18 4H0V0H18V4ZM18 6H0V10H18V6ZM18 12H0V16H18V12Z" fill="#828282"/></svg>`;
export const columnLayout = `<svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M11.67 0V14H6.33V0H11.67ZM12.67 14H18V0H12.67V14ZM5.33 14V0H0V14H5.33Z" fill="#828282"/></svg>`;
export const arrowDropDown = `<svg width="8" height="6" viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0.562102 2.4141L3.1521 5.0041C3.5421 5.3941 4.1721 5.3941 4.5621 5.0041L7.1521 2.4141C7.7821 1.7841 7.3321 0.704102 6.4421 0.704102H1.2621C0.372102 0.704102 -0.0678978 1.7841 0.562102 2.4141Z" fill="currentColor"/>
   </svg>`;
export const destructIcon = `<svg class="icon-delete" width="24" height="24" viewBox="0 0 40 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M4.94 35.0072H35.06C38.14 35.0072 40.06 31.6672 38.52 29.0072L23.46 2.98719C21.92 0.327188 18.08 0.327188 16.54 2.98719L1.48 29.0072C-0.0600016 31.6672 1.86 35.0072 4.94 35.0072ZM20 21.0072C18.9 21.0072 18 20.1072 18 19.0072V15.0072C18 13.9072 18.9 13.0072 20 13.0072C21.1 13.0072 22 13.9072 22 15.0072V19.0072C22 20.1072 21.1 21.0072 20 21.0072ZM22 29.0072H18V25.0072H22V29.0072Z" fill="#E02E2E"/>
   </svg>`;
export const waringIcon = `<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M20 0C8.96 0 0 8.96 0 20C0 31.04 8.96 40 20 40C31.04 40 40 31.04 40 20C40 8.96 31.04 0 20 0ZM22 30H18V26H22V30ZM22 22H18V10H22V22Z" fill="#FDD855"/>
   </svg>`;
export const cancelIcon = `<svg width="40" height="41" viewBox="0 0 40 41" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M20 0.5C8.94 0.5 0 9.44 0 20.5C0 31.56 8.94 40.5 20 40.5C31.06 40.5 40 31.56 40 20.5C40 9.44 31.06 0.5 20 0.5ZM28.6 29.1C27.82 29.88 26.56 29.88 25.78 29.1L20 23.32L14.22 29.1C13.44 29.88 12.18 29.88 11.4 29.1C10.62 28.32 10.62 27.06 11.4 26.28L17.18 20.5L11.4 14.72C10.62 13.94 10.62 12.68 11.4 11.9C12.18 11.12 13.44 11.12 14.22 11.9L20 17.68L25.78 11.9C26.56 11.12 27.82 11.12 28.6 11.9C29.38 12.68 29.38 13.94 28.6 14.72L22.82 20.5L28.6 26.28C29.36 27.04 29.36 28.32 28.6 29.1Z" fill="#FDD855"/>
   </svg>`;
export const deleteIcon = `<svg width="28" height="37" viewBox="0 0 28 37" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M2 32.5C2 34.7 3.8 36.5 6 36.5H22C24.2 36.5 26 34.7 26 32.5V8.5H2V32.5ZM8.34 19.68C7.56 18.9 7.56 17.64 8.34 16.86C9.12 16.08 10.38 16.08 11.16 16.86L14 19.68L16.82 16.86C17.6 16.08 18.86 16.08 19.64 16.86C20.42 17.64 20.42 18.9 19.64 19.68L16.82 22.5L19.64 25.32C20.42 26.1 20.42 27.36 19.64 28.14C18.86 28.92 17.6 28.92 16.82 28.14L14 25.32L11.18 28.14C10.4 28.92 9.14 28.92 8.36 28.14C7.58 27.36 7.58 26.1 8.36 25.32L11.18 22.5L8.34 19.68ZM26 2.5H21L19.58 1.08C19.22 0.72 18.7 0.5 18.18 0.5H9.82C9.3 0.5 8.78 0.72 8.42 1.08L7 2.5H2C0.9 2.5 0 3.4 0 4.5C0 5.6 0.9 6.5 2 6.5H26C27.1 6.5 28 5.6 28 4.5C28 3.4 27.1 2.5 26 2.5Z" fill="#E02E2E"/>
   </svg>`;
export const dragHandle = `<svg width="12" height="5" viewBox="0 0 12 5" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12 0.25H0V1.75H12V0.25ZM0 4.75H12V3.25H0V4.75Z" fill="#828282"/>
   </svg>`;
export const folderUpload = `<svg width="20" height="16" viewBox="0 0 20 16" fill="auto" xmlns="http://www.w3.org/2000/svg">
<path d="M18 2H10L8 0H2C0.9 0 0.00999999 0.9 0.00999999 2L0 14C0 15.1 0.9 16 2 16H18C19.1 16 20 15.1 20 14V4C20 2.9 19.1 2 18 2ZM18 14H2V4H18V14ZM6 9.01L7.41 10.42L9 8.84V13H11V8.84L12.59 10.43L14 9.01L10.01 5L6 9.01Z" fill="auto"/>
</svg>`;
export const uploadIcon = `<svg fill="auto" xmlns="http://www.w3.org/2000/svg">
<path d="M14 11V14H2V11H0V14C0 15.1 0.9 16 2 16H14C15.1 16 16 15.1 16 14V11H14ZM3 5L4.41 6.41L7 3.83V12H9V3.83L11.59 6.41L13 5L8 0L3 5Z" fill="auto"/>
</svg>`;
export const uploadPlaceholder = `<svg fill="auto" xmlns="http://www.w3.org/2000/svg">
<path d="M2.4 7.29562H3.99V12.2956C3.99 12.8456 4.44 13.2956 4.99 13.2956H8.99C9.54 13.2956 9.99 12.8456 9.99 12.2956V7.29562H11.58C12.47 7.29562 12.92 6.21562 12.29 5.58562L7.7 0.995625C7.31 0.605625 6.68 0.605625 6.29 0.995625L1.7 5.58562C1.07 6.21562 1.51 7.29562 2.4 7.29562ZM0 16.2956C0 16.8456 0.45 17.2956 1 17.2956H13C13.55 17.2956 14 16.8456 14 16.2956C14 15.7456 13.55 15.2956 13 15.2956H1C0.45 15.2956 0 15.7456 0 16.2956Z" fill="auto"/>
</svg>`;
export const loadingIcon = `<svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M9.5 18.9961C6.96094 18.9961 4.57422 18.0078 2.78125 16.2148C0.988281 14.4219 0 12.0352 0 9.49609C0 6.95898 0.988281 4.57422 2.78125 2.78125C4.33729 1.22521 6.34052 0.275245 8.50242 0.0513495C9.05136 -0.00550085 9.5 0.447383 9.5 0.999259C9.5 1.55095 9.05048 1.99127 8.50364 2.06424C4.83478 2.55378 1.99805 5.69876 1.99805 9.49609C1.99805 13.6328 5.36328 16.998 9.5 16.998C13.2971 16.998 16.4436 14.16 16.9322 10.4927C17.005 9.9458 17.4453 9.49609 17.9971 9.49609C18.5488 9.49609 19.0016 9.94462 18.9448 10.4934C18.7211 12.6565 17.7711 14.6586 16.2148 16.2148C14.4219 18.0078 12.0371 18.9961 9.5 18.9961Z" fill="#408BF9"/>
</svg>`;
export const processingIcon = `<svg width="20" height="20" viewBox="0 0 20 20" fill="auto" xmlns="http://www.w3.org/2000/svg">
<path d="M14.95 8.23001L9.29 13.89C8.9 14.28 8.27 14.28 7.88 13.89L5.05 11.06C4.66 10.67 4.66 10.04 5.05 9.65001C5.44 9.26001 6.07 9.26001 6.46 9.65001L8.58 11.77L13.53 6.82001C13.92 6.43001 14.55 6.43001 14.94 6.82001C15.34 7.21001 15.34 7.84001 14.95 8.23001ZM2 10C2 7.67001 3.02 5.58001 4.62 4.12001L6.15 5.65001C6.46 5.96001 7 5.74001 7 5.29001V1.00001C7 0.720011 6.78 0.500011 6.5 0.500011H2.21C1.76 0.500011 1.54 1.04001 1.86 1.35001L3.2 2.70001C1.24 4.52001 0 7.11001 0 10C0 14.75 3.32 18.73 7.76 19.75C8.39 19.89 9 19.42 9 18.77C9 18.3 8.67 17.9 8.21 17.79C4.66 16.98 2 13.8 2 10ZM20 10C20 5.25001 16.68 1.27001 12.24 0.250011C11.61 0.110011 11 0.580011 11 1.23001C11 1.70001 11.33 2.10001 11.79 2.21001C15.34 3.02001 18 6.20001 18 10C18 12.33 16.98 14.42 15.38 15.88L13.85 14.35C13.54 14.04 13 14.26 13 14.71V19C13 19.28 13.22 19.5 13.5 19.5H17.79C18.24 19.5 18.46 18.96 18.14 18.65L16.8 17.3C18.76 15.48 20 12.89 20 10Z" fill="auto"/>
</svg>`;
export const contributionIcon = `<svg width="20" height="20" viewBox="0 0 20 20" fill="auto" xmlns="http://www.w3.org/2000/svg">
<path d="M17.83 6L15.56 3.73C15.63 3.31 15.74 2.92 15.88 2.58C15.96 2.4 16 2.21 16 2C16 1.17 15.33 0.5 14.5 0.5C12.86 0.5 11.41 1.29 10.5 2.5H5.5C2.46 2.5 0 4.96 0 8C0 11.04 2.5 19.5 2.5 19.5H8V17.5H10V19.5H15.5L17.18 13.91L20 12.97V6H17.83ZM11 7.5H6V5.5H11V7.5ZM14 9.5C13.45 9.5 13 9.05 13 8.5C13 7.95 13.45 7.5 14 7.5C14.55 7.5 15 7.95 15 8.5C15 9.05 14.55 9.5 14 9.5Z" fill="auto"/>
</svg>`;
export const payrollIcon = `<svg width="22" height="16" viewBox="0 0 22 16" fill="auto" xmlns="http://www.w3.org/2000/svg">
<path d="M22 4V14C22 15.1 21.1 16 20 16H4C3.45 16 3 15.55 3 15C3 14.45 3.45 14 4 14H20V4C20 3.45 20.45 3 21 3C21.55 3 22 3.45 22 4ZM3 12C1.34 12 0 10.66 0 9V3C0 1.34 1.34 0 3 0H15C16.66 0 18 1.34 18 3V10C18 11.1 17.1 12 16 12H3ZM6 6C6 7.66 7.34 9 9 9C10.66 9 12 7.66 12 6C12 4.34 10.66 3 9 3C7.34 3 6 4.34 6 6Z" fill="auto"/>
</svg>`;
export const scheduledIcon = `<svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M10.9751 5.05964C11.0501 5.05964 11.1176 5.06714 11.1851 5.06714L1.5176 0.99464C1.0226 0.79214 0.475098 1.15214 0.475098 1.69214V4.47464C0.475098 4.81964 0.707598 5.11964 1.0451 5.20214L6.4751 6.55964L1.0451 7.91714C0.707598 7.99964 0.475098 8.29964 0.475098 8.64464V11.4271C0.475098 11.9671 1.0226 12.3271 1.5176 12.1171L5.7251 10.3471C5.7251 10.3321 5.7251 10.3246 5.7251 10.3096C5.7251 7.41464 8.0801 5.05964 10.9751 5.05964Z" fill="#FD8A03"/>
<path d="M10.9751 6.55964C8.9051 6.55964 7.2251 8.23964 7.2251 10.3096C7.2251 12.3796 8.9051 14.0596 10.9751 14.0596C13.0451 14.0596 14.7251 12.3796 14.7251 10.3096C14.7251 8.23964 13.0451 6.55964 10.9751 6.55964ZM11.9426 11.8096L10.7051 10.5721C10.6376 10.5046 10.5926 10.4071 10.5926 10.3096V8.43464C10.5926 8.22464 10.7576 8.05964 10.9676 8.05964C11.1776 8.05964 11.3426 8.22464 11.3426 8.43464V10.1521L12.4676 11.2771C12.6176 11.4271 12.6176 11.6596 12.4676 11.8096C12.3251 11.9596 12.0926 11.9596 11.9426 11.8096Z" fill="#FD8A03"/>
</svg>`;
export const inReviewIcon = `<svg width="10" height="15" viewBox="0 0 10 15" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M1.6001 0C0.775098 0 0.100098 0.675 0.100098 1.5L0.107598 3.885C0.107598 4.2825 0.265098 4.6575 0.542598 4.9425L3.1001 7.5L0.542598 10.0725C0.265098 10.35 0.107598 10.7325 0.107598 11.13L0.100098 13.5C0.100098 14.325 0.775098 15 1.6001 15H7.6001C8.4251 15 9.1001 14.325 9.1001 13.5V11.13C9.1001 10.7325 8.9426 10.35 8.6651 10.0725L6.1001 7.5L8.6576 4.95C8.9426 4.665 9.1001 4.2825 9.1001 3.885V1.5C9.1001 0.675 8.4251 0 7.6001 0H1.6001ZM7.6001 11.1825V12.75C7.6001 13.1625 7.2626 13.5 6.8501 13.5H2.3501C1.9376 13.5 1.6001 13.1625 1.6001 12.75V11.1825C1.6001 10.98 1.6826 10.7925 1.8176 10.65L4.6001 7.875L7.3826 10.6575C7.5176 10.7925 7.6001 10.9875 7.6001 11.1825Z" fill="#FBC94A"/>
</svg>`;
export const doubleArrowDownIcon = `<svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
   <path d="M11.2946 0.71461C10.9046 0.32461 10.2746 0.32461 9.88461 0.71461L6.00461 4.58461L2.11461 0.704609C1.72461 0.314609 1.09461 0.314609 0.704609 0.704609C0.314609 1.09461 0.314609 1.72461 0.704609 2.11461L5.29461 6.70461C5.68461 7.09461 6.31461 7.09461 6.70461 6.70461L11.2946 2.11461C11.6846 1.73461 11.6846 1.10461 11.2946 0.71461Z" fill="white"/>
   <path d="M11.2946 7.30461C10.9046 6.91461 10.2746 6.91461 9.88461 7.30461L6.00461 11.1746L2.12461 7.29461C1.73461 6.90461 1.10461 6.90461 0.71461 7.29461C0.32461 7.68461 0.32461 8.31461 0.71461 8.70461L5.30461 13.2946C5.69461 13.6846 6.32461 13.6846 6.71461 13.2946L11.3046 8.70461C11.6846 8.32461 11.6846 7.69461 11.2946 7.30461Z" fill="white"/>
   </svg>`;
export const doubleArrowUpIcon = `<svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
   <path d="M0.704609 13.2846C1.09461 13.6746 1.72461 13.6746 2.11461 13.2846L6.00461 9.41461L9.88461 13.2946C10.2746 13.6846 10.9046 13.6846 11.2946 13.2946C11.6846 12.9046 11.6846 12.2746 11.2946 11.8846L6.70461 7.29461C6.31461 6.90461 5.68461 6.90461 5.29461 7.29461L0.704609 11.8746C0.314609 12.2646 0.314609 12.8946 0.704609 13.2846Z" fill="white"/>
   <path d="M0.704609 6.69461C1.09461 7.08461 1.72461 7.08461 2.11461 6.69461L6.00461 2.82461L9.88461 6.70461C10.2746 7.09461 10.9046 7.09461 11.2946 6.70461C11.6846 6.31461 11.6846 5.68461 11.2946 5.29461L6.70461 0.704609C6.31461 0.314609 5.68461 0.314609 5.29461 0.704609L0.704609 5.28461C0.314609 5.67461 0.314609 6.30461 0.704609 6.69461Z" fill="white"/>
   </svg>`;
export const refundIcon = `<svg width="34" height="36" viewBox="0 0 34 36" fill="auto" xmlns="http://www.w3.org/2000/svg">
<path d="M14.4075 20.418V12.418C14.4075 11.318 13.5075 10.418 12.4075 10.418H4.4075V6.41797H12.4075C13.5075 6.41797 14.4075 5.51797 14.4075 4.41797C14.4075 3.31797 13.5075 2.41797 12.4075 2.41797H9.4075C9.4075 1.31797 8.5075 0.417969 7.4075 0.417969C6.3075 0.417969 5.4075 1.31797 5.4075 2.41797H2.4075C1.3075 2.41797 0.407501 3.31797 0.407501 4.41797V12.418C0.407501 13.518 1.3075 14.418 2.4075 14.418H10.4075V18.418H2.4075C1.3075 18.418 0.407501 19.318 0.407501 20.418C0.407501 21.518 1.3075 22.418 2.4075 22.418H5.4075C5.4075 23.518 6.3075 24.418 7.4075 24.418C8.5075 24.418 9.4075 23.518 9.4075 22.418H12.4075C13.5075 22.418 14.4075 21.518 14.4075 20.418Z" fill="#1C7975"/>
<path d="M30.1675 20.858L20.2675 30.758L16.0275 26.518C15.2475 25.738 13.9875 25.738 13.2075 26.518C12.4275 27.298 12.4275 28.558 13.2075 29.338L18.8675 34.998C19.6475 35.778 20.9075 35.778 21.6875 34.998L33.0075 23.678C33.7875 22.898 33.7875 21.638 33.0075 20.858C32.2075 20.078 30.9475 20.078 30.1675 20.858Z" fill="auto"/>
</svg>`;
export const buyBackIcon = `<svg width="32" height="40" viewBox="0 0 32 40" fill="auto" xmlns="http://www.w3.org/2000/svg">
<path d="M20 0H4C1.8 0 0 1.8 0 4V36C0 38.2 1.8 40 4 40H28C30.2 40 32 38.2 32 36V12L20 0ZM4 36V4H18V12H28V36H4ZM14 34H18V32H20C21.1 32 22 31.1 22 30V24C22 22.9 21.1 22 20 22H14V20H22V16H18V14H14V16H12C10.9 16 10 16.9 10 18V24C10 25.1 10.9 26 12 26H18V28H10V32H14V34Z" fill="auto"/>
</svg>`;
export const deductionDisableIcon = `<svg width="13" height="15" viewBox="0 0 13 15" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M7.12687 3.675C8.46187 3.675 8.95687 4.3125 9.00187 5.25H10.6594C10.6069 3.96 9.81938 2.775 8.25187 2.3925V0.75H6.00187V2.37C5.60437 2.46 5.22937 2.595 4.89188 2.775L5.99437 3.8775C6.30187 3.75 6.67688 3.675 7.12687 3.675ZM1.74937 1.545L0.796875 2.4975L3.37687 5.0775C3.37687 6.6375 4.54687 7.485 6.30937 8.01L8.94187 10.6425C8.68687 11.0025 8.15437 11.325 7.12687 11.325C5.58187 11.325 4.97438 10.635 4.89188 9.75H3.24187C3.33188 11.3925 4.56187 12.315 6.00187 12.6225V14.25H8.25187V12.6375C8.97188 12.5025 9.61688 12.225 10.0894 11.7975L11.7544 13.4625L12.7069 12.51L1.74937 1.545Z" fill="#828282"/>
</svg>`;
export const noteIcon = `<svg width="13" height="15" viewBox="0 0 13 15" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M2 0C1.175 0 0.5075 0.675 0.5075 1.5L0.5 13.5C0.5 14.325 1.1675 15 1.9925 15H11C11.825 15 12.5 14.325 12.5 13.5V5.1225C12.5 4.725 12.3425 4.3425 12.0575 4.065L8.435 0.4425C8.1575 0.1575 7.775 0 7.3775 0H2ZM7.25 4.5V1.125L11.375 5.25H8C7.5875 5.25 7.25 4.9125 7.25 4.5Z" fill="#60a5ce"/>
</svg>
`;
export const checkCircle = `<svg width="24" height="24" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 24 24"  fill="#14C457"><path d="M0 0h24v24H0z" fill="none"/><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z"/></svg>`;
export const iconUploadOutline = `<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="auto"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M14 2H6c-1.1 0-1.99.9-1.99 2L4 20c0 1.1.89 2 1.99 2H18c1.1 0 2-.9 2-2V8l-6-6zm4 18H6V4h7v5h5v11zM8 15.01l1.41 1.41L11 14.84V19h2v-4.16l1.59 1.59L16 15.01 12.01 11z"/></svg>`;
export const iconGenerateOutline = `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M5.5 9.5H8.5V19.5H5.5V9.5ZM5.5 4.5H8.5V8.5H5.5V4.5ZM15.5 15.5H18.5V19.5H15.5V15.5ZM15.5 12.5H18.5V14.5H15.5V12.5ZM10.5 12.5H13.5V19.5H10.5V12.5ZM10.5 8.5H13.5V11.5H10.5V8.5Z" fill="#408BF9"/></svg>`;
export const iconUpload = `<svg width="16" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M15.41 5.41L10.58 0.58C10.21 0.21 9.7 0 9.17 0H2C0.9 0 0.0100002 0.9 0.0100002 2L0 18C0 19.1 0.89 20 1.99 20H14C15.1 20 16 19.1 16 18V6.83C16 6.3 15.79 5.79 15.41 5.41ZM10.8 13H9V16C9 16.55 8.55 17 8 17C7.45 17 7 16.55 7 16V13H5.21C4.76 13 4.54 12.46 4.86 12.15L7.66 9.36C7.86 9.17 8.17 9.17 8.37 9.36L11.16 12.15C11.46 12.46 11.24 13 10.8 13ZM10 7C9.45 7 9 6.55 9 6V1.5L14.5 7H10Z" fill="#408BF9"/></svg>`;
export const iconDownload = `<svg width="14" height="18" viewBox="0 0 14 18" fill="auto" xmlns="http://www.w3.org/2000/svg"><path d="M14 6.5H10V0.5H4V6.5H0L7 13.5L14 6.5ZM0 15.5V17.5H14V15.5H0Z" fill="auto"/></svg>`;
export const vectorIcon = `<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
<path d="M0 15C0 15.55 0.45 16 1 16H6V14H1C0.45 14 0 14.45 0 15ZM0 3C0 3.55 0.45 4 1 4H10V2H1C0.45 2 0 2.45 0 3ZM10 17V16H17C17.55 16 18 15.55 18 15C18 14.45 17.55 14 17 14H10V13C10 12.45 9.55 12 9 12C8.45 12 8 12.45 8 13V17C8 17.55 8.45 18 9 18C9.55 18 10 17.55 10 17ZM4 7V8H1C0.45 8 0 8.45 0 9C0 9.55 0.45 10 1 10H4V11C4 11.55 4.45 12 5 12C5.55 12 6 11.55 6 11V7C6 6.45 5.55 6 5 6C4.45 6 4 6.45 4 7ZM18 9C18 8.45 17.55 8 17 8H8V10H17C17.55 10 18 9.55 18 9ZM13 6C13.55 6 14 5.55 14 5V4H17C17.55 4 18 3.55 18 3C18 2.45 17.55 2 17 2H14V1C14 0.45 13.55 0 13 0C12.45 0 12 0.45 12 1V5C12 5.55 12.45 6 13 6Z" fill="#124462"/>
</svg>`;
export const iconUploadAttachment = `<svg width="16" height="16" viewBox="0 0 16 16" fill="auto" xmlns="http://www.w3.org/2000/svg"><path d="M14 11V14H2V11H0V14C0 15.1 0.9 16 2 16H14C15.1 16 16 15.1 16 14V11H14ZM3 5L4.41 6.41L7 3.83V12H9V3.83L11.59 6.41L13 5L8 0L3 5Z"/></svg>`;
export const iconRestart = `<svg width="16" height="18" viewBox="0 0 16 19" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M8 2.29153V0.501535C8 0.0515345 7.46 -0.168466 7.15 0.151534L4.35 2.94153C4.15 3.14153 4.15 3.45153 4.35 3.65153L7.14 6.44153C7.46 6.75153 8 6.53153 8 6.08153V4.29153C11.31 4.29153 14 6.98153 14 10.2915C14 13.0115 12.17 15.3115 9.69 16.0415C9.27 16.1615 9 16.5615 9 16.9915C9 17.6415 9.62 18.1515 10.25 17.9615C13.57 16.9915 16 13.9315 16 10.2915C16 5.87153 12.42 2.29153 8 2.29153Z" fill="#60a5ce"/>
<path d="M2 10.2915C2 8.95153 2.44 7.71153 3.19 6.70153C3.49 6.30153 3.45 5.75153 3.1 5.39153C2.68 4.97153 1.96 5.01153 1.6 5.49153C0.6 6.83153 0 8.49153 0 10.2915C0 13.9315 2.43 16.9915 5.75 17.9615C6.38 18.1515 7 17.6415 7 16.9915C7 16.5615 6.73 16.1615 6.31 16.0415C3.83 15.3115 2 13.0115 2 10.2915Z" fill="#60a5ce"/>
</svg>`;
export const calculationIcon = `<svg width="20" height="16" viewBox="0 0 20 16" fill="auto" xmlns="http://www.w3.org/2000/svg">
<path d="M16 0H2C0.9 0 0 0.9 0 2V16C0 17.1 0.9 18 2 18H16C17.1 18 18 17.1 18 16V2C18 0.9 17.1 0 16 0ZM10.03 4.06L11.09 3L12.5 4.41L13.91 3L14.97 4.06L13.56 5.47L14.97 6.88L13.91 7.94L12.5 6.54L11.09 7.95L10.03 6.89L11.44 5.48L10.03 4.06ZM3.25 4.72H8.25V6.22H3.25V4.72ZM8.5 13H6.5V15H5V13H3V11.5H5V9.5H6.5V11.5H8.5V13ZM15 14.25H10V12.75H15V14.25ZM15 11.75H10V10.25H15V11.75Z" fill="auto"/>
</svg>`;
export const missingAddressIcon = `<svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M10 21V15H14V21H19V13H22L12 4L2 13H5V21H10Z" fill="#E02E2E"/>
<mask id="path-2-inside-1_44204_23067" fill="white">
<path d="M1.33398 5.5625L2.1398 4.50022L23.0592 20.369L22.2534 21.4313L1.33398 5.5625Z"/>
</mask>
<path d="M1.33398 5.5625L2.1398 4.50022L23.0592 20.369L22.2534 21.4313L1.33398 5.5625Z" fill="#E02E2E"/>
<path d="M22.4951 21.1126L1.57573 5.24382L1.09224 5.88118L22.0116 21.75L22.4951 21.1126Z" fill="white" mask="url(#path-2-inside-1_44204_23067)"/>
</svg>
`;
export const pensionEstimatorIcon = `<svg width="20" height="16" viewBox="0 0 20 16" fill="auto" xmlns="http://www.w3.org/2000/svg">
<path d="M0.933777 4.5H11.9338V6.5H0.933777V4.5ZM0.933777 2.5H11.9338V0.5H0.933777V2.5ZM0.933777 10.5H7.93378V8.5H0.933777V10.5ZM15.9438 7.37L16.6538 6.66C17.0438 6.27 17.6738 6.27 18.0638 6.66L18.7738 7.37C19.1638 7.76 19.1638 8.39 18.7738 8.78L18.0638 9.49L15.9438 7.37ZM15.2338 8.08L9.93378 13.38V15.5H12.0538L17.3538 10.2L15.2338 8.08Z" fill="auto"/>
</svg>`;
export const successedNotiIcon = `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20ZM16.59 7.58L10 14.17L7.41 11.59L6 13L10 17L18 9L16.59 7.58Z" fill="#14C457"/>
</svg>
`;
export const failedNotiIcon = `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M15.73 3H8.27L3 8.27V15.73L8.27 21H15.73L21 15.73V8.27L15.73 3ZM19 14.9L14.9 19H9.1L5 14.9V9.1L9.1 5H14.9L19 9.1V14.9Z" fill="#E02E2E"/>
<path d="M12 17C12.5523 17 13 16.5523 13 16C13 15.4477 12.5523 15 12 15C11.4477 15 11 15.4477 11 16C11 16.5523 11.4477 17 12 17Z" fill="#E02E2E"/>
<path d="M11 7H13V14H11V7Z" fill="#E02E2E"/>
</svg>`;
export const notiBellIcon = `<svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M16.9995 33.25C18.8328 33.25 20.3328 31.75 20.3328 29.9167H13.6661C13.6661 31.75 15.1661 33.25 16.9995 33.25ZM26.9995 23.25V14.9167C26.9995 9.8 24.2828 5.51667 19.4995 4.38333V3.25C19.4995 1.86667 18.3828 0.75 16.9995 0.75C15.6161 0.75 14.4995 1.86667 14.4995 3.25V4.38333C9.73281 5.51667 6.99948 9.78333 6.99948 14.9167V23.25L3.66615 26.5833V28.25H30.3328V26.5833L26.9995 23.25ZM23.6661 24.9167H10.3328V14.9167C10.3328 10.7833 12.8495 7.41667 16.9995 7.41667C21.1495 7.41667 23.6661 10.7833 23.6661 14.9167V24.9167ZM9.63281 3.38333L7.24948 1C3.24948 4.05 0.616146 8.75 0.382812 14.0833H3.71615C3.96615 9.66667 6.23281 5.8 9.63281 3.38333ZM30.2828 14.0833H33.6161C33.3661 8.75 30.7328 4.05 26.7495 1L24.3828 3.38333C27.7495 5.8 30.0328 9.66667 30.2828 14.0833Z" fill="#29B373"/>
<path d="M16.9995 33.25C18.8328 33.25 20.3328 31.75 20.3328 29.9167H13.6661C13.6661 31.75 15.1661 33.25 16.9995 33.25ZM26.9995 23.25V14.9167C26.9995 9.8 24.2828 5.51667 19.4995 4.38333V3.25C19.4995 1.86667 18.3828 0.75 16.9995 0.75C15.6161 0.75 14.4995 1.86667 14.4995 3.25V4.38333C9.73281 5.51667 6.99948 9.78333 6.99948 14.9167V23.25L3.66615 26.5833V28.25H30.3328V26.5833L26.9995 23.25ZM23.6661 24.9167H10.3328V14.9167C10.3328 10.7833 12.8495 7.41667 16.9995 7.41667C21.1495 7.41667 23.6661 10.7833 23.6661 14.9167V24.9167ZM9.63281 3.38333L7.24948 1C3.24948 4.05 0.616146 8.75 0.382812 14.0833H3.71615C3.96615 9.66667 6.23281 5.8 9.63281 3.38333ZM30.2828 14.0833H33.6161C33.3661 8.75 30.7328 4.05 26.7495 1L24.3828 3.38333C27.7495 5.8 30.0328 9.66667 30.2828 14.0833Z" fill="black" fill-opacity="0.3"/>
</svg>`;

export const pensionDropPlanIcon = `<svg width="24" height="24" viewBox="0 0 24 24" fill="auto" xmlns="http://www.w3.org/2000/svg">
<path d="M13.5 9.5H2.5V11.5H13.5V9.5Z" fill="auto"/>
<path d="M13.5 5.5H2.5V7.5H13.5V5.5Z" fill="auto"/>
<path d="M9.5 13.5H2.5V15.5H9.5V13.5Z" fill="auto"/>
<path d="M20.09 11.43L15.84 15.67L13.72 13.55L12.31 14.96L15.84 18.5L21.5 12.84L20.09 11.43Z" fill="auto"/>
</svg>`;

export const pensionViewWeek = `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
<path d="M20 4H4C2.9 4 2 4.9 2 6V18C2 19.1 2.9 20 4 20H20C21.1 20 22 19.1 22 18V6C22 4.9 21.1 4 20 4ZM8 18H4V6H8V18ZM14 18H10V6H14V18ZM20 18H16V6H20V18Z" fill="#828282"/>
</svg>`;

export const pendingPaymentsIcon = `<svg width="48" height="48" viewBox="0 0 48 48" fill="auto" xmlns="http://www.w3.org/2000/svg">
<path d="M19 32V16C19 13.8 20.78 12 23 12H41V10C41 7.8 39.2 6 37 6H9C6.78 6 5 7.8 5 10V38C5 40.2 6.78 42 9 42H37C39.2 42 41 40.2 41 38V36H23C20.78 36 19 34.2 19 32ZM25 16C23.9 16 23 16.9 23 18V30C23 31.1 23.9 32 25 32H43V16H25ZM31 27C29.34 27 28 25.66 28 24C28 22.34 29.34 21 31 21C32.66 21 34 22.34 34 24C34 25.66 32.66 27 31 27Z" fill="#9a9546"/>
</svg>`;

export const priceChange = `<svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24" fill="auto"><path d="M80-160v-640h800v640H80Zm80-80h640v-480H160v480Zm0 0v-480 480Zm160-40h80v-40h40q17 0 28.5-11.5T480-360v-120q0-17-11.5-28.5T440-520H320v-40h160v-80h-80v-40h-80v40h-40q-17 0-28.5 11.5T240-600v120q0 17 11.5 28.5T280-440h120v40H240v80h80v40Zm320-30 80-80H560l80 80Zm-80-250h160l-80-80-80 80Z"/></svg>`;
export const employerPinIcon = `<svg width="24" height="24" viewBox="0 0 24 24" fill="#124462" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M12.4185 21.9508C17.4154 17.3723 20 13.4092 20 10.2462C20 5.50769 16.5292 2 12 2C7.47077 2 4 5.52 4 10.2462C4 13.4092 6.58462 17.3723 11.5815 21.9385L12 22.32L12.4185 21.9508ZM9.53086 7.53086V5.55556H14.4691V9.50617H16.4444V14.4444H12.4938V12.4691H11.5062V14.4444H7.55556V7.53086H9.53086ZM8.54321 13.4568H9.53086V12.4691H8.54321V13.4568ZM8.54321 11.4815H9.53086V10.4938H8.54321V11.4815ZM8.54321 9.50617H9.53086V8.51852H8.54321V9.50617ZM10.5185 11.4815H11.5062V10.4938H10.5185V11.4815ZM10.5185 9.50617H11.5062V8.51852H10.5185V9.50617ZM10.5185 7.53086H11.5062V6.54321H10.5185V7.53086ZM12.4938 11.4815H13.4815V10.4938H12.4938V11.4815ZM12.4938 9.50617H13.4815V8.51852H12.4938V9.50617ZM12.4938 7.53086H13.4815V6.54321H12.4938V7.53086ZM14.4691 13.4568H15.4568V12.4691H14.4691V13.4568ZM14.4691 11.4815H15.4568V10.4938H14.4691V11.4815Z" fill="#124462"/>
</svg>`;
export const monetization_on = `<svg width="40" height="41" viewBox="0 0 40 41" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M20 0.5C8.96 0.5 0 9.46 0 20.5C0 31.54 8.96 40.5 20 40.5C31.04 40.5 40 31.54 40 20.5C40 9.46 31.04 0.5 20 0.5ZM21.76 32.02V32.74C21.76 33.7 20.98 34.5 20 34.5C19.04 34.5 18.24 33.72 18.24 32.74V31.9C16.98 31.6 14.38 30.68 12.86 27.7C12.4 26.82 12.84 25.72 13.76 25.34L13.9 25.28C14.72 24.94 15.64 25.28 16.06 26.06C16.7 27.28 17.96 28.8 20.3 28.8C22.16 28.8 24.26 27.84 24.26 25.58C24.26 23.66 22.86 22.66 19.7 21.52C17.5 20.74 13 19.46 13 14.9C13 14.7 13.02 10.1 18.24 8.98V8.26C18.24 7.28 19.04 6.5 20 6.5C20.96 6.5 21.76 7.28 21.76 8.26V9C23.9 9.38 25.26 10.52 26.08 11.6C26.76 12.48 26.4 13.76 25.36 14.2C24.64 14.5 23.8 14.26 23.32 13.64C22.76 12.88 21.76 12.1 20.12 12.1C18.72 12.1 16.5 12.84 16.5 14.88C16.5 16.78 18.22 17.5 21.78 18.68C26.58 20.34 27.8 22.78 27.8 25.58C27.8 30.84 22.8 31.84 21.76 32.02Z" fill="#1D2E4E"/>
</svg>`;
