import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from 'src/environments/environment';
import { SortType } from '@ptg-shared/constance';
import {
  MBConfigCalendarResponse,
  MBConfigCalendarResult,
} from '@ptg-processing/features/payroll-calendar-container/services/models';
import {
  BankAccountDetailsReponse,
  GetPayrollRunListPaymentRequest,
  GetPayrollRunListPaymentResponse,
  GetPayrollRunStateResponse,
  GetPayrollRunSummaryRequest,
  PayrollRunSummary,
  SubmitToBankRequest,
  UpdateTransactionsRequest,
  GetPayrollErrorMessageResponse,
  GetPaymentLevelErrorListsRequest,
  GetPaymentLevelErrorListsResponse,
  GetTotalPayeesPaymentsRequest,
  GetTotalPayeesPaymentsResponse,
  EditCheckNumberRequest,
  GetTotalDeductionsRequest,
  GetTotalDeductionResponse,
  PayrollRunStep,
  MissingFillingForms,
  GetSearchPayeesRequest,
  GetSearchPayeesResponse,
  GetACHFileRequest,
  GetACHFileResponse,
  ClearAuditTrailRequest,
  SubmitToBankRequestQuery,
  EditStartCheckNumberReviewRequest,
} from './models';

@Injectable()
export class PayrollRunService {
  constructor(private httpClient: HttpClient) {}

  getPayrollRunSummary(request: GetPayrollRunSummaryRequest): Observable<PayrollRunSummary> {
    let params = new HttpParams();
    if (request?.filter) {
      Object.entries(request.filter).forEach((filter) => {
        if (filter[1] || filter[1] === 0) {
          if (filter[0] === 'changesOnly') {
            if (request.selectedState === PayrollRunStep.Review) {
              params = params.append(`${filter[0]}`, filter[1]);
            }
          } else {
            params = params.append(`${filter[0]}`, filter[1]);
          }
        }
      });
    }

    if (request.paymentInstructionId) {
      params = params.append('PaymentInstructionId', request.paymentInstructionId);
    }
    if (request.selectedState !== undefined) {
      params = params.append('selectedState', request.selectedState);
    }
    return this.httpClient.get<PayrollRunSummary>(
      `${environment.apiUrl}/new-payroll/payroll-runs/${request?.payrollRunId}/summary`,
      { params },
    );
  }

  getBankAccounts(request: any): Observable<BankAccountDetailsReponse> {
    let params = new HttpParams();
    params = params.append('payrollRunId', request.payrollRunId);
    return this.httpClient.get<BankAccountDetailsReponse>(`${environment.apiUrl}/new-payroll/banks/assignments/0`, {
      params,
    });
  }

  getBankConfigCalendar(): Observable<MBConfigCalendarResult> {
    return this.httpClient.get<MBConfigCalendarResponse>(`${environment.apiUrl}/new-payroll/bank-configuration`).pipe(
      map((result) => {
        return result?.bankConfiguration;
      }),
    );
  }

  checkStartingCheckNumber = (bodyCheck: any) => {
    let params = new HttpParams();
    params = params.append('startingCheckNumber', bodyCheck.startingCheckNumber);
    if (bodyCheck.isViewOndeDuctionPayees) {
      return this.httpClient.get(
        `${environment.apiUrl}/new-payroll/deduction-payees-runs/${bodyCheck.payrollRunId}/${bodyCheck.accountId}/bank-assignments/starting-check-number/valid`,
        { params },
      );
    }
    return this.httpClient.get(
      `${environment.apiUrl}/new-payroll/banks/bank-accounts/${bodyCheck.accountId}/check-number/valid`,
      { params },
    );
  };

  setBankAccountStartingCheckNumber(
    accountId: string,
    payload: { startingCheckNumber: number; payrollRunId: string; isViewOndeDuctionPayees?: boolean },
  ) {
    if (payload.isViewOndeDuctionPayees) {
      return this.httpClient.put(
        `${environment.apiUrl}/new-payroll/deduction-payees-runs/${payload.payrollRunId}/${accountId}/bank-assignments/starting-check-number`,
        { startingCheckNumber: payload.startingCheckNumber },
      );
    }
    return this.httpClient.put(`${environment.apiUrl}/new-payroll/banks/bank-accounts/${accountId}/check-number`, {
      startingCheckNumber: payload.startingCheckNumber,
    });
  }

  getPayrollRunListPayment(query: GetPayrollRunListPaymentRequest): Observable<GetPayrollRunListPaymentResponse> {
    let params = new HttpParams();
    params = params.append('PageSize', query.pageSize);
    params = params.append('PageIndex', query.pageIndex);
    params = params.append('IsOnReview', query.isOnReview);
    params = params.append('PayrollRunId', query.payrollRunId);

    if (query.sortField === 'benefitPeriod') {
      params = params.append('SortNames', 'BenefitStartDate');
      params = params.append('SortType', query.sortType ? query.sortType : SortType.ASC);
    } else if (query.sortField) {
      params = params.append('SortNames', query.sortField[0].toUpperCase() + query.sortField.substring(1));
      params = params.append('SortType', query.sortType ? query.sortType : SortType.ASC);
    }

    if (query.id) {
      params = params.append('Id', query.id);
    }

    if (query.filter) {
      Object.entries(query.filter).forEach((filter) => {
        if (filter[1] || filter[1] === 0) {
          if (filter[0] === 'changesOnly') {
            if (query.isOnReview) params = params.append(`${filter[0]}`, filter[1]);
          } else {
            params = params.append(`${filter[0]}`, filter[1]);
          }
        }
      });
    }

    return this.httpClient.get<GetPayrollRunListPaymentResponse>(
      `${environment.apiUrl}/new-payroll/payroll-runs/${query?.payrollRunId}/data-table`,
      { params },
    );
  }

  editCheckMemo(payrollRunId: string, payload: { checkMemo: string }) {
    return this.httpClient.put(`${environment.apiUrl}/new-payroll/payroll-runs/${payrollRunId}/check-memo`, payload);
  }

  changeAchSent(payrollRunId: string, payload: { achSent: boolean }) {
    return this.httpClient.put(`${environment.apiUrl}/new-payroll/payroll-runs/${payrollRunId}/ach-sent`, payload);
  }

  changeChecksPrinted(payrollRunId: string, payload: { checksPrinted: boolean }) {
    return this.httpClient.put(
      `${environment.apiUrl}/new-payroll/payroll-runs/${payrollRunId}/checks-printed`,
      payload,
    );
  }

  finalizePayrollRun(payrollRunId: string, isAutoFinalize?: boolean) {
    let params = new HttpParams();
    if (isAutoFinalize) {
      params = params.append('isAutoFinalize', isAutoFinalize);
    }
    return this.httpClient.put(
      `${environment.apiUrl}/new-payroll/payroll-runs/${payrollRunId}/finalize`,
      {},
      { params },
    );
  }

  getPayrollRunState(payrollRunId: string): Observable<GetPayrollRunStateResponse> {
    return this.httpClient.get<GetPayrollRunStateResponse>(
      `${environment.apiUrl}/new-payroll/payroll-runs/${payrollRunId}/state`,
    );
  }

  setIssuePayment(payrollRunId: string) {
    return this.httpClient.put(`${environment.apiUrl}/new-payroll/payroll-runs/${payrollRunId}/issue-payment`, {
      payrollRunId,
    });
  }

  updateTransactions(request: UpdateTransactionsRequest): Observable<void> {
    return this.httpClient.put<void>(
      `${environment.apiUrl}/new-payroll/payroll-runs/${request.payrollRunId}/update-transaction`,
      request,
    );
  }

  validateSubmitToBank(request: SubmitToBankRequest): Observable<void> {
    let params = new HttpParams();
    if (request.bankId) params = params.append('BankId', request.bankId);
    params = params.append('ExcludeCheckDate', request.excludeCheckDate ?? false);
    params = params.append('ExcludeDepositDate', request.excludeDepositDate ?? false);
    return this.httpClient.get<void>(
      `${environment.apiUrl}/new-payroll/payroll-runs/${request.payrollRunId}/submit-to-bank/validity`,
      { params },
    );
  }

  submitToBank(request: SubmitToBankRequest, ostQuery?: SubmitToBankRequestQuery): Observable<void> {
    let params = new HttpParams();
    if (ostQuery) {
      params = params.append('isOst', !!ostQuery.isOst);
      params = params.append('ostWaitingTime', ostQuery.ostWaitingTime || 0);
      if (ostQuery?.includeMuniRefundTransactions) {
        params = params.append('includeMuniRefundTransactions', ostQuery.includeMuniRefundTransactions);
      }
    }

    return this.httpClient.put<void>(
      `${environment.apiUrl}/new-payroll/payroll-runs/${request.payrollRunId}/submit-to-bank`,
      request,
      {params}
    );
  }

  getPayrollRunError(payrollRunId: string): Observable<GetPayrollErrorMessageResponse> {
    return this.httpClient.get<GetPayrollErrorMessageResponse>(
      `${environment.apiUrl}/new-payroll/payroll-runs/${payrollRunId}/payroll-error-message`,
    );
  }

  setDimissHoldWarning(payrollRunId: string) {
    return this.httpClient.put<void>(
      `${environment.apiUrl}/new-payroll/payroll-runs/${payrollRunId}/dimiss-hold-warning`,
      { payrollRunId },
    );
  }

  getPaymentLevelErrorLists(request: GetPaymentLevelErrorListsRequest): Observable<GetPaymentLevelErrorListsResponse> {
    return this.httpClient.get<GetPaymentLevelErrorListsResponse>(
      `${environment.apiUrl}/new-payroll/payroll-runs/${request.payrollRunId}/transactions/${request.transactionId}/error-list`,
    );
  }

  getTotalPayeesPayments(request: GetTotalPayeesPaymentsRequest): Observable<GetTotalPayeesPaymentsResponse> {
    let params = new HttpParams();

    params = params.append('PayrollState', request.currentStep);

    return this.httpClient.get<GetTotalPayeesPaymentsResponse>(
      `${environment.apiUrl}/new-payroll/payroll-runs/${request.payrollRunId}/total-payees-payments`,
      { params },
    );
  }

  validateCheckNumber(request: EditCheckNumberRequest, isViewOndeDuctionPayees?: boolean) {
    let params = new HttpParams();
    params = params.append('CheckNumber', request.checkNumber);
    params = params.append('SubsequentUpdate', request.subsequentUpdate);

    if (isViewOndeDuctionPayees) {
      return this.httpClient.get(
        `${environment.apiUrl}/new-payroll/deduction-payees-runs/${request.payrollRunId}/payments/${request.transactionId}/check-number-validation`,
        { params },
      );
    }

    return this.httpClient.get(
      `${environment.apiUrl}/new-payroll/payroll-runs/${request.payrollRunId}/${request.transactionId}/check-number/valid`,
      { params },
    );
  }

  editCheckNumber(request: EditCheckNumberRequest): Observable<void> {
    let params = new HttpParams();
    params = params.append('CheckNumber', request.checkNumber);
    params = params.append('SubsequentUpdate', request.subsequentUpdate);
    return this.httpClient.put<void>(
      `${environment.apiUrl}/new-payroll/payroll-runs/${request.payrollRunId}/${request.transactionId}/check-number`,
      {},
      { params },
    );
  }

  getTotalDeductions(request: GetTotalDeductionsRequest): Observable<GetTotalDeductionResponse> {
    let params = new HttpParams();
    params = params.append('payrollState', request.payrollStep);
    return this.httpClient.get<GetTotalDeductionResponse>(
      `${environment.apiUrl}/new-payroll/payroll-runs/${request.payrollRunId}/total-deduction-detail`,
      { params },
    );
  }

  resetPayroll(payrollRunId: string): Observable<void> {
    return this.httpClient.put<void>(
      `${environment.apiUrl}/new-payroll/payroll-runs/${payrollRunId}/reset-payroll`,
      {},
    );
  }

  cancelPayroll(payrollRunId: string) {
    return this.httpClient.put(`${environment.apiUrl}/new-payroll/payroll-runs/${payrollRunId}/cancel`, {
      payrollRunId,
    });
  }

  reissuePaymentRun(payrollRunId: string) {
    return this.httpClient.put<void>(`${environment.apiUrl}/new-payroll/payroll-runs/${payrollRunId}/reissue-payment`, {
      payrollRunId,
    });
  }

  getNextYearTax(payrollRunId: string): Observable<MissingFillingForms> {
    return this.httpClient.get<MissingFillingForms>(
      `${environment.apiUrl}/new-payroll/payroll-runs/${payrollRunId}/check-missing-tax-forms`,
    );
  }

  getSearchPayees(request: GetSearchPayeesRequest): Observable<GetSearchPayeesResponse> {
    let params = new HttpParams();
    if (request?.sortNames?.length) {
      params = params.append('SortNames', request.sortNames);
      params = params.append('SortType', request?.sortType ?? SortType.ASC);
    }
    if (request?.pageSize && request?.pageNumber) {
      params = params.append('PageSize', request.pageSize);
      params = params.append('PageIndex', request.pageNumber);
    }

    return this.httpClient.get<GetSearchPayeesResponse>(
      `${environment.apiUrl}/new-payroll/payroll-runs/${request.payrollRunId}/search-payee?SearchContent=${request.searchContent}&Step=${request.step}`,
      { params },
    );
  }

  getACHFile(request: GetACHFileRequest): Observable<GetACHFileResponse> {
    return this.httpClient.get<GetACHFileResponse>(
      `${environment.apiUrl}/new-payroll/payroll-runs/${request?.payrollRunId}/ach-file`,
    );
  }

  clearAuditTrail(request: ClearAuditTrailRequest) {
    return this.httpClient.put(
      `${environment.apiUrl}/new-payroll/payroll-runs/${request.payrollRunId}/clear-audit-trail`,
      request.payrollRunState,
    );
  }

  editStartCheckNumberReview(request: EditStartCheckNumberReviewRequest): Observable<void> {
    let params = new HttpParams();
    params = params.append('StartingCheckNumber', request.startingCheckNumber);
    return this.httpClient.put<void>(
      `${environment.apiUrl}/new-payroll/payroll-runs/${request.payrollRunId}/starting-check-number`,
      {},
      { params },
    );
  }

  validateACHSendAndCheckPrinted(request: {payrollRunId: string, checkedType: number}) {
    return this.httpClient.get<{fileNotGenerated: boolean, datePassed: boolean}>(
      `${environment.apiUrl}/new-payroll/payroll-runs/${request.payrollRunId}/ach-sent-checks-printed/${request.checkedType}/validity`,
    );
  }
  
  validateUpdateTransaction(payrollRunId: string): Observable<any> {
    return this.httpClient.get(
      `${environment.apiUrl}/new-payroll/payroll-runs/${payrollRunId}/update-transaction/validity`,
    );
  }
}
