<div class="list-display">
  <div class="list-header" [ngClass]="{'spe-doc': isDocument}">
    <span class="title-name">{{ title }}</span>
    <div class="button-container" *ngIf="btnAddTitle">
      <div class="separator"></div>
      <div class="button-group">
        <ptg-button
          [buttonName]="btnAddTitle"
          classInput="edit-button"
          (clickButton)="onClickAddItem()"
        >
          <mat-icon>add</mat-icon>
        </ptg-button>
      </div>
    </div>
    <div class="button-container button-container-right" *ngIf="btnGenerate || btnAddDisabilityPayment">
      <div class="button-group">
        <ptg-button
          *ngIf="btnAddDisabilityPayment"
          [buttonName]="btnAddDisabilityPayment"
          classInput="generate-button"
          (clickButton)="onClickAddDisabilityPayment()">
          <mat-icon> add </mat-icon>
        </ptg-button>
        <ptg-button
          *ngIf="btnGenerate"
          [buttonName]="btnGenerate"
          classInput="generate-button"
          (clickButton)="onClickGenerateRecord()">
          <mat-icon
            svgIcon="generate-icon-outline"
            aria-hidden="false"
            aria-label="generate-icon-outline"
          ></mat-icon>
        </ptg-button>
      </div>
    </div>
    <div *ngIf="isDocument" style="margin-right: 10px;">
      <button
          mat-icon-button
          [matMenuTriggerFor]="menu"
          aria-label="Icon-button with a menu"
          class="group-button ignore-row"
        >
          <mat-icon class="ignore-row">more_horiz</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
          <button mat-menu-item (click)="onManageListDocument($event)">
            <mat-icon class="member-section-action-icon"> list_alt </mat-icon>
            <span class="label-icon">Manage List</span>
          </button>
          <button mat-menu-item (click)="onUploadDocument($event)">
            <mat-icon class="member-section-action-icon">upload</mat-icon>
            <span class="label-icon">Upload Document</span>
          </button>
        </mat-menu>
    </div>
    <div class="output" [class.list-address]="isAddress">
      <ng-content></ng-content>
    </div>
  </div>

  <div class="grid-wrapper">
    <ptg-grid
      [data]="dataTable"
      [columns]="columns"
      [isLoading]="isLoading"
      [totalRecords]="lengthPg"
      [pageSize]="pageSize"
      [pageNumber]="pageNumber"
      [paginable]="paginable"
      (pageChange)="onChangePage($event)"
      (sortChange)="onSortChange($event)"
      (rowClick)="onSelectRow($event)"
      [(currentRowIndex)]="currentRowIndex"
      [fitToParent]="true"
      [notFoundMessage]="isHideNotFoundMessage ? '' : 'No ' + pageName + ' to Display'"
    >
      <ng-container *ngIf="isDocument || isGenerateReport">
        <ng-template cellContent columnName="fileName" let-row>
          <div class="flex file-document-container">
            <span (click)="downloadDocument(row)" class="file-document-name" target="_blank">
              <span class="truncate">{{ row?.fileName }}</span>
            </span>
          </div>
        </ng-template>
      </ng-container>
      <ng-container *ngIf="isAddress">
        <ng-template cellContent columnName="entityPropertyName" let-row>
          <div class="icon-status-container">
            <mat-icon class="icon-address" [ngStyle]="{ color: row?.isActive ? '#408BF9' : '#E02E2E' }">{{ row?.isActive ? 'check_circle' : 'do_not_disturb_on' }}</mat-icon>
            <div class="status-name">{{ row.entityPropertyName }}</div>
          </div>
        </ng-template>
      </ng-container>
      <ng-container *ngIf="isAddressHistory">
        <ng-template cellContent columnName="address" let-row>
          {{!row.addressBlank ? '-' : (row.address | address)}}
        </ng-template>
        <ng-template cellContent columnName="effectTo" let-row>
          <div>
            {{!row.effectTo ? '-' : (row.effectTo | date : 'MM/dd/yyyy')}}
          </div>
        </ng-template>
      </ng-container>
      <ng-template cellContent columnName="statusName" let-row>
        <div class="icon-status-container">
          <mat-icon
            *ngIf="row?._typedValue?.iconName"
            class="icon-status"
            [ngStyle]="{ color: row?._typedValue?.color }"
            >{{ row?._typedValue?.iconName }}</mat-icon
          >
          <div class="status-name">{{ row.statusName }}</div>
        </div>
      </ng-template>
      <ng-container *ngIf="isDocument">
        <ng-template cellContent columnName="action" let-row>
          <ptg-button buttonName="Remove" classInput="edit-button" (clickButton)="onClickRemove(row)">
              <mat-icon>delete_forever</mat-icon>
          </ptg-button>
        </ng-template>
      </ng-container>
      <ng-container *ngIf="isAccountDeposit">
        <ng-template cellContent columnName="isUse" let-row>
          <div class="icon-status-container">
            <mat-icon *ngIf="row.isUse" class="icon-inuse">check_circle</mat-icon>
          </div>
        </ng-template>
        <ng-template cellContent columnName="isActive" let-row>
          <div class="icon-status-container">
            <mat-icon *ngIf="row.isActive" class="icon-active">check_circle</mat-icon>
          </div>
        </ng-template>
      </ng-container>
    </ptg-grid>
  </div>
</div>
