import { createAction, props } from '@ngrx/store';
import {
  CreateReissueTransactionsRequest,
  CreateRejectedTransactionsRequest,
  GetPayrollBenefitTypeResponse,
  GetVoidedReasonDetailsRequest,
  GetVoidedReasonDetailsResponse,
  GetVoidedReasonsRequest,
  GetVoidedReasonsResponse,
  MarkReturnedQuery,
  QueryExportRegister,
  RegisterListQuery,
  SummaryList
} from '../../services/models/register.model';
import { generateActionName } from '@ptg-shared/utils/common.util';

export const clearRegisterAction = createAction('[RegisterClear]');

export const getSummaryListAction = createAction(
  '[GetSummaryList/API] Send Request',
  props<{ query: RegisterListQuery }>()
);
export const getSummaryListSuccessAction = createAction(
  '[GetSummaryList/API] Success',
  props<{ summaryList: SummaryList }>()
);
export const getSummaryListFailureAction = createAction(
  '[GetSummaryList/API] Failure',
  props<{ error?: any }>()
);

export const createRejectedTransactionsAction = createAction(
  '[CreateRejectedTransactions/API] Send Request',
  props<{ request: CreateRejectedTransactionsRequest }>()
);

export const createRejectedTransactionsSuccessAction = createAction(
  '[CreateRejectedTransactions/API] Success'
);

export const createRejectedTransactionsFailureAction = createAction(
  '[CreateRejectedTransactions/API] Failure',
  props<{ error?: any }>()
);

export const clearCreateRejectedTransactionsStateAction = createAction(
  '[CreateRejectedTransactions] Clear'
);

export const getVoidedReasonsAction = createAction(
  '[GetVoidedReasons/API] Send Request',
  props<{ request: GetVoidedReasonsRequest, query: MarkReturnedQuery }>()
);

export const getVoidedReasonsSuccessAction = createAction(
  '[GetVoidedReasons/API] Success',
  props<{ response: GetVoidedReasonsResponse }>()
);

export const getVoidedReasonsFailureAction = createAction(
  '[GetVoidedReasons/API] Failure',
  props<{ error?: any }>()
);

export const clearGetVoidedReasonsStateAction = createAction(
  '[GetVoidedReasons] Clear'
);

export const getVoidedReasonDetailsAction = createAction(
  '[GetVoidedReasonDetails/API] Send Request',
  props<{ request: GetVoidedReasonDetailsRequest }>()
);

export const getVoidedReasonDetailsSuccessAction = createAction(
  '[GetVoidedReasonDetails/API] Success',
  props<{ response: GetVoidedReasonDetailsResponse }>()
);

export const getVoidedReasonDetailsFailureAction = createAction(
  '[GetVoidedReasonDetails/API] Failure',
  props<{ error?: any }>()
);

export const clearGetVoidedReasonDetailsStateAction = createAction(
  '[GetVoidedReasonDetails] Clear'
);

export const createReissueTransactionsAction = createAction(
  '[CreateReissueTransactions/API] Send Request',
  props<{ request: CreateReissueTransactionsRequest }>()
);

export const createReissueTransactionsSuccessAction = createAction(
  '[CreateReissueTransactions/API] Success',
  props<{ id: string }>()
);

export const createReissueTransactionsFailureAction = createAction(
  '[CreateReissueTransactions/API] Failure',
  props<{ error?: any }>()
);

export const clearCreateReissueTransactionsStateAction = createAction(
  '[CreateReissueTransactions] Clear'
);

export const getPayrollBenefitTypesAction = createAction(
  '[getPayrollBenefitTypes/API] Send Request',
);

export const getPayrollBenefitTypesSuccessAction = createAction(
  '[getPayrollBenefitTypes/API] Success',
  props<{ response: GetPayrollBenefitTypeResponse }>()
);

export const getPayrollBenefitTypesFailureAction = createAction(
  '[getPayrollBenefitTypes/API] Failure',
  props<{ error?: any }>()
);

export const exportTransactionRegister = createAction(
  generateActionName('ExportTransactionRegister/API').Send,
  props<{ request: QueryExportRegister }>()
);
export const exportTransactionRegisterSuccess = createAction(
  generateActionName('ExportTransactionRegister/API').Success,
  props<{ data: any }>()
);
export const exportTransactionRegisterFailure = createAction(
  generateActionName('ExportTransactionRegister/API').Failure
);
export const downloadTransactionRegister = createAction(
  generateActionName('DownloadTransactionRegister/API').Send,
  props<{ fileName: string }>()
);
export const downloadTransactionRegisterSuccess = createAction(
  generateActionName('DownloadTransactionRegister/API').Success
);
export const downloadTransactionRegisterFailure = createAction(
  generateActionName('DownloadTransactionRegister/API').Failure
);
