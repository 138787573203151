<div class="generate-document-container">
  <ptg-breadcrumb [listBreadcrumbs]="listBreadcrumbs" (clickBreadcrumb)="onClickBreadcrumb()"></ptg-breadcrumb>
  <ng-container *ngIf="entityType === EntityType.Participant">
    <ptg-overview-header-entity
      [isShowChangeMemberDetailBtn]="false"
      [targetIdFromDialog]="targetId"
      [isSubHeader]="true"
    >
    </ptg-overview-header-entity>
  </ng-container>
  <div class="p-5">
    <ptg-banner
      *ngIf="bannerType"
      [(bannerType)]="bannerType"
      [message]="message"
      [isLoadingInfo]="isLoadingInfoBanner"
      [isHideCloseButton]="isHideCloseButton"
    ></ptg-banner>
  </div>

  <form [formGroup]="generateDocForm" (ngSubmit)="formSubmit$.next()">
    <div class="form-container">
      <div class="wrap-btn">
        <button mat-raised-button type="submit" class="submit-button">Save</button>
        <button mat-raised-button type="button" class="cancel-button" (click)="onCancel()">Cancel</button>
      </div>
      <div class="input-container">
        <ptg-select
          [placeholder]="'Template'"
          [controlField]="generateDocForm.get('template')"
          [listData]="listTemplate"
          [isOptionObj]="true"
          [isRequired]="true"
          [isSetMaxWidthSelect]="true"
          [isMultipleLineOption]="true"
          [onlyTruncateTitle]="true"
          class="select-template"
          customError="errMsgTemplate"
          (changeOptionValue)="onSelectTemplate($event)"
        ></ptg-select>
        <ptg-select
          [placeholder]="'File Extension'"
          [controlField]="generateDocForm.get('fileType')"
          [listData]="listType"
          [isOptionObj]="true"
          [isRequired]="true"
          [isSetMaxWidthSelect]="true"
          [isMultipleLineOption]="true"
          [onlyTruncateTitle]="true"
          class="select-type"
        ></ptg-select>
      </div>
      <div class="pb-3">
        <ptg-toggle-button
          [controlField]="generateDocForm.get('isShowPreview')"
          label="Show Preview"
        ></ptg-toggle-button>
      </div>
      <div class="flex-1 autocomplete-chip-section">
        <div class="auto-complete-section">
          <div class="select-container">
            <div class="select-tag">
              <mat-form-field
                class="auto-complete"
                appearance="fill"
                [ngClass]="{ 'disabled-tag': currentTagsChipValueList.length == listOptionTag.length }"
              >
                <mat-label>Tags</mat-label>
                <input
                  class="custom-input"
                  type="text"
                  matInput
                  [formControl]="tagsAutoCompleteControl"
                  [matAutocomplete]="auto"
                  (blur)="validateTags()"
                  (focus)="tagsFocus($event)"
                />
                <span class="custom-arrow mat-select-arrow-wrapper"><span class="mat-select-arrow"></span></span>
                <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayTagFn">
                  <mat-option *ngFor="let option of filteredByTypingTagOptions | async" [value]="option">
                    {{ option.displayValue }}
                  </mat-option>
                </mat-autocomplete>
                <mat-error
                  *ngIf="!tagsAutoCompleteControl?.errors?.required && tagsAutoCompleteControl?.errors?.inValidAsync"
                >
                  Value must be selected from result list.
                </mat-error>
              </mat-form-field>
              <p
                class="add-new-hyperlink"
                [ngClass]="{
                  'pt-3': !tagsAutoCompleteControl?.errors?.required && tagsAutoCompleteControl?.errors?.inValidAsync
                }"
              >
                <span (click)="addNewTags()">Add New Tag</span>
              </p>
            </div>
            <div class="chip-container">
              <span class="add-chip-btn-section">
                <button
                  mat-icon-button
                  (click)="onAddNewChip()"
                  [disabled]="
                    !tagsAutoCompleteControl.value ||
                    tagsAutoCompleteControl?.errors?.inValidAsync ||
                    currentTagsChipValueList.length == listOptionTag.length
                  "
                >
                  <mat-icon>add_circle</mat-icon>
                </button>
              </span>
              <div class="tags-chip-list-section">
                <mat-chip-list *ngIf="tagsChipListControl?.length">
                  <mat-chip
                    *ngFor="let item of tagsChipListControl.controls; index as i"
                    (removed)="onRemoveChip(item.value, i)"
                  >
                    <span class="chip-wrapper">
                      <span class="chip__txt--primary">
                        <span class="mr-1">{{ displayValue(item.value.displayValue) }}</span>
                      </span>
                    </span>
                    <button matChipRemove>
                      <mat-icon>close</mat-icon>
                    </button>
                  </mat-chip>
                </mat-chip-list>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
