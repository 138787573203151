import { HttpClient, HttpContext, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SHOW_LOADING } from '@ptg-interceptor/httpconfig.interceptor';
import { SortType } from '@ptg-shared/constance';
import { Observable } from 'rxjs';

import { environment } from 'src/environments/environment';
import { CalculationType } from '../types/enums';
import { CreateRetirementBenefitRequest } from './models/retirement-benefit-dialog.model';

import {
  GetRetirementBenefitDocumentsResponse,
  GetRetirementBenefitDownloadDocumentRequest,
  GetRetirementBenefitHistoriesResponse,
  GetRetirementBenefitRequest,
  GetValidateBeforeRemovingDisabilityBenefitsRequest,
  GetValidateBeforeRemovingDisabilityBenefitsResponse,
  RemoveDisabilityBenefitHistoryRequest,
} from './models/retirement-benefit-history.model';


@Injectable()
export class RetirementBenefitService {
  constructor(private httpClient: HttpClient) {}
  getRetirementBenefitHistories(
    request: GetRetirementBenefitRequest,
    memberId: string,
    calculationType: CalculationType,
  ): Observable<GetRetirementBenefitHistoriesResponse> {
    let params = new HttpParams();

    if (request?.sortNames?.length) {
      params = params.append('SortNames', request.sortNames);
      params = params.append('SortType', request?.sortType ?? SortType.ASC);
    }
    if (request?.pageSize && request?.pageNumber) {
      params = params.append('PageSize', request.pageSize);
      params = params.append('PageIndex', request.pageNumber);
    }

    return this.httpClient.get<GetRetirementBenefitHistoriesResponse>(
      `${environment.apiUrl}/calculations/${memberId}/benefit-processing/calculation-benefit-histories/${calculationType}`,
      { params },
    );
  }

  removeCalculationBenefit(id: string, memberId: string) {
    const context = new HttpContext().set(SHOW_LOADING, true);
    return this.httpClient.delete(
      `${environment.apiUrl}/calculations/${memberId}/benefit-processing/calculation-benefit/${id}`,
      { context },
    );
  }

  getRetirementBenefitDocuments(
    request: GetRetirementBenefitRequest,
    memberId: string,
    calculationType: CalculationType,
    calculationBenefitId?: string,
  ): Observable<GetRetirementBenefitDocumentsResponse> {
    let body: any = {};
    if (request?.sortNames?.length) {
      body = {
        ...body,
        sortNames: [request?.sortNames],
        sortType: request.sortType || SortType.ASC,
      };
    }
    if (request?.pageSize && request?.pageNumber) {
      body = {
        ...body,
        PageSize: request.pageSize,
        PageIndex: request.pageNumber,
      };
    }
    if (calculationBenefitId) {
      body = {
        ...body,
        CalculationBenefitId: calculationBenefitId,
      };
    }
    let params = new HttpParams({ fromObject: body as any });
    return this.httpClient.get<GetRetirementBenefitDocumentsResponse>(
      `${environment.apiUrl}/calculations/${memberId}/benefit-processing/calculation-benefit-documents/${calculationType}`,
      { params },
    );
  }

  getRetirementBenefitDownloadDocument(request: GetRetirementBenefitDownloadDocumentRequest) {
    const { memberId, calculationType, calculationDocumentId } = request;
    return this.httpClient.get(
      `${environment.apiUrl}/calculations/${memberId}/benefit-processing/calculation-benefit-documents/${calculationType}/${calculationDocumentId}`,
      { responseType: 'blob' },
    );
  }

  initiateSurvivor(body: CreateRetirementBenefitRequest, id: string): Observable<string> {
    const formData = new FormData();
    formData.append('benefitEntityId', body.benefitEntityId);
    formData.append('calculationType', body.calculationType.toString());
    body?.propertyValueFromPriorSteps?.forEach((item, index) => {
      formData.append(`propertyValueFromPriorSteps[${index}].calculationStepId`, item.calculationStepId || '');
      formData.append(
        `propertyValueFromPriorSteps[${index}].calculationParamMappingId`,
        item.calculationParamMappingId || '',
      );
      formData.append(`propertyValueFromPriorSteps[${index}].type`, item.type?.toString() || '');
      formData.append(`propertyValueFromPriorSteps[${index}].id`, item.id || '');
      formData.append(`propertyValueFromPriorSteps[${index}].order`, item.order?.toString() ?? '0');
      formData.append(`propertyValueFromPriorSteps[${index}].orderColumn`, item.orderColumn?.toString() ?? '0');
      formData.append(`propertyValueFromPriorSteps[${index}].value`, item.value || '');
      Object.entries(item.inputConfigs).forEach(([key, value]) => {
        formData.append(`propertyValueFromPriorSteps[${index}].configs.${key}`, value);
      });
    });
    const context = new HttpContext().set(SHOW_LOADING, true);
    return this.httpClient.post<string>(
      `${environment.apiUrl}/calculations/${id}/benefit-processing/calculation-benefit-histories/initiate`,
      formData,
      { context },
    );
  }

  getValidateBeforeRemovingDisabilityBenefits(
    request: GetValidateBeforeRemovingDisabilityBenefitsRequest,
  ): Observable<GetValidateBeforeRemovingDisabilityBenefitsResponse> {
    const context = new HttpContext().set(SHOW_LOADING, true);
    return this.httpClient.get<GetValidateBeforeRemovingDisabilityBenefitsResponse>(
      `${environment.apiUrl}/Calculations/disability-benefit-history/${request.id}/before-removing/validation`,
      { context },
    );
  }

  removeDisabilityBenefitHistories(request: RemoveDisabilityBenefitHistoryRequest): Observable<void> {
    const { memberId, calculationBenefitId } = request;
    const context = new HttpContext().set(SHOW_LOADING, true);
    return this.httpClient.delete<void>(
      `${environment.apiUrl}/Calculations/disability-benefit-history/${calculationBenefitId}/${memberId}`,
      { context },
    );
  }

  validateMemberRetired(memberId: string) {
    return  this.httpClient.get(
      `${environment.apiUrl}/Calculations/benefit-processing/calculation-benefit/${memberId}/validate-member-retired`,
    );
  }
}
