<mat-form-field
  *ngIf="controlField"
  appearance="fill"
  class="select-custom {{ class }}"
  [ngClass]="{ 'has-icon': selected?.iconConfig }"
  [style.width]="width"
>
  <mat-label>{{ placeholder }}</mat-label>
  <mat-select
    #select
    [formControl]="controlField"
    [compareWith]="compareWithFunction"
    [panelClass]="[
      isSetMaxWidthSelect ? 'max-length-option' : 'common-option',
      panelClass
    ]"
    id="mat-select-{{ placeholder }}"
    (focus)="onFocusValue()"
    (focusout)="onFocusOutValue()"
    (click)="onClickDdl()"
    [disabled]="isDisabledSelect!!"
    [multiple]="isMultiple"
  >
    <mat-select-trigger *ngIf="isOptionObj" id="dropdown-{{ placeholder }}">
      <ng-container
        *ngIf="!isMultiple"
        [ngTemplateOutlet]="optionContainer"
        [ngTemplateOutletContext]="{
          option: selected,
          isShowDescription: false,
          isShowSubInfo: true
        }"
      ></ng-container>
      
      <!-- for multiple choice only -->
      <ng-container *ngIf="isMultiple">
        <span>{{ displayMultipleSelected.join(', ') }}</span>
      </ng-container>
    </mat-select-trigger>
    <ng-container *ngIf="!isOptionObj; else optionObj">
      <mat-option *ngIf="hasNoneValue" value="" class="blank">{{
        noneValue
      }}</mat-option>
      <mat-option
        *ngFor="let option of listData"
        [value]="option"
        (click)="isCheckChange ? changeOption(option) : {}"
      >
        {{ option }}
      </mat-option>
    </ng-container>
    <ng-template #optionObj>
      <mat-option
        *ngIf="hasNoneValue"
        value=""
        class="blank"
        (click)="changeOption()"
        >{{ noneValue }}</mat-option
      >
      <!-- for multiple choice only -->
      <div class="select-all" *ngIf="isMultiple">
        <mat-checkbox 
          [(ngModel)]="isAllSelected"
          [ngModelOptions]="{standalone: true}"
          (change)="selectAllOptions()"
          [indeterminate]="select?.value?.length > 0 && !isAllSelected">
          (Select All)
        </mat-checkbox>
      </div>
      <mat-option
        *ngFor="let option of listData"
        [value]="option.value"
        [disabled]="option?.disabled"
        (click)="changeOption(option)"
        [ngClass]="{
          'select-option-container': option?.iconConfig,
          'multiple-select-option-container': isMultipleLineOption
        }"
        [ngStyle]="{ display: option.isHide ? 'none' : 'block' }"
      >
        <ng-container
          *ngIf="!isMultiple"
          [ngTemplateOutlet]="optionContainer"
          [ngTemplateOutletContext]="{
            option: option,
            isShowDescription: true,
            isShowSubInfo: false
          }"
        >
        </ng-container>

        <ng-container
          *ngIf="isMultiple"
          [ngTemplateOutlet]="multipleOptionContainer"
          [ngTemplateOutletContext]="{
            option: option,
            isShowDescription: true
          }"
        >
        </ng-container>
      </mat-option>
    </ng-template>

    <!-- this template was built for multiple choice dropdown -->
    <ng-template
      let-option="option"
      let-isShowDescription="isShowDescription"
      #multipleOptionContainer
    >
      <!-- {{ ((!isShowDescription && isShowValue) ? option?.valueShow : option?.displayValue) || noneValue }} -->
      <ng-container
        [ngTemplateOutlet]="optionContent"
        [ngTemplateOutletContext]="{ option, isShowDescription }"
      >
      </ng-container>
    </ng-template>

    <!-- this template was built for single choice dropdown -->
    <ng-template
      let-option="option"
      let-isShowDescription="isShowDescription"
      let-isShowSubInfo="isShowSubInfo"
      #optionContainer
    >
      <ng-container
        *ngIf="option?.iconConfig?.icon && option?.iconConfig?.iconFirst"
        [ngTemplateOutlet]="optionIcon"
        [ngTemplateOutletContext]="{ config: option?.iconConfig }"
      >
      </ng-container>
      <ng-container
        [ngTemplateOutlet]="optionContent"
        [ngTemplateOutletContext]="{ option, isShowDescription, isShowSubInfo }"
      >
      </ng-container>
      <ng-container
        *ngIf="option?.iconConfig?.icon && !option?.iconConfig?.iconFirst"
        [ngTemplateOutlet]="optionIcon"
        [ngTemplateOutletContext]="{ config: option?.iconConfig }"
      >
      </ng-container>
    </ng-template>

    <ng-template let-config="config" #optionIcon>
      <ng-container *ngIf="config">
        <mat-icon
          *ngIf="!config.isSvg; else svgIcon"
          [style.color]="config.color"
          >{{ config.icon }}</mat-icon
        >
        <ng-template #svgIcon>
          <mat-icon
            *ngIf="config.icon && config.iconFirst"
            [svgIcon]="config.icon"
            [style.color]="config.color"
          >
          </mat-icon>
        </ng-template>
      </ng-container>
    </ng-template>

    <ng-template
      let-option="option"
      let-isShowDescription="isShowDescription"
      let-isShowSubInfo="isShowSubInfo"
      #optionContent
    >
      <div class="multiple-line-option-container">
        <div
          class="title"
          [ngClass]="{'title-no-bold': onlyTruncateTitle}"
          [ngStyle]="{ color: !option?.displayValue ? '#828282' : '#303030' }"
        >
          <ng-container *ngIf="isOneTimeType; else commonType">
            <span [style]="option?.displayValueStyle">{{ ((!isShowDescription && isShowValue) ? option?.valueShow : option?.displayValue) || noneValue }}</span>
            <span *ngIf="option?.hasSeparator && isShowSubInfo"> - </span>
            <span
              *ngIf="option?.additionalDataConfig && isShowSubInfo"
              [style]="option?.additionalDataConfig?.style"
            >
              {{ option?.additionalDataConfig?.value }}
            </span>
          </ng-container>
          <ng-template #commonType>
            <span *ngIf="!isMasked" [style]="option?.displayValueStyle">{{
              (!isShowDescription && isShowValue ? option?.valueShow : option?.displayValue) || noneValue
            }}</span>
            <span *ngIf="isMasked">
              <span [style]="option?.displayValueStyle">{{ option?.maskedAccountNumber || '' }}</span>{{
                option?.accountNumberNotMasked && option?.accountNumberNotMasked.length > 0
                  ? option?.accountNumberNotMasked
                  : ''
              }}{{
                option?.displayValue && option?.displayValue.length > 0
                  ? option?.displayValue
                  : ''
              }}
            </span>
            <span *ngIf="option?.hasSeparator"> - </span>
            <span
              *ngIf="option?.additionalDataConfig"
              [style]="option?.additionalDataConfig?.style"
            >
              {{ option?.additionalDataConfig?.value }}
            </span>
          </ng-template>
        </div>
        <div
          class="description"
          *ngIf="isMultipleLineOption && isShowDescription && option?.valueDescription"
        >
          {{ option.valueDescription }}
        </div>
      </div>
    </ng-template>
  </mat-select>
  <mat-hint *ngIf="isShowHintText && !controlField?.errors && select.focused" class="helpText">{{hintText}}</mat-hint>
  <mat-error
    *ngIf="controlField?.errors?.required"
    id="mat-error-{{ placeholder }}"
    >{{ errorRequire }}</mat-error
  >
  <mat-error
    *ngIf="controlField?.errors?.duplicated"
    id="mat-error-{{ placeholder }}"
    >{{ errorDuplicated }}</mat-error
  >
  <mat-error *ngIf="controlField?.errors?.inValidAsync">{{
    errorAsync ? errorAsync : controlField.getError("inValidAsync")
  }}</mat-error>
  <mat-error
    *ngIf="controlField?.errors?.[customError] && !controlField?.errors?.required"
    >{{ controlField.getError([customError]) }}</mat-error
  >
</mat-form-field>
