import { createAction, props } from '@ngrx/store';

import {
  BankAccountDetailsReponse,
  EditCheckNumberRequest,
  GetPaymentLevelErrorListsRequest,
  GetPaymentLevelErrorListsResponse,
  GetPayrollRunListPaymentRequest,
  GetPayrollRunListPaymentResponse,
  GetPayrollRunSummaryRequest,
  PayrollRunSummary,
  SubmitToBankRequest,
  UpdateTransactionsRequest,
  CancelPayrollRequest,
  CancelPayrollResponse,
  MissingFillingForms,
  GetSearchPayeesRequest,
  GetSearchPayeesResponse,
  GetACHFileRequest,
  GetACHFileResponse,
  ClearAuditTrailRequest,
  SubmitToBankRequestQuery,
  EditStartCheckNumberReviewRequest,
  EditStartCheckNumberDeductionRequest,
} from '../../services/models';

export const getPayrollRunSummaryAction = createAction(
  '[GetPayrollRunSummary/API] Send Request',
  props<{ request: GetPayrollRunSummaryRequest; shouldLoad?: boolean }>(),
);

export const getPayrollRunSummarySuccessAction = createAction(
  '[GetPayrollRunSummary/API] Success',
  props<{ response: PayrollRunSummary }>(),
);

export const getPayrollRunSummaryFailureAction = createAction(
  '[GetPayrollRunSummary/API] Failure',
  props<{ error?: any }>(),
);

export const clearGetPayrollRunSummaryStateAction = createAction('[GetPayrollRunSummary] Clear');

export const getBankAccountsDetailAction = createAction(
  '[GetBankAccountsDetail/API] Send Request',
  props<{ request: GetPayrollRunSummaryRequest }>(),
);

export const getBankAccountsDetailSuccessAction = createAction(
  '[GetBankAccountsDetail/API] Success',
  props<{ response: BankAccountDetailsReponse }>(),
);

export const getBankAccountsDetailFailureAction = createAction(
  '[GetBankAccountsDetail/API] Failure',
  props<{ error?: any }>(),
);

export const clearGetBankAccountsDetailStateAction = createAction('[GetBankAccountsDetail] Clear');

export const getPayrollRunListPaymentAction = createAction(
  '[GetPayrollRunListPayment/API] Send Request',
  props<{ query: GetPayrollRunListPaymentRequest }>(),
);
export const getPayrollRunListPaymentSuccessAction = createAction(
  '[GetPayrollRunListPayment/API] Success',
  props<{ response: GetPayrollRunListPaymentResponse }>(),
);
export const getPayrollRunListPaymentFailureAction = createAction(
  '[GetPayrollRunListPayment/API] Failure',
  props<{ error?: any }>(),
);
export const clearGetPayrollRunListPaymentStateAction = createAction('[GetPayrollRunListPayment/API] Clear');

export const setIssuePaymentAction = createAction(
  '[SetIssuePaymentAction/API] Send Request',
  props<{ payrollRunId: string }>(),
);
export const setIssuePaymentSuccessAction = createAction('[SetIssuePaymentAction/API] Success');
export const setIssuePaymentFailureAction = createAction(
  '[SetIssuePaymentAction/API] Failure',
  props<{ error?: any }>(),
);
export const setIssuePaymentClearAction = createAction('[SetIssuePaymentAction/API] Clear');

export const updateTransactionsAction = createAction(
  '[UpdateTransactions/API] Send Request',
  props<{ request: UpdateTransactionsRequest }>(),
);

export const updateTransactionsSuccessAction = createAction('[UpdateTransactions/API] Success');

export const updateTransactionsFailureAction = createAction(
  '[UpdateTransactions/API] Failure',
  props<{ error?: any }>(),
);

export const clearUpdateTransactionsStateAction = createAction('[UpdateTransactions] Clear');

export const submitToBankAction = createAction(
  '[SubmitToBank/API] Send Request',
  props<{ request: SubmitToBankRequest, ostQuery?: SubmitToBankRequestQuery }>(),
);

export const submitToBankSuccessAction = createAction('[SubmitToBank/API] Success');

export const submitToBankFailureAction = createAction('[SubmitToBank/API] Failure', props<{ error?: any }>());

export const clearSubmitToBankStateAction = createAction('[SubmitToBank] Clear');
export const getPaymentLevelErrorListsAction = createAction(
  '[GetPaymentLevelErrorLists/API] Send Request',
  props<{ request: GetPaymentLevelErrorListsRequest }>(),
);

export const getPaymentLevelErrorListsSuccessAction = createAction(
  '[GetPaymentLevelErrorLists/API] Success',
  props<{ response: GetPaymentLevelErrorListsResponse }>(),
);

export const getPaymentLevelErrorListsFailureAction = createAction(
  '[GetPaymentLevelErrorLists/API] Failure',
  props<{ error?: any }>(),
);

export const clearGetPaymentLevelErrorListsStateAction = createAction('[GetPaymentLevelErrorLists] Clear');

export const editCheckNumberAction = createAction(
  '[EditCheckNumber/API] Send Request',
  props<{ request: EditCheckNumberRequest }>(),
);

export const editCheckNumberSuccessAction = createAction('[EditCheckNumber/API] Success');

export const editCheckNumberFailureAction = createAction('[EditCheckNumber/API] Failure', props<{ error?: any }>());

export const clearEditCheckNumberStateAction = createAction('[EditCheckNumber] Clear');

export const resetPayrollAction = createAction('[ResetPayroll/API] Send Request', props<{ payrollRunId: string }>());

export const resetPayrollSuccessAction = createAction('[ResetPayroll/API] Success');

export const resetPayrollFailureAction = createAction('[ResetPayroll/API] Failure', props<{ error?: any }>());

export const clearResetPayrollStateAction = createAction('[ResetPayroll/API] Clear');
export const cancelPayrollAction = createAction(
  '[CancelPayroll/API] Send Request',
  props<{ request: CancelPayrollRequest }>(),
);

export const cancelPayrollSuccessAction = createAction(
  '[CancelPayroll/API] Success',
  props<{ response: CancelPayrollResponse }>(),
);

export const cancelPayrollFailureAction = createAction('[CancelPayroll/API] Failure', props<{ error?: any }>());

export const clearCancelPayrollStateAction = createAction('[CancelPayroll] Clear');

export const editCheckMemoAction = createAction(
  '[EditCheckMemo/API] Send Request',
  props<{ payrollRunId: string; payload: { checkMemo: string } }>(),
);

export const editCheckMemoSuccessAction = createAction('[EditCheckMemo/API] Success');

export const editCheckMemoFailureAction = createAction('[EditCheckMemo/API] Failure', props<{ error?: any }>());

export const clearEditCheckMemoStateAction = createAction('[EditCheckMemo] Clear');

export const getNextYearTaxAction = createAction(
  '[GetNextYearTax/API] Send Request',
  props<{ payrollRunId: string }>(),
);

export const getNextYearTaxSuccessAction = createAction(
  '[GetNextYearTax/API] Success',
  props<{ response: MissingFillingForms }>(),
);

export const getNextYearTaxFailureAction = createAction('[GetNextYearTax/API] Failure', props<{ error?: any }>());

export const getSearchPayeesAction = createAction(
  '[GetSearchPayees/API] Send Request',
  props<{ request: GetSearchPayeesRequest }>(),
);

export const getSearchPayeesSuccessAction = createAction(
  '[GetSearchPayees/API] Success',
  props<{ response: GetSearchPayeesResponse }>(),
);

export const getSearchPayeesFailureAction = createAction('[GetSearchPayees/API] Failure', props<{ error?: any }>());

export const clearGetSearchPayeesStateAction = createAction('[GetSearchPayees] Clear');

export const getACHFileAction = createAction('[GetACHFile/API] Send Request', props<{ request: GetACHFileRequest }>());

export const getACHFileSuccessAction = createAction(
  '[GetACHFile/API] Success',
  props<{ response: GetACHFileResponse }>(),
);

export const getACHFileFailureAction = createAction('[GetACHFile/API] Failure', props<{ error?: any }>());

export const clearGetACHFileStateAction = createAction('[GetACHFile] Clear');

export const clearAuditTrailStateAction = createAction('[ClearAuditTrail/API] Send Request', props<{request: ClearAuditTrailRequest}>());
export const clearAuditTrailSuccessAction = createAction('[ClearAuditTrail/API] Success');
export const clearAuditTrailFailureAction = createAction('[ClearAuditTrail/API] Failure', props<{error?: any}>());

//for edit start check number step review new payroll
export const editStartCheckNumberReviewAction = createAction(
  '[EditStartCheckNumberReview/API] Send Request',
  props<{ request: EditStartCheckNumberReviewRequest }>(),
);

export const editStartCheckNumberReviewSuccessAction = createAction('[EditStartCheckNumberReview/API] Success');

export const editStartCheckNumberReviewFailureAction = createAction('[EditStartCheckNumberReview/API] Failure', props<{ error?: any }>());

export const clearEditStartCheckNumberReviewStateAction = createAction('[EditStartCheckNumberReview] Clear');

//for deduction payee processing
export const editStartCheckNumberDeductionAction = createAction(
  '[EditStartCheckNumberDeduction/API] Send Request',
  props<{accountId: string, request: EditStartCheckNumberDeductionRequest }>(),
);

export const editStartCheckNumberDeductionSuccessAction = createAction('[EditStartCheckNumberDeduction/API] Success');

export const editStartCheckNumberDeductionFailureAction = createAction('[EditStartCheckNumberDeduction/API] Failure', props<{ error?: any }>());

export const clearEditStartCheckNumberDeductionStateAction = createAction('[EditStartCheckNumberDeduction] Clear');