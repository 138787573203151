import { DatePipe } from '@angular/common';
import { Component, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Params } from '@angular/router';
import { select, Store } from '@ngrx/store';
import {
  CancelPaymentInstructionRequest,
  GetRecalculateTaxesRequest,
  InstructionHistory,
  LastPayrollCycle,
  PaymentTab,
} from '@ptg-member/features/payee-detail/services/models';
import * as EditPaymentStatusActions from '@ptg-member/features/payee-detail/store/actions';
import { HeaderItemConfig } from '@ptg-member/types/models/header-item-config.model';
import * as fromReducer from '@ptg-reducers';

import { BaseComponent } from '@ptg-shared/components';
import { ACTION, DATE_FORMAT, PaymentInfoNoteType, STATE } from '@ptg-shared/constance';
import { ConfirmType } from '@ptg-shared/constance/confirm-type.const';
import { BannerType } from '@ptg-shared/controls/banner/types/banner.model';
import { ConfirmPopupComponent } from '@ptg-shared/controls/confirm-popup/confirm-popup.component';
import * as fromLayoutReducer from '@ptg-shared/layout/reducers';
import { Breadcrumb } from '@ptg-shared/types/models/breadcrumb.model';
import { showBanner } from '@ptg-shared/utils/common.util';
import { getDateString } from '@ptg-shared/utils/string.util';
import { DateTime } from 'luxon';
import { combineLatest, Subject } from 'rxjs';
import { filter, take, takeUntil } from 'rxjs/operators';
import { EditPaymentStatusComponent } from '../../components/edit-payment-status/edit-payment-status.component';
import { EditPaymentStatusComponentService } from '../../components/edit-payment-status/edit-payment-status.component.service';
import { NoteMemoDetailComponent } from '../../components/note-memo-detail/note-memo-detail.component';
import { PaymentInfoTabComponent } from '../../components/payment-info-tab/payment-info-tab.component';
import { PayrollSettingsComponent } from '../../components/payroll-settings/payroll-settings-detail.component';
import { EditPaymentStatusOutputData } from '../../services/models/edit-payment-status.model';
import { AdjustmentDetailed } from '../../services/models/view-adjustment-details.model';
import {
  cancelPaymentInstructionAction,
  cancelPaymentInstructionSelector,
  clearCancelPaymentInstructionStateAction,
  clearCreateOneTimePaymentStateAction,
  clearGetPayeePaymentStateAction,
  clearGetRecalculateTaxesStateAction,
  clearHeaderConfigurationInDialogState,
  clearSetPayeePaymentStateAction,
  createOneTimePaymentSelector,
  getRecalculateTaxesAction,
  getRecalculateTaxesSelector,
  PayeeDetailState,
  setHeaderConfigurationInDialogAction,
  setInitialPaymentConfigurationsSelector,
} from '../../store';
import { clearSetInitialPaymentConfigurationsStateAction } from '../../store/actions/initial-payment-configuration.action';
import { getValidationQDROLinkedSelector } from '../../store/selectors/edit-payment-status.selectors';
import {
  createNotesState,
  editBenefitPeriodState,
  editPayableDateState,
  updateNotesState,
} from '../../store/selectors/note-memo-detail.selector';
import { setPayeePaymentSelector } from '../../store/selectors/payee-payment.selector';
import { PaymentInfoAdjustmentType, PaymentInstructionType, PayStatus, TabPaymentInfo } from '../../types/enums';
import { HeaderBenefit, PayeePaymentType } from '../../types/models';
import { PaymentInstructionDetailComponentService } from './payment-instruction-detail.component.service';

@Component({
  selector: 'ptg-payment-instruction-detail',
  templateUrl: './payment-instruction-detail.component.html',
  styleUrls: ['./payment-instruction-detail.component.scss'],
  providers: [EditPaymentStatusComponentService, PaymentInstructionDetailComponentService],
})
export class PaymentInstructionDetailComponent extends BaseComponent {
  listBreadcrumbs: Breadcrumb[] = [{ name: '' }];
  readonly PayeePaymentType = PayeePaymentType;
  readonly TabPaymentInfo = TabPaymentInfo;
  readonly PaymentInfoAdjustmentType = PaymentInfoAdjustmentType;
  readonly PaymentInstructionType = PaymentInstructionType;

  settings: Breadcrumb[] = [];
  menuId: string = '';
  viewId: string = '';
  memberId: string = '';
  entityReferenceLinkedId: string = '';
  payrollRunId: string = '';
  payrollRunPayableDate: string = '';
  fromOneTimePayment: string = '';
  selectedInstructionId?: string;
  calendarName: string = '';
  dateOfDeath: string = '';
  isLumpsumBenefit?: boolean;

  // Notification Banner
  bannerType = BannerType.Hidden;
  message: string = '';

  // Tax Deduction Amounts Calculation Error Banner
  errorTaxCalculationBannerType = BannerType.Hidden;
  errorTaxCalculationMessage = '';

  // Termination / Suspend Notification Banners
  notificationBannerType = BannerType.Hidden;
  notificationBannerMessage = '';
  private selectedPaymentSubject$ = new Subject<PaymentTab>();
  private selectedHeaderSubject$ = new Subject<HeaderBenefit>();

  reloadPaymentInfoTabTrigger$ = new Subject();

  selectedRow?: PaymentTab & InstructionHistory;
  selectedTabPayment: TabPaymentInfo = TabPaymentInfo.Payment;
  selectedHeaderBenefit?: HeaderBenefit;
  reloadTime: number = new Date().getTime();

  @ViewChild(NoteMemoDetailComponent) noteMemoDetailComponent!: NoteMemoDetailComponent;
  @ViewChild(PaymentInfoTabComponent) paymentInfoTabComponent!: PaymentInfoTabComponent;
  @ViewChild(PayrollSettingsComponent) payrollSettingsComponent!: PayrollSettingsComponent;
  customData?: { label: string; displayValue: string }[];

  // Section Visibility
  isShowPayrollSetting: boolean = true;
  isShowAdjustmentDetailsSection = false;
  isExpandedAdjustmentDetailsSection = false;
  isLoading = true;
  cancelingPaymentInstruction = false;
  PayStatus = PayStatus;

  isChangeToTerminated: boolean = false;
  isPaymentStatusSection: boolean = false;
  lastPayrollCycle?: LastPayrollCycle;
  isShowBtnReverse: boolean = true;
  isUncheckStatus?: boolean;
  isCreatedOTPSuccess?: boolean = false;
  private headerBenefitList: HeaderBenefit[] = [];
  private headerConfigList: HeaderItemConfig[] = [];
  private isQDROLinked = false;

  constructor(
    public readonly dialog: MatDialog,
    private readonly datePipe: DatePipe,
    public readonly route: ActivatedRoute,
    private readonly store: Store<fromReducer.State>,
    private readonly payeeDetailStore: Store<PayeeDetailState>,
    private readonly editPaymentStatusComponentService: EditPaymentStatusComponentService,
    private readonly paymentInstructionDetailComponentService: PaymentInstructionDetailComponentService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.selectSetPaymentConfig();
    this.getRouteData();
    this.getSetPayeePaymentSelector();
    this.selectProfileNavigationState();
    this.selectCreateOneTimePaymentState();
    this.getSetPaymentNoteSelector();
    this.handleCancelPaymentInstruction();

    this.selectorRecalculateFederalTax();
    this.selectGetValidationQDROLinkedState();
  }

  ngOnDestroy(): void {
    this.payeeDetailStore.dispatch(clearHeaderConfigurationInDialogState());
    this.payeeDetailStore.dispatch(clearCancelPaymentInstructionStateAction());
    this.selectedHeaderSubject$.complete();
    this.selectedPaymentSubject$.complete();
    this.reloadPaymentInfoTabTrigger$.complete();
  }

  selectCreateOneTimePaymentState() {
    this.store
      .select(createOneTimePaymentSelector)
      .pipe(
        filter((res) => !!res),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((state) => {
        if (state?.success) {
          this.isCreatedOTPSuccess = true;
          showBanner.call(this, STATE.SUCCESS, 'One-time payment', ACTION.ADD);
        } else {
          showBanner.call(this, STATE.FAIL, 'One-time payment', ACTION.ADD);
        }
        this.store.dispatch(clearCreateOneTimePaymentStateAction());
      });
  }

  /**
   * To show banner with the same logic as used in showBanner function in common.util.ts file
   * @param bannerData : (Same as used in showBanner function in common.util.ts file)
   */
  getBannerEvent(bannerData: {
    state: string;
    propertyName: string;
    action: string;
    options?: { customMessage: string };
  }) {
    const { state, propertyName, action, options } = bannerData;
    showBanner.call(this, state, propertyName, action, options);
  }

  /**
   * To show banner with custom message
   * @param event : { resultStatus: BannerType, customMessage: string }
   */
  getBannerEventWithCustomMessage(event: any): void {
    showBanner.call(this, event.resultStatus, '', '', {
      customMessage: event.customMessage,
    });
    this.isChangeToTerminated = event?.isTerminated;
    this.isPaymentStatusSection = event?.isPaymentStatusSection;
    this.lastPayrollCycle = event?.lastPayrollCycle;
    this.isUncheckStatus = event?.isUncheckStatus;
    if (event?.isRejected) {
      this.paymentInstructionDetailComponentService.selectedPaymentInstructionId = '';
    }
  }

  getErrorTaxCalculationBannerMessage(event: any): void {
    if (event.isErrorTaxCalculation) {
      if (this.selectedRow?.paymentType === PaymentInstructionType.Recurring ||
          this.selectedRow?.paymentType === PaymentInstructionType.InitialPayment) {
        this.errorTaxCalculationBannerType = event.resultStatus;
        this.errorTaxCalculationMessage = event.customMessage;
        return;
      } else {
        this.errorTaxCalculationBannerType = BannerType.Hidden;
        this.errorTaxCalculationMessage = '';
        return;
      }
    }
    showBanner.call(this, event.resultStatus, '', '', { customMessage: event.customMessage });
  }

  // TODO: Will define key for each section in the Payment Instruction Detail screen later
  getReloadSignalFromChildren(key?: any): void {
    if (key === 'payment-info-tab') {
      this.reloadPaymentInfoTabTrigger$.next(null);
    }
  }

  private getSetPayeePaymentSelector(): void {
    this.store.pipe(select(setPayeePaymentSelector), takeUntil(this.unsubscribe$)).subscribe((state) => {
      if (state?.success) {
        this.bannerType = BannerType.Success;
      }

      if (state?.error) {
        this.bannerType = BannerType.Fail;
      }

      showBanner.call(this, this.bannerType, 'Payments', ACTION.EDIT);
    });

    this.payeeDetailStore.dispatch(clearGetPayeePaymentStateAction());
    this.payeeDetailStore.dispatch(clearSetPayeePaymentStateAction());
  }

  private getRouteData(): void {
    combineLatest([this.route.params, this.route.queryParams])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(([params, queryParams]: [Params, Params]) => {
        const { menuId, viewId, id } = params;
        const { payrollRunId, instructionId, calendarName, fromOneTimePayment, payableDate, entityReferenceLinkedId } =
          queryParams;

        this.menuId = menuId;
        this.memberId = id;
        this.viewId = viewId;
        this.entityReferenceLinkedId = entityReferenceLinkedId;

        this.settings = this.paymentInstructionDetailComponentService.getSettings(
          queryParams,
          this.menuId,
          this.viewId,
          this.memberId,
        );

        this.payrollRunId = payrollRunId;
        this.payrollRunPayableDate = payableDate;
        this.fromOneTimePayment = fromOneTimePayment;
        this.selectedInstructionId = instructionId;
        this.calendarName = calendarName ?? this.calendarName;
      });
  }

  private selectSetPaymentConfig(): void {
    this.payeeDetailStore
      .select(setInitialPaymentConfigurationsSelector)
      .pipe(
        filter((response) => !!response && !response.isLoading),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((res) => {
        if (res?.success) {
          showBanner.call(this, BannerType.Success, '', '', {
            customMessage: 'Initial Payment Configuration successfully updated.',
          });
        } else {
          showBanner.call(this, BannerType.Fail, '', '', {
            customMessage: 'Error occurred updating Initial Payment Configuration. Please try again.',
          });
        }
        this.payeeDetailStore.dispatch(clearSetInitialPaymentConfigurationsStateAction());
      });
  }

  onSelectRow(event: { selectedRow: PaymentTab, manualSelect: boolean } & PaymentTab): void {
    if (event?.manualSelect) {
      this.bannerType = BannerType.Hidden;
      this.selectedRow = event.selectedRow;
    } else {
      this.selectedRow = event;
    }
    this.getValidationQDROLinked();
    this.customData =
    this.selectedRow?.employerLabel && this.selectedRow?.employerName
        ? [
            {
              label: this.selectedRow.employerLabel,
              displayValue: this.selectedRow.employerName,
            },
          ]
        : [];
    this.hideTaxBanner();
    this.selectedPaymentSubject$.next(this.selectedRow);
    this.isShowPayrollSetting =
      this.selectedTabPayment === TabPaymentInfo.Payment
        ? this.selectedRow?.paymentInfoAdjustmentType !== PaymentInfoAdjustmentType.Adjustment
        : this.selectedRow?.paymentInstructionType !== PaymentInstructionType.Adjustment;

    const isShowTerminateNotification =
      this.isChangeToTerminated &&
      (this.selectedRow?.payStatus === PayStatus.Pending ||
        this.selectedRow?.payStatus === PayStatus.Suspended ||
        this.selectedRow?.payStatus === PayStatus.Finalized);
    if (this.isCreatedOTPSuccess && this.isQDROLinked) {
      this.notificationBannerMessage =
        'Please review and create a one-time payment for the related alternate payee(s) if necessary.';
      this.notificationBannerType = BannerType.Warning;
      this.isCreatedOTPSuccess = false;
    }
    if (
      this.isPaymentStatusSection &&
      (isShowTerminateNotification ||
        this.selectedRow?.prevPayStatus === PayStatus.Terminated ||
        this.selectedRow?.prevPayStatus === PayStatus.Suspended ||
        (this.selectedRow?.prevPayStatus === PayStatus.Pending && this.lastPayrollCycle != null))
    ) {
      // Termination & Suspend Notification
      this.showBannerNotification();
    }
    this.showTerminationBanner();
  }

  get isShowManuallyInputBanner() {
    return (
      this.selectedRow?.paymentType === PaymentInstructionType.InitialPayment &&
      (this.selectedRow?.payStatus === PayStatus.Suspended || this.selectedRow?.payStatus === PayStatus.Pending) &&
      this.selectedRow?.isRecalculateTaxAllowed
    );
  }

  private selectProfileNavigationState(): void {
    this.store
      .pipe(select(fromLayoutReducer.selectProfileNavigationState), takeUntil(this.unsubscribe$))
      .subscribe((state) => {
        if (state?.menu?.length > 0 && !state?.isHyperlink) {
          const currentMenu = state?.memberNavigationList?.memberNavigationMenu?.find(
            (x) => x.menuItems.find((item) => item.id === this.menuId) != null,
          );
          const menuItemName = currentMenu?.menuItems?.find((x) => x.id === this.menuId)?.name;
          if (menuItemName) {
            if (this.fromOneTimePayment) {
              const url =
                this.fromOneTimePayment === 'Pending Payments'
                  ? '/processing/otp-pending-payments'
                  : `/processing/otp-complete-payment/${this.payrollRunId}`;
              this.listBreadcrumbs = [
                {
                  url,
                  name: this.fromOneTimePayment,
                },
                {
                  name: menuItemName,
                },
              ];
            } else if (this.payrollRunId) {
              let name = this.calendarName;
              let url = `/processing/next-payroll/${this.payrollRunId}?keptState=true`;

              if (this.payrollRunPayableDate) {
                name = `Completed Payroll: ${this.datePipe.transform(
                  new Date(getDateString(this.payrollRunPayableDate)),
                  DATE_FORMAT,
                )}`;
                url += '&payrollRegister=true';
              }

              this.listBreadcrumbs = [
                {
                  name,
                  url,
                },
                {
                  name: menuItemName,
                },
              ];
            } else {
              this.listBreadcrumbs[0].name = menuItemName;
            }
          }
        }
      });
  }

  onChangeTab(event: any): void {
    // Used for resetting saved selected record in Component Service before changing Info Tab data
    this.paymentInstructionDetailComponentService.selectedPaymentInstructionId = '';
    this.paymentInstructionDetailComponentService.selectedPaymentInstructionHistoryId = '';
    this.selectedTabPayment = event;
  }

  private getSetPaymentNoteSelector(): void {
    this.store.pipe(select(createNotesState), takeUntil(this.unsubscribe$)).subscribe((state) => {
      if (state?.success) {
        if (state?.createState === STATE.SUCCESS) {
          this.noteMemoDetailComponent.getDataFirstPage();
          this.paymentInfoTabComponent.getData();
        }
        showBanner.call(
          this,
          state?.createState || '',
          this.noteMemoDetailComponent.noteType === PaymentInfoNoteType.Memo ? 'Memo' : 'Note',
          ACTION.ADD,
        );
      }
    });
    this.store.pipe(select(updateNotesState), takeUntil(this.unsubscribe$)).subscribe((state) => {
      if (state?.success) {
        if (state?.updateState === STATE.SUCCESS) {
          this.noteMemoDetailComponent.getDataFirstPage();
        }
        showBanner.call(
          this,
          state?.updateState || '',
          this.noteMemoDetailComponent.noteType === PaymentInfoNoteType.Memo ? 'Memo' : 'Note',
          ACTION.EDIT,
        );
      }
    });
    this.store.pipe(select(editPayableDateState), takeUntil(this.unsubscribe$)).subscribe((state) => {
      if (state?.success) {
        showBanner.call(this, STATE.SUCCESS, 'Payable Date', ACTION.EDIT);
        this.paymentInfoTabComponent.getData();
      } else if (state) {
        showBanner.call(this, STATE.FAIL, 'Payable Date', ACTION.EDIT);
      }
    });
    this.store.pipe(select(editBenefitPeriodState), takeUntil(this.unsubscribe$)).subscribe((state) => {
      if (state?.success) {
        showBanner.call(this, STATE.SUCCESS, 'Benefit Period', ACTION.EDIT);
        this.paymentInfoTabComponent.getData();
      } else if (state) {
        showBanner.call(this, STATE.FAIL, 'Benefit Period', ACTION.EDIT);
      }
    });
  }

  private showBannerNotification(): void {
    const { bannerType, message } = this.paymentInstructionDetailComponentService.getNotificationBannerMessage(
      this.selectedHeaderBenefit,
      this.selectedRow,
      this.isChangeToTerminated,
      this.isUncheckStatus,
      this.lastPayrollCycle,
    );

    this.notificationBannerMessage = message;
    this.notificationBannerType = bannerType;
  }

  private showTerminationBanner(): void {
    this.isShowBtnReverse = !this.isQDROLinked;
    const lastPayrollCycle = this.selectedRow?.terminatedItem?.lastPayrollCycle;
    if (this.selectedRow?.isDisplayTerminationBanner && lastPayrollCycle?.startDate && lastPayrollCycle.endDate) {
      const lastPayrollCycleStartDate = DateTime.fromISO(lastPayrollCycle.startDate);
      const lastPayrollCycleEndDate = DateTime.fromISO(lastPayrollCycle.endDate);
      const message = `${this.selectedRow.payeeName}'s ${this.selectedHeaderBenefit
        ?.benefitName} benefit is terminated with the last payroll cycle (${lastPayrollCycleStartDate.toFormat(
        'MM/dd/yyyy',
      )} - ${lastPayrollCycleEndDate.toFormat('MM/dd/yyyy')}).`;
      this.notificationBannerMessage = message;
      this.notificationBannerType = BannerType.Warning;
    }
  }

  private hideTaxBanner() {
    this.notificationBannerType = BannerType.Hidden;
  }

  onRecalculateFederalTax() {
    const confirmDialog = this.dialog.open(ConfirmPopupComponent, {
      panelClass: 'confirm-popup',
      autoFocus: false,
      data: {
        title: 'Confirmation',
        text: 'Do you want to recalculate Federal Tax. This will revert all manual changes of Funding Sources or Deductions made by the user and repopulate Funding Sources and Deductions for the corresponding One time payment',
        type: ConfirmType.ConfirmSave,
        saveButtonTitle: 'Yes',
        cancelButtonTitle: 'No',
        hideSaveAsButton: true,
        hideConfirmButton: true,
      },
    });

    confirmDialog.afterClosed().subscribe((result) => {
      if (result) {
        const request: GetRecalculateTaxesRequest = {
          paymentInstructionId: this.selectedRow?.id ?? '',
        };
        this.payeeDetailStore.dispatch(getRecalculateTaxesAction({ request }));
      }
    });
  }

  selectorRecalculateFederalTax() {
    this.payeeDetailStore
      .select(getRecalculateTaxesSelector)
      .pipe(
        filter((response) => !!response && !response.isLoading),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((data) => {
        this.payeeDetailStore.dispatch(clearGetRecalculateTaxesStateAction());
        if (data?.payload?.isSuccess) {
          this.reloadPaymentInfoTabTrigger$.next(null);
          this.hideTaxBanner();
          showBanner.call(this, BannerType.Success, '', '', { customMessage: 'Federal Tax successfully recalculated' });

          return;
        }
        showBanner.call(this, BannerType.Fail, '', '', {
          customMessage: 'Error occurred recalculating Federal Tax . Please try again.',
        });
      });
  }

  onReversePaymentStatus(): void {
    const editPaymentStatusDialog: MatDialogRef<EditPaymentStatusComponent, EditPaymentStatusOutputData> =
      this.dialog.open(EditPaymentStatusComponent, {
        panelClass: 'dialog-full-screen',
        disableClose: true,
        autoFocus: false,
        data: {
          memberId: this.memberId,
          paymentInstruction: this.selectedRow?.isDisplayTerminationBanner
            ? this.selectedRow.terminatedItem
            : this.selectedRow,
          headerBenefits: this.selectedHeaderBenefit,
          isReversing: true,
        },
      });

    editPaymentStatusDialog
      .afterClosed()
      .pipe(take(1), takeUntil(this.unsubscribe$))
      .subscribe((result) => {
        // Cancel dialog
        if (!result) {
          return;
        }
        const customMessage: string = this.editPaymentStatusComponentService.getBannerCustomMessage(
          result.isSuccess,
          result.isReversing,
        );
        this.reloadPaymentInfoTabTrigger$.next();
        this.getBannerEventWithCustomMessage({
          customMessage,
          resultStatus: result?.isSuccess ? BannerType.Success : BannerType.Fail,
          isPaymentStatusSection: true,
          isTerminated: result.isTerminated,
          lastPayrollCycle: result.lastPayrollCycle,
          isUncheckStatus: result.isUncheckStatus,
        });
      });
  }

  getSelectedHeaderBenefit(benefit: HeaderBenefit) {
    // Used for resetting saved selected record in Component Service before reloading Payment Info data
    this.paymentInstructionDetailComponentService.selectedPaymentInstructionId = '';
    this.paymentInstructionDetailComponentService.selectedPaymentInstructionHistoryId = '';
    // Assign selected Benefit Option
    this.selectedHeaderBenefit = benefit;
    // Used for showing notification banner
    this.selectedHeaderSubject$.next(this.selectedHeaderBenefit);
    // Used to trigger reload on Payment Info Tab component
    this.reloadPaymentInfoTabTrigger$.next();
    // Used to save selected Header Benefit option to Store using in Edit dialogs
    this.payeeDetailStore.dispatch(
      setHeaderConfigurationInDialogAction({ payload: { selectedHeaderBenefit: this.selectedHeaderBenefit } }),
    );
  }

  getHeaderBenefitList(headerBenefitList: HeaderBenefit[] = []): void {
    this.headerBenefitList = headerBenefitList;
    this.payeeDetailStore.dispatch(
      setHeaderConfigurationInDialogAction({
        payload: { headerBenefits: this.headerBenefitList },
      }),
    );
  }

  getHeaderConfigList(headerConfigList: HeaderItemConfig[] = []): void {
    this.headerConfigList = headerConfigList;
    this.payeeDetailStore.dispatch(
      setHeaderConfigurationInDialogAction({
        payload: { headerItems: this.headerConfigList },
      }),
    );
  }

  //* Can be used for getting adjustment detail data from ViewAdjustmentDetailsComponent on each paymentInstructionHistoryId changes
  onGetAdjustmentDetails(event: { adjustmentDetails: AdjustmentDetailed[]; onReloading?: boolean }): void {
    this.isShowAdjustmentDetailsSection = event?.onReloading || !!event?.adjustmentDetails?.length;
  }

  onClickExpandOrCollapseAdjustmentDetailsSection(): void {
    this.isExpandedAdjustmentDetailsSection = !this.isExpandedAdjustmentDetailsSection;
  }

  setLoadingState(isLoading: boolean) {
    this.isLoading = isLoading;
  }

  cancelPayment() {
    const confirmDialog = this.dialog.open(ConfirmPopupComponent, {
      panelClass: 'confirm-popup',
      autoFocus: false,
      data: {
        title: 'Confirmation',
        text: 'Are you sure you want to cancel this payment instruction?',
        type: ConfirmType.ConfirmSave,
        saveButtonTitle: 'Yes',
        cancelButtonTitle: 'No',
        hideSaveAsButton: true,
        hideConfirmButton: true,
      },
    });

    confirmDialog
      .afterClosed()
      .pipe(take(1), takeUntil(this.unsubscribe$))
      .subscribe((result) => {
        if (result) {
          const request: CancelPaymentInstructionRequest = {
            paymentInstructionId: this.selectedRow?.id ?? '',
          };
          this.payeeDetailStore.dispatch(cancelPaymentInstructionAction({ request }));
        }
      });
  }

  handleCancelPaymentInstruction() {
    this.payeeDetailStore
      .select(cancelPaymentInstructionSelector)
      .pipe(
        filter((res) => !!res),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((result) => {
        this.cancelingPaymentInstruction = result!.isLoading;
        if (result!.success) {
          showBanner.call(this, STATE.SUCCESS, '', ACTION.CANCEL, {
            customMessage: 'Payment instruction cancelled successfully.',
          });
          this.selectedInstructionId = this.selectedRow!.id;
          this.selectedTabPayment = TabPaymentInfo.InstructionHistory;
          this.reloadPaymentInfoTabTrigger$.next(null);
          return;
        }
        if (result!.error !== undefined) {
          showBanner.call(this, STATE.FAIL, 'Payment instruction', ACTION.CANCEL);
        }
      });
  }

  private getValidationQDROLinked(): void {
    if (this.selectedRow?.id) {
      this.payeeDetailStore.dispatch(
        EditPaymentStatusActions.getValidationQDROLinkedAction({ paymentInstructionId: this.selectedRow?.id }),
      );
    }
  }

  private selectGetValidationQDROLinkedState(): void {
    this.payeeDetailStore
      .select(getValidationQDROLinkedSelector)
      .pipe(
        filter((res) => !!res && !res.isLoading),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((response) => {
        if (!response?.success || !response?.payload) {
          return;
        }
        const { isQDROLinked = false } = response.payload;
        this.isQDROLinked = isQDROLinked;
        this.isShowBtnReverse = !isQDROLinked;
      });
  }
}
