import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';

import { BehaviorSubject, combineLatest } from 'rxjs';
import { filter, startWith, takeUntil, tap } from 'rxjs/operators';

import {
  ACTION,
  MAX_VALUE_NUMBER,
  DEFAULT_MAX_TEXT_LENGTH,
  DEFAULT_MIN_PERCENTAGE_VALUE,
  DEFAULT_MAX_PERCENTAGE_VALUE,
  ENTITY_ORGANIZATION_GUID,
} from '@ptg-shared/constance';

import { BaseComponent } from '@ptg-shared/components';
import { isEmpty } from '@ptg-shared/utils/string.util';
import { Option } from '@ptg-shared/controls/select/select.component';
import { BannerType } from '@ptg-shared/controls/banner/types/banner.model';
import { InputType } from '@ptg-member/constance/metadataPropertyType.const';
import { showBanner, showCancelDialog, showConfirmDialog } from '@ptg-shared/utils/common.util';
import { EditPayrollSettingsComponentService } from './edit-payroll-settings.component.service';

import {
  getEditPayrollSettingsSelector,
  saveEditPayrollSettingsSelector,
} from '../../store/selectors/edit-payroll-settings.selectors';
import { Store, select } from '@ngrx/store';
import { addPayeeListSelector, getPayeeListAction, PayeeDetailState, paymentAddressSelector } from '../../store';
import * as EditPayrollSettingsActions from '@ptg-member/features/payee-detail/store/actions';

import { PayStatus, PaymentInstructionType } from '../../types/enums';
import {
  FlatType,
  FillingStatusType,
  FederalTableFormType,
} from '@ptg-processing/features/taxes/types/enums/federal-taxes.enum';

import {
  TaxInfo,
  GetEditPayrollSettingsInput,
  GetEditPayrollSettingsRequest,
  SaveEditPayrollSettingsRequest,
  PayrollSetting,
} from '../../services/models/edit-payroll-settings.model';
import { PayeeElementDetail, PayeeResponse } from '../../services/models';
import * as fromMember from '@ptg-member/store/reducers';
import { selectProfileNavigationState } from '@ptg-shared/layout/reducers';

interface ComponentVisibility {
  showExemption: boolean;
  showIncomeFromJobPensionAnnuities: boolean;
  showClaimDependents: boolean;
  showAdditionalIncome: boolean;
  showAdditionalDeductions: boolean;
  showFlatAmount: boolean;
  showPercent: boolean;
}

@Component({
  selector: 'ptg-edit-payroll-settings',
  templateUrl: './edit-payroll-settings.component.html',
  styleUrls: ['./edit-payroll-settings.component.scss'],
  providers: [EditPayrollSettingsComponentService],
})
export class EditPayrollSettingsComponent extends BaseComponent {
  // Default constants
  readonly InputType = InputType;
  readonly minValueForCurrency = 0;
  readonly maxValueForCurrency = MAX_VALUE_NUMBER;
  readonly minValueForPercentage = DEFAULT_MIN_PERCENTAGE_VALUE;
  readonly maxValueForPercentage = DEFAULT_MAX_PERCENTAGE_VALUE;
  readonly maxCharacterLengthForTextBox = DEFAULT_MAX_TEXT_LENGTH;

  // Banner
  bannerType: BannerType = BannerType.Hidden;
  message = '';

  // Loading
  isLoading = true;
  isErrorOccurred = new BehaviorSubject<boolean>(false);

  // Dropdown list input data
  readonly formList: Option[] = this.editPayrollSettingsComponentService.federalTableFormTypeOptionList;
  readonly additionalWithholdingList: Option[] =
    this.editPayrollSettingsComponentService.additionalWithholdingOptionList;

  representativesPayeeNameList: Option[] = [];
  taxTableList: Option[] = [];
  paymentAddressList: Option[] = [];
  estatePayeeList: Option[] = [];

  // FormGroup, FormControl
  editForm = new FormGroup({});

  // Section visibility conditions
  showRepresentativePayeeName = false;
  showNamePrintOnCheck = false;
  showIssueToEstate = false;

  // TODO (Next Sprint): System displays the fields in this section if the Payee has a Deduction with [Deduction Type] = "Tax" and [Tax Type] = "Federal".
  showFederalWithholdingColumn = true;
  // TODO (Next Sprint): System displays the fields in this section if the Payee has a Deduction with [Deduction Type] = "Tax" and [Tax Type] = "State".
  showStateWithholdingColumn = false;

  // Component visibility conditions
  federalComponentVisibility: ComponentVisibility = {
    showExemption: false,
    showIncomeFromJobPensionAnnuities: false,
    showClaimDependents: false,
    showAdditionalIncome: false,
    showAdditionalDeductions: false,
    showFlatAmount: false,
    showPercent: false,
  };
  stateComponentVisibility: ComponentVisibility = {
    showExemption: false,
    showIncomeFromJobPensionAnnuities: false,
    showClaimDependents: false,
    showAdditionalIncome: false,
    showAdditionalDeductions: false,
    showFlatAmount: false,
    showPercent: false,
  };

  // Singular data
  private entityId = '';
  private savedPaymentAddressCode = '';
  private currentPayee = { id: '', isPerson: true, name: '' };
  currentData!: PayrollSetting;

  constructor(
    @Inject(MAT_DIALOG_DATA) public readonly data: GetEditPayrollSettingsInput,
    private readonly dialog: MatDialog,
    private readonly payeeDetailStore: Store<PayeeDetailState>,
    private readonly dialogRef: MatDialogRef<EditPayrollSettingsComponent>,
    private readonly editPayrollSettingsComponentService: EditPayrollSettingsComponentService,
    private readonly memberStore: Store<fromMember.MemberState>,
  ) {
    super();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.payeeDetailStore.dispatch(EditPayrollSettingsActions.clearPaymentAddressState());
  }

  ngOnInit(): void {
    // Do not display for One-Time Payment
    this.showFederalWithholdingColumn = !this.data.isOneTimePayment;

    // Initialize FormGroup with default values
    this.editForm = this.editPayrollSettingsComponentService.getInitForm(
      this.showFederalWithholdingColumn,
      this.showStateWithholdingColumn,
    );

    // Initialize FormGroup validators depends on Payment Status
    this.initValidatorByPaymentStatus();

    // Initialize FormGroup valueChange listeners
    this.initRepresentativePayeeValueChange();
    this.initIssueToEstateValueChange();
    this.initPayeeValueChange();

    if (this.showFederalWithholdingColumn) {
      this.initWithholdingFormGroupValueChanges(this.federalControls, this.federalComponentVisibility);
    }
    if (this.showStateWithholdingColumn) {
      this.initWithholdingFormGroupValueChanges(this.stateControls, this.stateComponentVisibility);
    }

    // Listen to saved data state
    this.selectGetEditPayrollSettingsState();
    // Listen to Payee Payment Address state
    this.selectPaymentAddressState();

    // Get Entity ID for getting Current Payee Address List
    this.selectProfileNavigationState();
    // Get saved data for Edit Payroll Settings screen
    this.getSavedDetailData();
    // Get Payee List & Tax Info
    this.getRelatedPayeeAndTaxInfo();

    // Listen to save action on Edit Payroll Settings screen
    this.selectSaveEditPayrollSettingsState();

    // Show error banner message on any error occurrences
    this.showOccurrenceErrorPopupMessage();
  }

  onCancel(): void {
    showCancelDialog(this.dialog, this.dialogRef);
  }

  onSave(): void {
    // Once the user clicks on the "Save" button, the system will validate:
    this.editForm.markAllAsTouched();
    if (this.editForm.invalid) {
      return;
    }
    const formData = this.editForm.getRawValue();
    if (this.data.isOneTimePayment) {
      formData.federal = {
        form: FederalTableFormType.None,
        withholding: FillingStatusType.None,
        additionalWithholding: FlatType.None,
      };
    }

    // If the validation succeeds, system proceed to show a confirmation pop-up as in defined BR 3.
    const body: SaveEditPayrollSettingsRequest = this.editPayrollSettingsComponentService.getSaveRequest(
      formData,
      this.currentPayee,
    );
    if (this.data?.paymentType === PaymentInstructionType.InitialPayment && body.addressCode !== this.currentData?.savedPayrollSetting?.addressCode) {
      showConfirmDialog(this.dialog, 'Do you want to use this address for the Recurring Payment Instruction?').subscribe(el => {
        body.isUpdateAddressOfRecurring = el;
        this.payeeDetailStore.dispatch(
          EditPayrollSettingsActions.saveEditPayrollSettingsAction({
            body,
            paymentInstructionId: this.data.paymentInstructionId,
          }),
        );
      })
    } else {
      this.payeeDetailStore.dispatch(
        EditPayrollSettingsActions.saveEditPayrollSettingsAction({
          body,
          paymentInstructionId: this.data.paymentInstructionId,
        }),
      );
    }
  }

  private initValidatorByPaymentStatus(): void {
    if (isEmpty(this.data.paymentStatus)) {
      this.isErrorOccurred.next(true);
      return;
    }

    /**
     * This field is mandatory if "Suspend" checkbox is unchecked.
     * Else, this field is not mandatory.
     */
    const isSuspendedPaymentInstruction = this.data.paymentStatus === PayStatus.Suspended;

    if (!isSuspendedPaymentInstruction) {
      this.paymentAddressControl.addValidators(Validators.required);
    }

    if (this.showFederalWithholdingColumn && !isSuspendedPaymentInstruction) {
      this.federalControls.form.addValidators(Validators.required);
    }

    if (this.showStateWithholdingColumn && !isSuspendedPaymentInstruction) {
      this.stateControls.form.addValidators(Validators.required);
    }
  }

  private selectSaveEditPayrollSettingsState(): void {
    this.payeeDetailStore
      .pipe(
        select(saveEditPayrollSettingsSelector),
        filter((res) => !!res && !res.isLoading),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((response) => {
        this.payeeDetailStore.dispatch(EditPayrollSettingsActions.clearSaveEditPayrollSettingsState());

        if (!response?.success) {
          // Show error banner message
          showBanner.call(this, BannerType.Fail, 'Payroll Settings', ACTION.EDIT);
          return;
        }

        // Response to View Detail screen to show success banner message
        this.dialogRef.close(response?.success);

        const request = {
          paymentInstructionId: this.data.paymentInstructionId,
          memberId: this.currentPayee.id,
          paymentInstructionHistoryId: this.data.paymentInstructionHistoryId,
          paymentInfoAdjustmentType: this.data.paymentInfoAdjustmentType,
          instructionStatusHistoryId: this.data.instructionStatusHistoryId ?? '',
        };
        this.payeeDetailStore.dispatch(EditPayrollSettingsActions.getPayeeDeductionsDetailAction(request));
      });
  }

  private initWithholdingFormGroupValueChanges(
    formControls: { [key: string]: AbstractControl },
    componentVisibility: ComponentVisibility,
  ): void {
    formControls.form.valueChanges
      .pipe(startWith(null), takeUntil(this.unsubscribe$))
      .subscribe((formValue: FederalTableFormType | null) => {
        const isNotSelectedOrNone = isEmpty(formValue) || formValue === FederalTableFormType.None;

        // If user does not select any value or selects "None", Withholding component is disabled
        isNotSelectedOrNone ? formControls.withholding.disable() : formControls.withholding.enable();
        // Condition for the Withholding field is required or not
        formControls.withholding.setValidators(!isNotSelectedOrNone ? Validators.required : null);
        // Withholding resets to default (no selection, only display Placeholder)
        formControls.withholding.reset();
        /**
         * all Tax tables saved in the database of the system
         * where Federal Tax [Year] = Current year and [Form] = <Form> selected in Form component.
         */
        this.taxTableList
          .filter((item) => item.value?.fillingStatus !== FillingStatusType.None)
          .forEach((option) => {
            option.isHide = option.value?.taxTableFormType !== formValue;
          });
      });

    combineLatest([formControls.form.valueChanges, formControls.withholding.valueChanges])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(([formValue, withholdingValue]: [FederalTableFormType | null, TaxInfo | null]) => {
        const withholdingIsNotSelectedOrNone =
          isEmpty(withholdingValue) || withholdingValue?.fillingStatus === FillingStatusType.None;
        const showExemptionCondition = formValue === FederalTableFormType.W4 && !withholdingIsNotSelectedOrNone;
        const showAdditionalComponentsCondition =
          formValue === FederalTableFormType.W4P && !withholdingIsNotSelectedOrNone;

        /**
         * If user previous selected an option <> "None", then changes the selection to "None":
         * Input fields that depend on Withholding are hidden and the user’s inputs for them are discarded
         */
        if (withholdingIsNotSelectedOrNone) {
          formControls.exemption.reset();
          formControls.incomeFromJobPensionAnnuities.reset();
          formControls.claimDependents.reset();
          formControls.additionalIncome.reset();
          formControls.additionalDeductions.reset();
        }

        // If selected <Form> = "W-4 2019 or Earlier": display Exemption component
        componentVisibility.showExemption = showExemptionCondition;
        // If selected <Form> = "W-4P; W-4 2020 or Later": Display the following components
        componentVisibility.showIncomeFromJobPensionAnnuities = showAdditionalComponentsCondition;
        componentVisibility.showClaimDependents = showAdditionalComponentsCondition;
        componentVisibility.showAdditionalIncome = showAdditionalComponentsCondition;
        componentVisibility.showAdditionalDeductions = showAdditionalComponentsCondition;
      });

    formControls.additionalWithholding.valueChanges
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((additionalWithholdingValue) => {
        const isFlatAmount = additionalWithholdingValue === FlatType.Flat;
        const isPercent = additionalWithholdingValue === FlatType.Percent;

        // Condition for the Flat Amount field is required or not
        formControls.flatAmount.setValidators(isFlatAmount ? Validators.required : null);
        isFlatAmount && formControls.flatAmount.reset();

        // Condition for the Percent field is required or not
        formControls.percent.setValidators(isPercent ? Validators.required : null);
        isPercent && formControls.percent.reset();

        // This field will only be displayed if the value in [Additional Withholding] = "Flat Amount".
        componentVisibility.showFlatAmount = isFlatAmount;
        // This field will only be displayed if the value in [Additional Withholding] = "Percent".
        componentVisibility.showPercent = isPercent;
      });
  }

  private initRepresentativePayeeValueChange(): void {
    this.hasRepresentativePayeeControl.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe((checked: boolean) => {
      /**
       * If [Representative Payee] = "UNCHECKED", user can select an existing address of the current Benefit Payee in the dropdown list.
       * If [Representative Payee] = "CHECKED", user can select an existing address of the current Representative Payee in the dropdown list.
       */
      this.paymentAddressList = [];
      !checked && this.getCurrentPayeeAddressList();

      // Condition for the Representative Payee Name field is required or not
      this.representativesPayeeControl.setValidators(checked ? Validators.required : null);
      // Condition for the Name Printed on Check field is required or not
      this.namePrintedOnCheckControl.setValidators(checked ? Validators.required : null);

      // If the user checks/unchecks [Representative Payee] checkbox, reset the field value to null
      this.representativesPayeeControl.reset();
      this.namePrintedOnCheckControl.reset();
      // If the user checks/unchecks [Representative Payee] checkbox or selects a different [Representative Payee], reset the field value to null.
      this.paymentAddressControl.reset('');

      // Default as the first record in the dropdown list.
      checked &&
        this.representativesPayeeNameList.length &&
        this.representativesPayeeControl.setValue(this.representativesPayeeNameList[0].value);

      // System displays this component when [Representative Payee] = "CHECKED".
      this.showRepresentativePayeeName = checked;
      this.showNamePrintOnCheck = checked;
    });

    this.representativesPayeeControl.valueChanges
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((representativeValue) => {
        const selectedRepresentativePayeeOption = this.representativesPayeeNameList.find(
          (option) => (option.value as PayeeElementDetail)?.id === representativeValue?.id,
        );
        // When user select [Representative Payee Name] drop-down list above, system automatically fill out this field as: "<Selected Representative Payee Name> FBO <Participant Name>"
        const namePrintedOnCheck = selectedRepresentativePayeeOption?.displayValue
          ? `${selectedRepresentativePayeeOption.displayValue} FBO ${this.currentPayee.name}`
          : null;
        this.namePrintedOnCheckControl.setValue(this.hasRepresentativePayeeControl.value ? namePrintedOnCheck : null);

        // If the user checks/unchecks [Representative Payee] checkbox or selects a different [Representative Payee], reset the field value to null.
        this.paymentAddressControl.reset('');

        // Prevent NULL value on change that causes all the options of "Payment Address" dropdown list to be hidden
        representativeValue &&
          this.paymentAddressList.forEach((item) => {
            item.isHide = item.value?.payeeEntityId !== representativeValue?.id;
          });

        // Get the corresponding [Payment Address] to the selected [Representative Payee Name]
        selectedRepresentativePayeeOption &&
          this.payeeDetailStore.dispatch(
            EditPayrollSettingsActions.getPaymentAddressAction({
              payeeId: selectedRepresentativePayeeOption?.value?.id ?? '',
              isPerson: selectedRepresentativePayeeOption?.value?.isPerson ?? false,
              benefitCode: this.data.benefitCode,
            }),
          );
      });
  }

  private initIssueToEstateValueChange(): void {
    this.issueToEstateControl.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe((checked: boolean) => {
      this.payeeControl.setValidators(checked ? Validators.required : null);
      // If the user checks/unchecks [Issue to Estate] checkbox, reset the field value to null.
      this.payeeControl.reset();
      this.paymentAddressControl.reset('');
      this.paymentAddressList = [];
      !checked && this.getCurrentPayeeAddressList();
    });
  }

  private initPayeeValueChange(): void {
    this.payeeControl.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe((selectedPayeeId: string | null) => {
      if (!selectedPayeeId) return;

      this.paymentAddressControl.reset('');
      const selectedPayeeOption = this.estatePayeeList.find((item) => item.value === selectedPayeeId);

      this.payeeDetailStore.dispatch(
        EditPayrollSettingsActions.getPaymentAddressAction({
          payeeId: selectedPayeeOption?.value ?? '',
          isPerson: selectedPayeeOption?.extraData,
          benefitCode: this.data.benefitCode,
        }),
      );
    });
  }

  private selectPaymentAddressState(): void {
    this.payeeDetailStore
      .select(paymentAddressSelector)
      .pipe(
        tap((res) =>
          typeof res.success === 'boolean' ? this.paymentAddressControl.enable() : this.paymentAddressControl.disable(),
        ),
        filter((res) => !res.isLoading),
        tap((res) => this.isErrorOccurred.next(!res.success)),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((response) => {
        let selectedPayeeId = this.currentPayee.id;
        if (this.issueToEstateControl.value && this.payeeControl.value) selectedPayeeId = this.payeeControl.value;
        else if (this.hasRepresentativePayeeControl.value) selectedPayeeId = this.representativesPayeeControl.value?.id;

        const paymentAddressList = this.editPayrollSettingsComponentService.getAddressOptionList(
          response?.payload,
          selectedPayeeId,
        );

        this.paymentAddressList = paymentAddressList;
        const savedPaymentAddress = this.savedPaymentAddressCode
          ? this.paymentAddressList.find((item) => item.value?.addressCode === this.savedPaymentAddressCode)
          : null;
        this.paymentAddressControl.setValue(savedPaymentAddress?.value ?? '');

        // Reset to falsy value to set the first record in the list instead of the saved one for the second time changing the [Representative Payee/Payee]
        if (this.savedPaymentAddressCode) this.savedPaymentAddressCode = '';
      });
  }

  private getInitData(data: PayrollSetting, data2: PayeeResponse): void {
    const initData = this.editPayrollSettingsComponentService.getInitData(data, data2);

    const { systemSettings, savedSettings } = initData;

    const { currentPayeeName, withholdingOptionList, representativePayeeOptionList, estatePayeeOptionList } =
      systemSettings;

    const {
      isRepresentative,
      savedRepresentativePayeeId,
      savedNamePrintedOnCheck,
      savedPaymentAddressCode,
      savedWithholding,
      isAbleReissueToEstate,
    } = savedSettings;

    this.currentPayee = {
      id: this.data.memberId,
      isPerson: this.entityId !== ENTITY_ORGANIZATION_GUID,
      name: currentPayeeName,
    };

    // System displays this component when the current payment instruction: Has [One-time Payment Type] = "Reissue" or "Final Payment" and the payee has [Reported Deceased] = "CHECKED" or Date of Death <> null
    this.showIssueToEstate = !!this.data.isReissue && isAbleReissueToEstate;
    this.savedPaymentAddressCode = savedPaymentAddressCode;

    this.representativesPayeeNameList = representativePayeeOptionList;
    this.taxTableList = withholdingOptionList;
    this.estatePayeeList = estatePayeeOptionList;

    //! IMPORTANT: These FormControl needs to be run after all the dropdown lists input data are filled
    // Note: This is the order making the flow of value changes works. Be careful when trying to re-arrange it
    this.hasRepresentativePayeeControl.setValue(isRepresentative);
    const selectedRepresentativePayeeOption = this.representativesPayeeNameList.find(
      (item) => (item.value as PayeeElementDetail)?.id === savedRepresentativePayeeId,
    );
    this.representativesPayeeControl.setValue(
      isRepresentative && selectedRepresentativePayeeOption ? selectedRepresentativePayeeOption.value : null,
    );
    this.namePrintedOnCheckControl.setValue(
      isRepresentative && selectedRepresentativePayeeOption && savedNamePrintedOnCheck ? savedNamePrintedOnCheck : null,
    );

    this.showIssueToEstate && this.issueToEstateControl.setValue(!!this.data.estateId);
    if (this.showIssueToEstate && this.issueToEstateControl.value) {
      const selectedPayee = this.estatePayeeList.find((item) => item.value === this.data.estateId)?.value || null;
      this.payeeControl.setValue(selectedPayee);
    }

    if (this.showFederalWithholdingColumn) {
      this.federalControls.form.setValue(savedWithholding?.federal?.form ?? null);

      const savedWithholdingOption = this.taxTableList.find(
        (item) => item.value?.fillingStatus === savedWithholding?.federal?.fillingStatus,
      );
      this.federalControls.withholding.setValue(savedWithholdingOption?.value ?? null);
      this.federalControls.exemption.setValue(savedWithholding?.federal?.exemption?.toString() ?? null);
      this.federalControls.incomeFromJobPensionAnnuities.setValue(
        savedWithholding?.federal?.incomeFromJobPensionAnnuities ?? null,
      );
      this.federalControls.claimDependents.setValue(savedWithholding?.federal?.claimDependents ?? null);
      this.federalControls.additionalIncome.setValue(savedWithholding?.federal?.additionalIncome ?? null);
      this.federalControls.additionalDeductions.setValue(savedWithholding?.federal?.additionalDeductions ?? null);
      this.federalControls.additionalWithholding.setValue(savedWithholding?.federal?.additionalWithholding ?? null);
      this.federalControls.flatAmount.setValue(savedWithholding?.federal?.flatAmount ?? null);
      this.federalControls.percent.setValue(savedWithholding?.federal?.percent ?? null);
    }
  }

  private getSavedDetailData(): void {
    const { paymentInstructionId, memberId } = this.data;

    const request: GetEditPayrollSettingsRequest = {
      paymentInstructionId,
      payeeRecordId: memberId,
    };
    this.payeeDetailStore.dispatch(EditPayrollSettingsActions.getEditPayrollSettingsAction({ request }));
  }

  private selectGetEditPayrollSettingsState(): void {
    combineLatest([
      this.payeeDetailStore.select(getEditPayrollSettingsSelector),
      this.payeeDetailStore.select(addPayeeListSelector),
    ])
      .pipe(
        tap(([res1, res2]) => (this.isLoading = !!res1?.isLoading && !!res2?.isLoading && !res2.payload)),
        filter((responseList) => responseList?.every((res) => !!res && !res?.isLoading)),
        tap((responseList) =>
          this.isErrorOccurred.next(responseList.some((res) => !!res && !res.isLoading && !res.success)),
        ),
        takeUntil(this.unsubscribe$),
      )
      .subscribe(([response1, response2]) => {
        this.payeeDetailStore.dispatch(EditPayrollSettingsActions.clearGetEditPayrollSettingsState());
        this.payeeDetailStore.dispatch(EditPayrollSettingsActions.clearPayeeListState());

        if (!response1?.payload?.payrollSetting || !response2?.payload) return;

        // Append saved data
        this.currentData = response1.payload.payrollSetting;
        this.getInitData(response1.payload.payrollSetting, response2.payload);
      });
  }

  private getRelatedPayeeAndTaxInfo(): void {
    this.payeeDetailStore.dispatch(
      getPayeeListAction({
        payeeId: this.data.memberId,
        benefitCode: this.data?.benefitCode,
      }),
    );
  }

  private getCurrentPayeeAddressList(): void {
    this.payeeDetailStore.dispatch(
      EditPayrollSettingsActions.getPaymentAddressAction({
        payeeId: this.currentPayee.id,
        isPerson: this.currentPayee.isPerson,
        benefitCode: this.data.benefitCode,
      }),
    );
  }

  private selectProfileNavigationState(): void {
    this.memberStore.pipe(select(selectProfileNavigationState), takeUntil(this.unsubscribe$)).subscribe((state) => {
      if (state?.menu?.length > 0 && !state?.isHyperlink) {
        this.entityId = (state?.memberNavigationList as any).entityId;
      }
    });
  }

  private showOccurrenceErrorPopupMessage(): void {
    this.isErrorOccurred
      .pipe(
        filter((errorOccurred) => !!errorOccurred),
        takeUntil(this.unsubscribe$),
      )
      .subscribe(() => {
        showBanner.call(this, BannerType.Fail, 'Payroll Settings', ACTION.EDIT);
      });
  }

  get hasRepresentativePayeeControl(): FormControl {
    return this.editForm.get('hasRepresentativePayee') as FormControl;
  }

  get representativesPayeeControl(): FormControl {
    return this.editForm.get('representativesPayee') as FormControl;
  }

  get namePrintedOnCheckControl(): FormControl {
    return this.editForm.get('namePrintedOnCheck') as FormControl;
  }

  get paymentAddressControl(): FormControl {
    return this.editForm.get('paymentAddress') as FormControl;
  }

  get federalControls(): { [key: string]: AbstractControl } {
    return (this.editForm.get('federal') as FormGroup)?.controls;
  }

  get stateControls(): { [key: string]: AbstractControl } {
    return (this.editForm.get('state') as FormGroup)?.controls;
  }

  get issueToEstateControl(): FormControl {
    return this.editForm.get('issueToEstate') as FormControl;
  }

  get payeeControl(): FormControl {
    return this.editForm.get('payee') as FormControl;
  }
}
