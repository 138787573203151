import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { Store } from '@ngrx/store';
import { EMPTY, Subject, combineLatest } from 'rxjs';
import { debounceTime, filter, switchMap, take, takeUntil, tap } from 'rxjs/operators';

import { Column, Row } from '@ptg-shared/controls/grid';
import { FIRST_PAGE, PageEvent } from '@ptg-shared/controls/pagination';
import { BaseListComponent } from '@ptg-shared/components/base-list.component';
import {
  GRID_COLUMN_INSTRUCTION_HISTORY_TAB,
  GRID_COLUMN_PAYMENT_INFO_TAB,
  shouldUseLumpsumpName,
} from '../../types/constants/payment-info-tab.constant';
import { GetPaymentInfoTabsRequest, InstructionHistory, MarkOverpaidFinalizedPaymentRequest, PaymentTab } from '../../services/models';
import {
  PayeeDetailState,
  checkDisplayOffsetButtonAction,
  checkDisplayOffsetButtonSelector,
  clearCreateOneTimePaymentStateAction,
  createOneTimePaymentAction,
  createOneTimePaymentSelector,
  createReversalAdjustmentSelector,
  getInstructionHistoriesSelector,
  getPaymentInfoTabsSelector,
} from '../../store';
import * as PayeeDetailAction from '../../store/actions';
import {
  PayStatus,
  PayStatusLabel,
  PaymentInstructionType,
  PaymentInstructionTypeLabel,
  TabPaymentInfo,
  PaymentInfoAdjustmentType,
  PaymentStatusAdjustmentType,
  AdjustmentStatusLabel,
  PositionPaymentInfoTabDetailedQueryType,
} from '../../types/enums';
import { LayoutService } from '@ptg-shared/services/layout.service';
import { HeaderBenefit } from '../../types/models';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AddOneTimeComponent } from '@ptg-member/components/add-one-time/add-one-time.component';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { PERMISSION_KEY } from '@ptg-shared/constance/permission.const';
import { GUID_EMPTY } from '@ptg-shared/constance';
import { CalculationType } from '@ptg-member/features/calculation/types/enums';
import { DatePipe } from '@angular/common';
import { getDateString, isEmpty } from '@ptg-shared/utils/string.util';
import { NewDetailedAdjustmentComponent } from '../new-detailed-adjustment/new-detailed-adjustment.component';
import { CreateGeneralAdjustmentComponent } from '../create-general-adjustment/create-general-adjustment.component';
import {
  CreateGeneralAdjustmentInputData,
  CreateGeneralAdjustmentOutputData,
} from '../../services/models/create-general-adjustment.model';
import { BannerType } from '@ptg-shared/controls/banner/types/banner.model';
import { ConfirmPopupComponent } from '@ptg-shared/controls/confirm-popup/confirm-popup.component';
import { ConfirmType } from '@ptg-shared/constance/confirm-type.const';
import { PaymentInstructionDetailComponentService } from '../../pages/payment-instruction-detail/payment-instruction-detail.component.service';
import { showConfirmDialog } from '@ptg-shared/utils/common.util';
import { CreateOffsetDeductionComponent } from '../create-offset-deduction/create-offset-deduction.component';
import { MarkRemoveOverpaidComponent, MarkRemoveOverpaidDialogInput, MarkRemoveOverpaidDialogOutput } from '../mark-remove-overpaid/mark-remove-overpaid.component';

@Component({
  selector: 'ptg-payment-info-tab',
  templateUrl: './payment-info-tab.component.html',
  styleUrls: ['./payment-info-tab.component.scss'],
})
export class PaymentInfoTabComponent extends BaseListComponent implements OnChanges {
  readonly GUID_EMPTY = GUID_EMPTY;
  readonly TabPaymentInfo = TabPaymentInfo;

  PaymentInfoAdjustmentType = PaymentInfoAdjustmentType;
  PayStatus = PayStatus;
  pageName = 'ptg-payment-info-tab';

  columns: Column[] = GRID_COLUMN_PAYMENT_INFO_TAB;
  paymentInfoTabData: PaymentTab[] = [];
  instructionHistoryData: InstructionHistory[] = [];
  pageNumber: number = FIRST_PAGE;
  isLoading = false;
  benefitIdParam?: string;
  currentRowIndex: number = 0;
  totalRecords: number = 0;
  errorMessage: string = '';
  selectedRow?: PaymentTab | InstructionHistory;
  TabPaymentList = [
    {
      name: 'Payment',
      type: TabPaymentInfo.Payment,
    },
    {
      name: 'Instruction History',
      type: TabPaymentInfo.InstructionHistory,
    },
  ];
  payeeRecordId?: string;
  entityReferenceLinkedId?: string;
  readonly PERMISSION_KEY = PERMISSION_KEY;
  // isShowDetailedAdjustment: boolean = false;
  isShowOffsetButton = false;
  isFinalizedPeriodicLumpSum = false;

  @Input() selectedHeaderBenefit!: HeaderBenefit;
  @Input() selectedTabPayment!: TabPaymentInfo;
  @Input() memberId!: string;
  @Input() selectedRowId?: string;
  @Input() getReloadSignalFromParent$ = new Subject();
  @Output() changeBannerEmitter = new EventEmitter<{
    customMessage: string;
    resultStatus: BannerType;
  }>();

  @Output() onChangeTab = new EventEmitter();
  @Output() onSelectRow = new EventEmitter();
  @Input() dateOfDeath: string | undefined;
  @Output() dateOfDeathChange: EventEmitter<string> = new EventEmitter<string>();
  @Input() isLumpsumBenefit?: boolean;
  @Output() isLumpsumBenefitChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(
    private store: Store<PayeeDetailState>,
    public layoutService: LayoutService,
    public dialog: MatDialog,
    public route: ActivatedRoute,
    public readonly router: Router,
    private readonly datePipe: DatePipe,
    public readonly paymentInstructionDetailComponentService: PaymentInstructionDetailComponentService,
  ) {
    super(layoutService);
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.getReloadSignalFromParent$.complete();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.selectedHeaderBenefit && changes.selectedHeaderBenefit?.currentValue && this.payeeRecordId) {
      this.checkOffsetButton();
    }
    if (changes.selectedRowId) {
      this.paymentInstructionDetailComponentService.selectedPaymentInstructionId = changes.selectedRowId?.currentValue;
    }
  }

  ngOnInit(): void {
    super.ngOnInit();
    combineLatest([this.route.queryParams, this.route.params])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(([queryParams, params]: [Params, Params]) => {
        const {
          entityReferenceLinkedId = '',
          instructionHistoryId = '',
          benefitId = '',
          instructionId = '',
          selectedTab = '',
        } = queryParams;
        const { id = '' } = params;

        this.entityReferenceLinkedId = entityReferenceLinkedId;
        this.paymentInstructionDetailComponentService.selectedPaymentInstructionId = instructionId;
        this.paymentInstructionDetailComponentService.selectedPaymentInstructionHistoryId = instructionHistoryId;
        this.benefitIdParam = benefitId;

        if (selectedTab) {
          const selectedTabPayment: TabPaymentInfo = parseInt(selectedTab);
          this.selectedTabPayment = parseInt(selectedTab);
          this.columns =
            selectedTabPayment === TabPaymentInfo.Payment
              ? GRID_COLUMN_PAYMENT_INFO_TAB
              : GRID_COLUMN_INSTRUCTION_HISTORY_TAB;
        }

        this.payeeRecordId = id;

        if (instructionHistoryId) {
          this.paymentInstructionDetailComponentService.paymentInfoTabRequestData = this.createRequestInfoTab();
          this.paymentInstructionDetailComponentService.getAdjustmentDetailItemPosition(
            { paymentInstructionHistoryId: instructionHistoryId },
            PositionPaymentInfoTabDetailedQueryType.PaymentInstructionHistory,
          );
          this.checkOffsetButton();
        } else if (this.payeeRecordId) {
          this.getData();
          this.checkOffsetButton();
        }
      });

    this.selectGetPaymentInfoTabState();
    this.selectGetInstructionHistoriesState();
    this.selectCreateOneTimePaymentState();

    this.getReloadSignalFromParent$.pipe(debounceTime(100), takeUntil(this.unsubscribe$)).subscribe(() => {
      this.getData();
    });

    this.store
      .select(checkDisplayOffsetButtonSelector)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((el) => {
        this.isShowOffsetButton = !!el?.payload?.isValid;
      });
  }

  createRequestInfoTab() {
    const pageNumber = Number(
      sessionStorage.getItem(this.layoutService.currentFund$.value.key + '-' + this.pageName + '-pageNumber'),
    );
    const pageSize = Number(
      sessionStorage.getItem(this.layoutService.currentFund$.value.key + '-' + this.pageName + '-pageSize'),
    );
    this.pageNumber = pageNumber === 0 ? this.pageNumber : pageNumber;
    this.pageSize = pageSize === 0 ? this.pageSize : pageSize;
    const benefitCode = this.selectedHeaderBenefit?.benefitId;
    const calculationType = this.selectedHeaderBenefit?.calculationType;
    const request: GetPaymentInfoTabsRequest = {
      pageNumber: this.pageNumber,
      pageSize: this.pageSize,
      benefitSubtypeId: this.selectedHeaderBenefit.benefitTypeOptionId,
      payeeRecordId: this.payeeRecordId,
      ...(benefitCode && {
        benefitCode,
      }),
      ...(!isEmpty(calculationType) && {
        calculationType,
      }),
    };
    
    return request;
  }

  checkOffsetButton() {
    this.store.dispatch(
      checkDisplayOffsetButtonAction({
        request: {
          payeeRecordId: this.payeeRecordId,
          benefitSubtypeId: this.selectedHeaderBenefit.benefitTypeOptionId,
        },
      }),
    );
  }

  getData() {
    const request: GetPaymentInfoTabsRequest = this.createRequestInfoTab();

    if (this.selectedTabPayment === TabPaymentInfo.InstructionHistory) {
      this.store.dispatch(PayeeDetailAction.getInstructionHistoryAction({ request }));
      return;
    }
    this.store.dispatch(PayeeDetailAction.getPaymentInfoTabsAction({ request }));
    this.paymentInstructionDetailComponentService.paymentInfoTabRequestData = request; // Store current Payment Info Tab section request body
  }

  selectGetPaymentInfoTabState() {
    this.store
      .select(getPaymentInfoTabsSelector)
      .pipe(
        filter((res) => !!res),
        tap((res) => (this.isLoading = !!res?.isLoading)),
        filter((res) => !!res && !res.isLoading),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((state) => {
        const payload = state!.payload;
        this.dateOfDeathChange.emit(state!.payload?.dateOfDeath);
        this.isLumpsumBenefitChange.emit(state?.payload?.isLumpsumpBenefit);
        this.store.dispatch(PayeeDetailAction.clearGetPaymentInfoTabsStateAction());
        if (!state?.success || payload?.paymentTabs?.length === 0) {
          this.handleResetData();
          return;
        }
        this.isFinalizedPeriodicLumpSum = !!state.payload?.isFinalizedPeriodicLumpSum;
        // Mapping the data list for Grid Component
        this.paymentInfoTabData =
          payload?.paymentTabs?.map((p) => {
            const netPayment = (p.gross ?? 0) - (p.totalDeduction ?? 0);
            const isApprovedDate =
              [CalculationType.DisabilityShortTerm, CalculationType.Refund, CalculationType.LODDDeath].includes(
                this.selectedHeaderBenefit.calculationType as CalculationType,
              ) ||
              (this.selectedHeaderBenefit.calculationType === CalculationType.Survivor &&
                p.paymentType === PaymentInstructionType.PeriodicLumpsumPayment);
            const payableDate = isApprovedDate
              ? this.datePipe.transform(getDateString(p.payableDate ?? ''), 'MM/dd/yyyy')
              : this.datePipe.transform(getDateString(p.payableDate ?? ''), 'MM/dd/yyyy', 'UTC');
            const benefitPeriod =
              p.benefitPeriod !== '' &&
              p.paymentType !== PaymentInstructionType.PeriodicLumpsumPayment &&
              p.paymentType !== PaymentInstructionType.AutoOneTime
                ? p.benefitPeriod
                : ' - ';
            const isShowOverpaid = p.payStatus === PayStatus.Finalized;
            return {
              ...p,
              payStatusName: this.getPayStatusLabel(p),
              paymentTypeName: this.getPaymentTypeName(p),
              payableDate: payableDate ?? '-',
              netPayment,
              benefitPeriod,
              isShowOverpaid
            };
          }) ?? [];

        // Handle row selection
        this.selectedRow = this.getSelectedRow(
          this.paymentInstructionDetailComponentService.selectedPaymentInstructionId,
          this.paymentInstructionDetailComponentService.selectedPaymentInstructionHistoryId,
        );
        this.onSelectRow.emit(this.selectedRow);
        if (this.selectedRow) {
          this.currentRowIndex = this.paymentInfoTabData.indexOf(this.selectedRow as PaymentTab);
          this.paymentInstructionDetailComponentService.selectedPaymentInstructionId = this.selectedRow.id ?? '';
          this.paymentInstructionDetailComponentService.selectedPaymentInstructionHistoryId =
            this.selectedRow.paymentInstructionHistoryId ?? '';
        }

        // Paging
        this.totalRecords = payload?.total ?? 0;
        if (payload?.pageNumber && payload.pageNumber >= 1) {
          this.pageNumber = payload.pageNumber;
        }
      });
  }

  handleResetData() {
    this.currentRowIndex = 0;
    this.totalRecords = 0;
    this.pageNumber = FIRST_PAGE;
    this.selectedRow = undefined;
    this.paymentInstructionDetailComponentService.selectedPaymentInstructionId = '';
    this.onSelectRow.emit(undefined);
  }

  getPaymentTypeName(paymentTab: PaymentTab) {
    if (shouldUseLumpsumpName(paymentTab?.paymentType)) {
      return paymentTab.lumpSumPaymentTypeName;
    }
    if (paymentTab.paymentInfoAdjustmentType) {
      return PaymentInfoAdjustmentType[PaymentInfoAdjustmentType.Adjustment];
    }
    const typeLabel = paymentTab.paymentType
      ? PaymentInstructionTypeLabel[paymentTab.paymentType]
      : paymentTab.lumpSumPaymentTypeName;
    return typeLabel;
  }

  getInstructionTypeName(instructionHistory: InstructionHistory) {
    if (instructionHistory.paymentInstructionType && shouldUseLumpsumpName(instructionHistory.paymentInstructionType)) {
      return instructionHistory.lumpSumPaymentTypeName;
    }
    if (instructionHistory.paymentInfoAdjustmentType) {
      return PaymentInfoAdjustmentType[PaymentInfoAdjustmentType.Adjustment];
    }
    const typeLabel = instructionHistory.paymentInstructionType
      ? PaymentInstructionTypeLabel[instructionHistory.paymentInstructionType]
      : instructionHistory.lumpSumPaymentTypeName;
    return typeLabel;
  }

  private getSelectedRow(paymentInstructionId = '', paymentInstructionHistoryId = ''): PaymentTab | undefined {
    const finderById = (id = '', historyId = '') =>
      this.paymentInfoTabData.find((x) => {
        if (!id && historyId) {
          return x.paymentInstructionHistoryId === historyId && x.payStatus === PayStatus.Finalized;
        }
        return x.id === id && (historyId ? x.paymentInstructionHistoryId === historyId : true);
      });

    // Navigate to a PI by its ID and PaymentInstructionHistoryId
    const targetPaymentInstruction = finderById(paymentInstructionId, paymentInstructionHistoryId);
    if (targetPaymentInstruction) return targetPaymentInstruction;

    // Otherwise, select the first record of the Payment Info Tab section
    return this.paymentInfoTabData[0];
  }

  selectGetInstructionHistoriesState() {
    this.store
      .select(getInstructionHistoriesSelector)
      .pipe(
        filter((res) => !!res),
        tap((res) => (this.isLoading = !!res?.isLoading)),
        filter((res) => !!res && !res.isLoading),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((state) => {
        this.store.dispatch(PayeeDetailAction.clearGetInstructionHistoryStateAction());
        if (!state?.success || state?.payload?.length === 0) {
          this.handleResetData();
          return;
        }

        this.instructionHistoryData =
          state?.payload?.map((p) => {
            const benefitPeriod =
              p.benefitPeriod !== '' &&
              p.paymentInstructionType !== PaymentInstructionType.PeriodicLumpsumPayment &&
              p.paymentInstructionType !== PaymentInstructionType.AutoOneTime
                ? p.benefitPeriod
                : ' - ';
            return {
              ...p,
              benefitPeriod,
              payableDate: p.payableDate !== '' ? p.payableDate : ' - ',
              updatedAt: getDateString(p.updatedAt ?? ''),
              statusName: p?.adjustmentStatus
                ? AdjustmentStatusLabel[p.adjustmentStatus]
                : PayStatusLabel[p.status ?? PayStatus.IssuePayment],
              paymentInstructionTypeName: this.getInstructionTypeName(p),
            };
          }) ?? [];
        this.totalRecords = state?.total ?? 0;
        this.selectedRow = this.getSelectedInstructionHistory();
        if (!this.selectedRow) {
          return;
        }
        this.currentRowIndex = this.instructionHistoryData.indexOf(this.selectedRow as InstructionHistory);
        this.onSelectRow.emit(this.selectedRow);
      });
  }

  getSelectedInstructionHistory() {
    const selectedRow = this.instructionHistoryData.find((item) => this.selectedRowId === item.id);
    if (selectedRow) {
      return selectedRow;
    }
    return this.instructionHistoryData[0];
  }

  private getPayStatusLabel(paymentTab: PaymentTab) {
    if (paymentTab.paymentInfoAdjustmentType) {
      return PaymentStatusAdjustmentType[paymentTab.payStatus as PaymentStatusAdjustmentType];
    }
    return PayStatusLabel[paymentTab.payStatus ?? PayStatus.IssuePayment];
  }

  selectCreateOneTimePaymentState() {
    this.store
      .select(createOneTimePaymentSelector)
      .pipe(
        filter((res) => !!res),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((state) => {
        if (state?.success) {
          this.paymentInstructionDetailComponentService.getAdjustmentDetailItemPosition(
            { paymentInstructionId: state.payload?.id },
            PositionPaymentInfoTabDetailedQueryType.PaymentInstruction,
          );
        }
        this.store.dispatch(clearCreateOneTimePaymentStateAction());
      });
  }

  changePaging(event: PageEvent) {
    this.pageSize = event.pageSize;
    this.pageNumber = event.pageNumber;
    sessionStorage.setItem(
      this.layoutService.currentFund$.value.key + '-' + this.pageName + '-pageNumber',
      this.pageNumber.toString(),
    );
    sessionStorage.setItem(
      this.layoutService.currentFund$.value.key + '-' + this.pageName + '-pageSize',
      this.pageSize.toString(),
    );
    super.onChangePage(event);
    this.getData();
  }

  selectRow(event: any & Row) {
    this.selectedRow = event;
    this.onSelectRow.emit({ selectedRow: event, manualSelect: true });
    this.paymentInstructionDetailComponentService.selectedPaymentInstructionHistoryId = '';
  }

  openAddDialog() {
    const dialogRef = this.dialog.open(AddOneTimeComponent, {
      panelClass: ['dialog-full-screen'],
      autoFocus: false,
      disableClose: true,
      data: {
        memberId: this.memberId,
        viewName: 'New One-time payment',
        isAnnuityBenefitEntity: this.selectedHeaderBenefit.benefitTypeName === 'Annuity Benefit',
        selectedHeaderBenefit: this.selectedHeaderBenefit,
        infoData: {
          payeeEntityId: this.entityReferenceLinkedId,
          payeeEntityRecordId: this.payeeRecordId,
        },
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.store.dispatch(createOneTimePaymentAction({ body: result }));
      }
    });
  }

  onClickNavigator(calculationId: string, calculationBenefitId: string = '', relatedMemberId: string = '') {
    const selectedPaymentCalculationType = this.selectedHeaderBenefit.calculationType as CalculationType;

    // Refund route is different from other calculation types
    if (selectedPaymentCalculationType === CalculationType.Refund) {
      this.router.navigateByUrl(
        `member/refunds-overview/${relatedMemberId}/calculation-detail/${calculationBenefitId}/${calculationId}`,
      );
      return;
    }
    // Disability route is different from other calculation types
    if (
      [CalculationType.DisabilityShortTerm, CalculationType.DisabilityLongTerm].includes(selectedPaymentCalculationType)
    ) {
      this.router.navigateByUrl(
        `member/disability-overview/${selectedPaymentCalculationType}/${relatedMemberId}/calculation-detail/${calculationBenefitId}/${calculationId}`,
      );
      return;
    }

    // Route for other calculation types
    this.router.navigateByUrl(
      `member/benefit-overview/${selectedPaymentCalculationType}/${relatedMemberId}/detail/${calculationId}`,
    );
  }

  onSelectedTabChange(event: MatTabChangeEvent) {
    if (event.index === 0) {
      this.columns = GRID_COLUMN_PAYMENT_INFO_TAB;
      this.selectedTabPayment = TabPaymentInfo.Payment;
    } else {
      this.columns = GRID_COLUMN_INSTRUCTION_HISTORY_TAB;
      this.selectedTabPayment = TabPaymentInfo.InstructionHistory;
    }
    this.currentRowIndex = 0;
    // Reset selectedRow to undefined to hide all view sections before getting new selectRow from Info Tab section
    this.selectedRow = undefined;
    this.paymentInstructionDetailComponentService.selectedPaymentInstructionId = '';
    this.onSelectRow.emit(undefined);
    this.onChangeTab.emit(this.selectedTabPayment);
    this.getData();
  }

  get isShowDetailedAdjustment() {
    if (!this.selectedRow) {
      return false;
    }
    if ('paymentType' in this.selectedRow && this.selectedRow.paymentType === PaymentInstructionType.Reissue) {
      return false;
    }
    return 'payStatus' in this.selectedRow && this.selectedRow.payStatus === PayStatus.Finalized;
  }

  get isShowReverseAdjustment(): boolean {
    if (!this.selectedRow) return false;

    const {
      paymentInfoAdjustmentType,
      adjustmentStatus,
      hasNotRejectReversal = false,
      isReversalAdjustment = false,
    } = this.selectedRow as PaymentTab;
    return (
      this.selectedTabPayment === TabPaymentInfo.Payment &&
      paymentInfoAdjustmentType === PaymentInfoAdjustmentType.Adjustment &&
      adjustmentStatus === PaymentStatusAdjustmentType.Approved &&
      !hasNotRejectReversal &&
      !isReversalAdjustment
    );
  }

  onOpenDetailedAdjustment() {
    const detailedAdjustmentDialog = this.dialog.open(NewDetailedAdjustmentComponent, {
      panelClass: ['edit-popup'],
      disableClose: true,
      minHeight: '810px',
      maxHeight: '1044px',
      minWidth: '1148px',
      autoFocus: false,
      data: {
        selectedRow: this.selectedRow,
        calculationType: this.selectedHeaderBenefit.calculationType,
        payeeRecordId: this.payeeRecordId,
      },
    });
    detailedAdjustmentDialog.afterClosed().subscribe((result: any) => {
      if (result) {
        const customMessage = result.resultStatus
          ? 'New Adjustment successfully added.'
          : 'Error occurred adding Adjustment. Please try again.';
        const resultStatus = result.resultStatus ? BannerType.Success : BannerType.Fail;
        this.changeBannerEmitter.emit({ customMessage, resultStatus });

        if (result.resultStatus) {
          this.paymentInstructionDetailComponentService.getAdjustmentDetailItemPosition({
            adjustmentId: result?.adjustmentId,
          });

          if (this.selectedHeaderBenefit.calculationType === CalculationType.QDRO) {
            const dialog = this.dialog.open(ConfirmPopupComponent, {
              panelClass: 'confirm-popup',
              data: {
                title: 'Warning',
                text: 'The Recurring Payment of alternate payee has been adjusted. Please check the corresponding payment of main payee to ensure its correctness as well',
                type: ConfirmType.Warning,
                cancelButtonTitle: 'Close',
                hideConfirmButton: true,
                hideSaveAsButton: true,
              },
            });

            setTimeout(() => {
              dialog.close();
            }, 15000);
          }
        }
      }
    });
  }

  onCreateGeneralAdjustment(): void {
    const createGeneralAdjustmentDialog: MatDialogRef<
      CreateGeneralAdjustmentComponent,
      CreateGeneralAdjustmentOutputData
    > = this.dialog.open<CreateGeneralAdjustmentComponent, CreateGeneralAdjustmentInputData>(
      CreateGeneralAdjustmentComponent,
      {
        panelClass: 'dialog-full-screen',
        disableClose: true,
        autoFocus: false,
        data: {
          memberId: this.memberId,
          paymentInstruction: this.selectedRow as PaymentTab,
          headerBenefits: this.selectedHeaderBenefit,
        },
      },
    );

    createGeneralAdjustmentDialog
      .afterClosed()
      .pipe(take(1), takeUntil(this.unsubscribe$))
      .subscribe((result) => {
        // Cancel dialog
        if (!result) {
          return;
        }
        const isSuccess = result.isSuccess;

        const customMessage: string = isSuccess
          ? 'New Adjustment successfully added.'
          : 'Error occurred adding Adjustment. Please try again.';
        this.changeBannerEmitter.emit({
          customMessage,
          resultStatus: result.isSuccess ? BannerType.Success : BannerType.Fail,
        });

        if (isSuccess) {
          this.paymentInstructionDetailComponentService.getAdjustmentDetailItemPosition({
            adjustmentId: result?.adjustmentId,
          });
        }
      });
  }

  onOpenReverseAdjustment(): void {
    const isConfirmed = showConfirmDialog(this.dialog, 'Do you want to reverse the selected adjustment?');

    isConfirmed
      .pipe(
        switchMap((isConfirmed) => {
          if (!isConfirmed) return EMPTY;
          this.store.dispatch(
            PayeeDetailAction.createReversalAdjustmentAction({
              params: { adjustmentId: (this.selectedRow as PaymentTab)?.id ?? '' },
            }),
          );

          return this.store.select(createReversalAdjustmentSelector).pipe(
            filter((res) => !!res && !res.isLoading),
            take(1),
            takeUntil(this.unsubscribe$),
          );
        }),
      )
      .subscribe((response) => {
        this.store.dispatch(PayeeDetailAction.clearCreateReversalAdjustmentState());

        const isSuccess = response?.success;

        // Response to View Detail screen to show banner message
        const customMessage: string = isSuccess
          ? 'A new Adjustment has been created to reverse the selected Adjustment. Please approve it for the reversal to take effect.'
          : 'Error occurred in reverse the selected Adjustment. Please try again.';
        this.changeBannerEmitter.emit({
          customMessage,
          resultStatus: isSuccess ? BannerType.Success : BannerType.Fail,
        });

        if (isSuccess) {
          this.paymentInstructionDetailComponentService.getAdjustmentDetailItemPosition({
            adjustmentId: response?.payload?.reversalAdjustmentId ?? '',
          });
        }
      });
  }

  onOpenOffsetDeduction() {
    this.dialog.open(CreateOffsetDeductionComponent, {
      panelClass: ['dialog-full-screen'],
      autoFocus: false,
      disableClose: true,
      data: {
        memberId: this.memberId,
        viewName: 'Create Offset Deduction',
        isAnnuityBenefitEntity: this.selectedHeaderBenefit.benefitTypeName === 'Annuity Benefit',
        isPeriodicLumpsumPaymentFinalized: this.isFinalizedPeriodicLumpSum,
        selectedHeaderBenefit: this.selectedHeaderBenefit,
        infoData: {
          payeeEntityId: this.entityReferenceLinkedId,
          payeeEntityRecordId: this.payeeRecordId,
        },
      },
    });
  }

  onOpenOverpaid() {
    this.dialog.open<MarkRemoveOverpaidComponent, MarkRemoveOverpaidDialogInput, MarkRemoveOverpaidDialogOutput>(MarkRemoveOverpaidComponent, {
      panelClass: 'edit-popup',
      disableClose: true,
      autoFocus: false,
      data: {
        selectedRow: this.selectedRow as PaymentTab
      },
      width: '905px',
      height: '444px',
    }).afterClosed().subscribe(result => {
      if (result) {
        if (result?.isSuccess) {
          // Re-select payment (Get latest selectedRow)
          this.getData();
        }
        const customMessage = result?.isSuccess
          ? `Transaction Overpaid status successfully updated.`
          : `Error occurred marking overpaid transaction. Please try again.`;
        const resultStatus = result?.isSuccess ? BannerType.Success : BannerType.Fail;
        this.changeBannerEmitter.emit({customMessage, resultStatus});
      }
    });
  }
}
