import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { select, Store } from '@ngrx/store';
import { EditDeductionsComponent } from '@ptg-member/features/payee-detail/components/edit-deductions/edit-deductions.component';

import { DeductionType } from '@ptg-processing/features/payroll-calendar-container/types/enums';
import { BannerType } from '@ptg-shared/controls/banner/types/banner.model';
import { Breadcrumb } from '@ptg-shared/types/models/breadcrumb.model';
import { Subject } from 'rxjs';
import { filter, takeUntil, tap } from 'rxjs/operators';
import { InstructionHistory, PaymentInfoTabLoadingStateHandler, PaymentTab } from '../../services/models';
import { DeductionsItems, PaymentDeductionsDetailResponse } from '../../services/models/payment-deductions.model';
import {
  clearGetPayeeDeductionsDetail,
  getPayeeDeductionsDetailAction,
  getPaymentDeductionsDetailState,
} from '../../store';
import * as fromPayeeDetail from '../../store/reducers/index';
import {
  ONE_TIME_PAYMENT_INSTRUCTION_TYPE,
  handlePaymentInfoTabLoadingState,
  isOneTimePayment,
} from '../../types/constants/payment-info-tab.constant';
import {
  PaymentInfoAdjustmentType,
  PaymentInstructionType,
  PaymentInstructionTypeOneTimeList,
  PayStatus,
  TabPaymentInfo,
} from '../../types/enums';
import { EditPaymentStatusService, GetPaymentInfoService } from '../../services';

@Component({
  selector: 'ptg-payment-deductions-section',
  templateUrl: './payment-deductions-section.component.html',
  styleUrls: ['./payment-deductions-section.component.scss'],
})
export class PaymentDeductionsSectionComponent
  implements OnInit, OnDestroy, OnChanges, PaymentInfoTabLoadingStateHandler
{
  readonly PaymentInstructionType = PaymentInstructionType;
  readonly DeductionTypeEnum = DeductionType;
  readonly PayStatus = PayStatus;
  readonly PaymentInfoAdjustmentType = PaymentInfoAdjustmentType;
  readonly TabPaymentInfo = TabPaymentInfo;
  deductionsInfo: PaymentDeductionsDetailResponse | undefined;
  deductions: DeductionsItems[] = [];
  deductionsQDRO: DeductionsItems[] = [];
  isLoading: boolean = false;

  isHavingAdjustments: boolean = false;

  unsubscribe$ = new Subject<void>();
  showEarning: boolean = true;
  isChicagoPark: boolean = false;
  isShowEditBtn: boolean = false;
  isHideTotalActive: boolean = false;
  isHistory: boolean = false;
  isOneTimePayment: boolean = false;
  isHiddenEffectiveDate: boolean = false;

  @Input() memberId: string = '';
  @Input() breadcrumbs: Breadcrumb[] | undefined;
  @Input() selectedRow!: PaymentTab & InstructionHistory;
  @Input() selectedTabPayment!: TabPaymentInfo;
  @Input() isEntityView: boolean = false;
  @Input() isEstablishBenefit: boolean = true;
  @Input() targetId?: string;
  @Input() benefitId?: string;
  @Input() reloadTime?: number;
  @Input() isTabHistory!: boolean;

  @Output() changeBannerEmitter = new EventEmitter<{
    customMessage?: string;
    resultStatus: BannerType;
    isErrorTaxCalculation?: boolean;
  }>();
  @Output() reloadTimeChange: EventEmitter<number> = new EventEmitter();
  @Output() reloadDataEmitter = new EventEmitter<string>();

  constructor(
    private payeeDetailStore: Store<fromPayeeDetail.PayeeDetailState>,
    private dialog: MatDialog,
    private getPaymentInfoService: GetPaymentInfoService,
    private editPaymentStatusService: EditPaymentStatusService,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.selectedRow) {
      this.isOneTimePayment = isOneTimePayment(this.selectedRow);
      this.isHiddenEffectiveDate = this.selectedRow.payStatus === PayStatus.Finalized || this.isOneTimePayment;
    }
    if (changes.selectedRow || changes.memberId || changes.reloadTime) {
      this.isHistory = this.selectedRow?.payStatus === PayStatus.Finalized;
      this.isShowEditBtn = this.onShowButtonEdit();

      this.isHideTotalActive =
        this.selectedRow?.payStatus === PayStatus.Finalized ||
        this.isOneTimePayment ||
        this.selectedRow.paymentInfoAdjustmentType === PaymentInfoAdjustmentType.Adjustment;

      const request = {
        paymentInstructionId: this.selectedRow.id ?? '',
        memberId: this.memberId,
        paymentInstructionHistoryId: this.selectedRow.paymentInstructionHistoryId ?? '',
        paymentInfoAdjustmentType: this.selectedRow.paymentInfoAdjustmentType,
        instructionStatusHistoryId: this.selectedRow.instructionStatusHistoryId ?? '',
      };
      this.payeeDetailStore.dispatch(getPayeeDeductionsDetailAction(request));
    }
  }

  onShowButtonEdit() {
    const paymentType = this.selectedRow.paymentType;
    if (paymentType === PaymentInstructionType.AllRecurring || paymentType === PaymentInstructionType.Recurring) {
      return this.selectedRow?.payStatus !== PayStatus.Finalized || !this.isTabHistory;
    }
    if (PaymentInstructionTypeOneTimeList.includes(paymentType!)) {
      return (
        paymentType !== PaymentInstructionType.Reissue ||
        this.selectedRow.payStatus !== PayStatus.Finalized ||
        !this.isTabHistory
      );
    }
    return false;
  }
  ngOnInit(): void {
    this.selectPaymentDeductionsDetail();
    handlePaymentInfoTabLoadingState(this, this.payeeDetailStore);
  }

  selectPaymentDeductionsDetail(): void {
    this.payeeDetailStore
      .pipe(
        select(getPaymentDeductionsDetailState),
        filter((res) => !!res),
        tap((res) => (this.isLoading = !!res?.isLoading)),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((res) => {
        let showError = '';
        if (res?.success) {
          this.payeeDetailStore.dispatch(clearGetPayeeDeductionsDetail());
          this.deductionsInfo = res?.payload;
          this.isHavingAdjustments = res.payload?.isHavingAdjustments ?? false;
          const deductionList = res.payload?.deductionList.filter((d) => d.showInViewDetail);
          this.deductions = (deductionList || [])
            .filter((it) => it.deductionType != DeductionType.QDRO)
            .map((item) => {
              if (
                item.deductionType === DeductionType.Tax &&
                (!item.isFederalTaxCalculationSuccess || !item.isStateTaxCalculationSuccess)
              ) {
                showError = 'Error occurred calculating Tax Deduction Amounts. Tax Bracket cannot be determined. Please try again and contact support if needed.';
              }
              const label =
                item.deductionType === DeductionType.Tax ||
                item.deductionType === DeductionType.Insurance ||
                item.deductionType === DeductionType.Others
                  ? `${item.deductionCode} - ${item.labelName}`
                  : item.labelName;
              return {
                ...item,
                labelDisplay: label,
              };
            });
          this.deductionsQDRO = (deductionList || [])
            .filter((it) => it.deductionType === DeductionType.QDRO)
            .map((item) => {
              return {
                ...item,
                labelDisplay: `${item.labelName ?? ''} (${item.payeeName ?? ''})`,
              };
            });
          this.getPaymentInfoService.setDeductionInfo(
            [...this.deductions, ...this.deductionsQDRO].filter((deduction) => deduction.showInViewDetail),
          );
          if (this.selectedRow.paymentType === PaymentInstructionType.InitialPayment || this.selectedRow.paymentType === PaymentInstructionType.Recurring) {
            this.editPaymentStatusService.checkValidUnsuspend({benefitCode: this.benefitId || '', isCheckPaymentAddressValid: false}).subscribe((el: any) => {
              if (el?.message) {
                this.handleShowBanner(el?.message);
              } else {
                this.handleShowBanner(showError);
              }
            })
          } else {
            this.handleShowBanner(showError);
          }
        }
      });
  }

  handleShowBanner(isShowBanner: string) {
    const message = isShowBanner || '';
    this.changeBannerEmitter.emit({
      resultStatus: isShowBanner ? BannerType.Fail : BannerType.Hidden,
      customMessage: message,
      isErrorTaxCalculation: true,
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  onClickEdit() {
    const dialog = this.dialog.open(EditDeductionsComponent, {
      panelClass: ['dialog-full-screen'],
      autoFocus: false,
      disableClose: true,
      data: {
        paymentInstructionId: this.selectedRow.id,
        memberId: this.memberId,
        selectedPayment: this.selectedRow as PaymentTab,
        isRecurringRecord:
          this.selectedRow?.paymentType === PaymentInstructionType.Recurring ||
          this.selectedRow?.paymentType === PaymentInstructionType.AllRecurring,
        isOTPRecord: this.selectedRow.paymentType
          ? ONE_TIME_PAYMENT_INSTRUCTION_TYPE.includes(this.selectedRow.paymentType)
          : false,
      },
    });
    dialog
      .afterClosed()
      .pipe()
      .subscribe((result: any) => {
        if (result?.needReload) {
          this.changeBannerEmitter.emit({
            resultStatus: BannerType.Success,
            customMessage: this.selectedRow?.paymentType === PaymentInstructionType.InitialPayment && result.isChangeAmountOfPreTax ? 'Deductions successfully updated. Please be aware of federal withholding which might need to be recalculated.' : 'Deductions successfully updated.',
          });
          this.reloadDataEmitter.emit('payment-info-tab');
        }
      });
  }

  shouldShowTooltip(deductionsItem: DeductionsItems): boolean {
    if (!deductionsItem.deductionType) {
      return false;
    }

    if (this.selectedRow.payStatus !== PayStatus.Finalized) {
      if( deductionsItem.deductionType === DeductionType.Adjustment ){
        return (
          this.selectedRow.paymentType === PaymentInstructionType.AllRecurring ||
          this.selectedRow.paymentType === PaymentInstructionType.Recurring
        );
      }
      if (this.isOneTimePayment) {
        return (
          deductionsItem.deductionType === DeductionType.Garnishment || deductionsItem.deductionType === DeductionType.QDRO
        );
      }
      return deductionsItem.deductionType !== DeductionType.Tax;
    }

    return (
      deductionsItem.deductionType === DeductionType.Garnishment || deductionsItem.deductionType === DeductionType.QDRO
    );
  }
}
