import { PayeeFilterType } from './types/enums';

export const CHECK_NUMBER_ERROR_TYPE = 'InvalidStartingCheckNumberException';
export const CHECK_NUMBER_DEFAULT_ERROR = 'Error occurred updating Starting Check Number. Please try again.';

export const DEFAULT_FULL_DATETIME = 'MM/dd/yyyy hh:mm a';
export const ACH_CONFIRM_MESSAGE =
  'Are you sure you want to confirm ACH file has been sent? This action cannot be undone.';
export const CHECK_FILE_CONFIRM_MESSAGE =
  'Are you sure you want to confirm checks file has been printed? This action cannot be undone.';

export const PAYEE_FILTER_LABELS = {
  [PayeeFilterType.Recurring]: 'Recurring',
  [PayeeFilterType.Added]: 'Added',
  [PayeeFilterType.Suspend]: 'Suspend',
  [PayeeFilterType.Terminated]: 'Terminated',
  [PayeeFilterType.InitialPayment]: 'Initial Payment',
  [PayeeFilterType.Correction]: 'Correction',
  [PayeeFilterType.Reissue]: 'Reissue',
  [PayeeFilterType.FinalPayment]: 'Final Payment',
  [PayeeFilterType.PeriodicLumpsumPayment]: 'Periodic Lumpsum Payment',
  [PayeeFilterType.AutoOneTime]: 'LODD Death Payment',
};

export const enum CheckedType {
  AchSent,
  CheckPrinted
}
