export const SUBMODULE_URL: any = {
  EMPLOYER_LIST: '/employer/employer-management',
  PARTICIPANT_LIST: '/member',
  ESTABLISH_BENEFIT: '/member/benefit',
  PENSIONESTIMATOR: '/estimator',
  COMMUNICATIONS: 'fund-management/notification',
  BULK_CONTENT_UPDATES: '/member/bulk/demographics',
  ACCESS_MANAGEMENT: '/access-management/account',
  EXTERNAL_PAYMENT: '/admin/disbursement-report',
  RESOURCE_CENTER: '/fund-management/resource-center',
  APP_CONTENT_MANAGEMENT: '/fund-management/navigation',
  PAS_MANAGEMENT: '/fund-management/theme',
  ORGANIZATION_MANAGEMENT: '/organization',
  PROCESSING: '/processing',
  RECURRING_PAYMENTS: '/processing/next-payroll-list',
  LUMP_SUM_PAYMENTS: '/processing/off-cycle-payments',
  TRANSACTION_REGISTER: '/processing/transaction-register',
  PARTICIPANT_CONTRIBUTIONS: '/processing/remittance-posting',
  REPORTS: '/processing/report/register-summary',
  PARTICIPANT_REPORT: 'member/report',
  REMITTANCE_SUBMISSION: '/employer/remittance-submission',
  DOCUMENT_CONFIGURATION: '/admin/documents',
  DOCUMENTS: '/member/document-list',
  PENDING_PAYMENTS: '/processing/pending-payments',
  SUSPENDED_PAYMENTS: '/processing/suspended-payments',
  RESOLVED_SUSPENDED_PAYMENTS: '/processing/resolved-suspended-payments',
  ONE_TIME_PAYMENTS: 'processing/one-time-register',
  ONETIME_PAYMENTS_PENDING_PAYMENTS: '/processing/otp-pending-payments',
  EMPLOYER_DOCUMENT: 'employer_document',
  MUNICIPALITY_REFUND: '/processing/municipality-refund',
  OST_INTEGRATION: '/admin/ost-integrations/office-of-state-treasurer',
  LEAD_TIME_AND_WAITING_TIME: '/admin/ost-integrations/lead-time-and-waiting-time',
  OST_INPUT_FILE_REGISTER: '/admin/ost-integrations/ost-input-file-register',
};

export const PERMISSION_SUB_MODULE_URL: any = {
  EMPLOYER_LIST: [
    '/employer/employer-management',
    '/employer/employer-overview-configuration',
    '/employer/employer-information-configuration',
    '/employer/employer-list-configuration',
    '/employer/detail',
    '/employer/department-management',
    '/employer/reporting-calendar',
    '/employer/employer-file-layout-configuration',
    '/employer/wage-processing',
    '/employer/parameters',
    '/employer/tolerance/parameters',
    '/employer/contribution-interest-rate',
    '/employer/fee-amounts',
    '/employer/document/generated-document/view',
    '/employer/report-transactions',
    '/employer/report-service-list',
    '/employer/report-cash-journal',
    '/employer/transactions',
    '/employer/annual-certification',
    '/employer/annual-certification-history',
    '/employer/remittance-submission',
    '/employer/remittance-submission-history',
    '/employer/report/annual-certification',
    '/employer/report/pension-report/',
    '/employer/report/relief-report/',
    '/employer/annual-fee-letter',
    '/employer/report-disbursement-summary',
    '/employer/late-fee-reminder-letter',
    '/employer/document-list',
    '/employer/individual-document/',

    // TODO: Need apply permission for entity module
    '/entity-management/entities',
    '/entity-management/entities/entity',
    '/entity-management/entities/view',
    '/entity-management/entity-list-view-configuration',
    '/entity-management/entities/cards',
    '/entity-management/benefit-mapping',
  ],
  PARTICIPANT_LIST: [
    { url: '/member', strict: true },
    '/member/drop-plan',
    '/member/navigation',
    '/member/detail',
    '/member/summary-view',
    '/member/system-view/participant-relationships',
    '/member/system-view/payment-information',
    '/member/system-view/status-history',
    '/member/system-view/address-history',
    '/member/system-view/service-history',
    '/member/system-view/deposit-account',
    '/member/system-view/payments',
    '/member/system-view/',
    '/member/detail-view',
    '/member/opt-in',
    '/member/retirement-benefit',
    '/member/benefit-overview',
    '/member/buyback-makeup-overview',
    '/member/accident',
    '/member/calculation/retirement-benefit-calculation-list',
    '/member/calculation/retirement-benefit-calculation',
    '/member/calculation/retirement-benefit-input-outputs',
    '/member/calculation/step-configuration',
    '/member/calculation/calculation-parameter-configuration',
    '/member/calculation/benefit-calculation-configuration',
    '/member/calculation/exception-configuration',
    '/member/calculation/subheader-configuration',
    '/member/profiles',
    '/member/card-list',
    '/member/view',
    '/member/card',
    '/member/lookup-table',
    '/member/disability-overview/',
    '/member/participant-search-configuration/',
    '/member/refunds-overview/',
    '/member/distribution-code-mapping/',
    '/entity-management/entities/',
    '/entity-management/benefit-mapping',
    '/member/manage-status-history',
  ],
  ESTABLISH_BENEFIT: ['/member/benefit'],
  PENSION_ESTIMATOR: ['/estimator'],
  DOCUMENTS: [
    '/employer/document-list',
    '/employer/individual-document/',
    '/employer/generated-document',
    '/member/document-list',
    '/member/individual-document/',
    '/member/generated-document',
    '/member/generate-age65-letter',
  ],
  COMMUNICATIONS: ['/fund-management/notification', '/fund-management/notification/opt-in-message'],
  BULK_CONTENT_UPDATES: ['/member/bulk'],
  ACCESS_MANAGEMENT: [
    '/access-management/account',
    '/access-management/roles',
    '/access-management/employer-portal-roles',
  ],
  EXTERNAL_PAYMENT: ['admin/disbursement-report', 'admin/vendor'],
  RESOURCE_CENTER: ['/fund-management/resource-center'],
  APP_CONTENT_MANAGEMENT: [
    '/fund-management/navigation',
    '/fund-management/preferences',
    '/page',
    '/fund-management/app-content',
  ],
  PAS_MANAGEMENT: ['/fund-management/theme', '/fund-management/stripe'],
  ORGANIZATION_MANAGEMENT: [
    '/organization/board-management',
    '/organization/board-member',
    '/organization/banks-account',
    '/organization/bank-assignments',
  ],
  OST_INTEGRATION: [
    '/admin/ost-integrations/office-of-state-treasurer',
    '/admin/ost-integrations/lead-time-and-waiting-time',
    '/admin/ost-integrations/ost-input-file-register',
  ],
  DOCUMENT_CONFIGURATION: ['/admin/documents/tags', '/admin/documents/parameters', '/admin/documents/templates'],
  RECURRING_PAYMENTS: [
    '/processing/next-payroll-list',
    '/processing/payroll-register',
    '/processing/next-payroll',
    '/processing/federal-taxes',
    '/processing/banking-list',
    '/processing/payment-deduction-payee',
    '/processing/earning-list',
    '/processing/payment-deductions',
    '/processing/payment-information',
    '/processing/payroll-calendar',
    '/processing/multiple-bank-account',
    '/processing/bank-holiday',
    '/processing/blackout-date',
    '/processing/config-cut-off-date',
    '/processing/manage-calendar',
    '/processing/payroll-structure',
    '/processing/payroll-display',
  ],
  LUMP_SUM_PAYMENTS: ['/processing/off-cycle-payments', '/processing/lump-sum-payment-history'],
  PENDING_PAYMENTS: [
    '/processing/pending-payments',
    '/processing/municipality-refund',
    '/processing/suspended-payments',
    '/processing/resolved-suspended-payments',
  ],
  ONE_TIME_PAYMENTS: [
    '/processing/one-time-register',
    '/processing/otp-payroll-config',
    '/processing/lead-time-config',
    '/processing/otp-pending-payments',
    '/processing/otp-complete-payment',
  ],
  DEDUCTION_PAYEE_PAYMENTS: [
    '/processing/dp-checks-and-direct-deposit',
    '/processing/dp-checks-and-direct-deposit-complete',
    '/processing/wire-transfer-and-internal-payment',
    '/processing/wire-transfer-and-internal-payment-complete',
    '/processing/deduction-payee-register',
  ],
  REPORTS: ['/processing/report'],
  TRANSACTION_REGISTER: ['processing/transaction-register'],
  PARTICIPANT_CONTRIBUTIONS: ['/processing/remittance-posting'],
  PROCESSING: [{ url: '/processing', strict: true }],
  PARTICIPANT_REPORT: ['/member/report'],
  ENTITY_MANAGEMENT: [
    '/entity-management/entities',
    '/entity-management/entities/entity',
    '/entity-management/entities/view',
    '/entity-management/entities/cards',
    '/entity-management/benefit-mapping',
  ],
};

export const PERMISSION_KEY: Record<string, string> = {
  EMPLOYERS: 'employers',
  PARTICIPANTS: 'participants',
  PARTICIPANT_LIST: 'participant_list',
  PENSION_ESTIMATOR: 'pension_estimator',
  ESTABLISH_BENEFIT: 'establish_benefit',
  COMMUNICATIONS: 'communications',
  BULK_CONTENT_UPDATES: 'bulk_content_updates',
  RECURRING_PAYMENTS: 'recurring_payments',
  TRANSACTION_REGISTER: 'transaction_register',
  LUMP_SUM_PAYMENTS: 'lump_sum_payments',
  PENDING_PAYMENTS: 'pending_payments',
  ONE_TIME_PAYMENTS: 'one_time_payments',
  DEDUCTION_PAYEE_PAYMENTS: 'deduction_payee_payments',
  REPORTS: 'reports',
  PARTICIPANT_CONTRIBUTIONS: 'participant_contributions',
  ADMIN: 'admin',
  APP_CONTENT: 'app',
  RESOURCE_CENTER: 'resource_center',
  ACCESS_CONTROL: 'access_management',
  EXTERNAL_PAYMENT: 'external_payment',
  PAS_MANAGEMENT: 'pas_management',
  ORGANIZATION_MANAGEMENT: 'organization_management',
  PENSION_APP: 'pensionapp',
  MEMBER_IMPERSONATION: 'participant_impersonation',
  PROCESSING: 'processing',
  PARTICIPANT_REPORT: 'participant_report',
  DROP_PLAN: 'drop_plan',
  DOCUMENT_CONFIGURATION: 'document_configuration',
  DOCUMENTS: 'documents',
  EMPLOYER_DOCUMENT: 'employer_document',
  OST_INTEGRATION: 'OST_Integration'
};

export enum ModuleKey {
  Employers = 'employers',
  Participants = 'participants',
  Processing = 'processing',
  App = 'app',
  Admin = 'admin',
  PensionApp = 'pensionapp',
}

export enum SUBMODULE_KEY {
  EMPLOYER_LIST = 'employer_list',
  PARTICIPANT_LIST = 'participant_list',
  ESTABLISH_BENEFIT = 'establish_benefit',
  PENSION_ESTIMATOR = 'pension_estimator',
  COMMUNICATIONS = 'communications',
  BULK_CONTENT_UPDATES = 'bulk_content_updates',
  RECURRING_PAYMENTS = 'recurring_payments',
  TRANSACTION_REGISTER = 'transaction_register',
  LUMP_SUM_PAYMENTS = 'lump_sum_payments',
  PENDING_PAYMENTS = 'pending_payments',
  REPORTS = 'reports',
  PARTICIPANT_CONTRIBUTIONS = 'participant_contributions',
  APP_CONTENT_MANAGEMENT = 'app_content_management',
  ACCESS_MANAGEMENT = 'access_management',
  EXTERNAL_PAYMENT = 'external_payment',
  RESOURCE_CENTER = 'resource_center',
  ORGANIZATION_MANAGEMENT = 'organization_management',
  PAS_MANAGEMENT = 'pas_management',
  PARTICIPANT_IMPERSONATION = 'participant_impersonation',
  PARTICIPANT_REPORT = 'participant_report',
  DROP_PLAN = 'drop_plan',
  DOCUMENT_CONFIGURATION = 'document_configuration',
  DOCUMENTS = 'documents',
  EMPLOYER_DOCUMENT = 'employer_document',
  ONE_TIME_PAYMENTS = 'one_time_payments',
  DEDUCTION_PAYEE_PAYMENTS = 'deduction_payee_payments',
  OST_INTEGRATION = 'OST_Integration'
}

export const SUBMODULE_GROUP_BY_MODULE: Record<ModuleKey, string[]> = {
  [ModuleKey.Employers]: [SUBMODULE_KEY.EMPLOYER_LIST],
  [ModuleKey.Participants]: [
    SUBMODULE_KEY.PARTICIPANT_LIST,
    SUBMODULE_KEY.ESTABLISH_BENEFIT,
    SUBMODULE_KEY.PENSION_ESTIMATOR,
    SUBMODULE_KEY.COMMUNICATIONS,
    SUBMODULE_KEY.BULK_CONTENT_UPDATES,
    SUBMODULE_KEY.PARTICIPANT_REPORT,
    SUBMODULE_KEY.DOCUMENTS,
  ],
  [ModuleKey.Processing]: [
    ModuleKey.Processing,
    SUBMODULE_KEY.RECURRING_PAYMENTS,
    SUBMODULE_KEY.PENDING_PAYMENTS,
    SUBMODULE_KEY.LUMP_SUM_PAYMENTS,
    SUBMODULE_KEY.REPORTS,
    SUBMODULE_KEY.TRANSACTION_REGISTER,
    SUBMODULE_KEY.PARTICIPANT_CONTRIBUTIONS,
    SUBMODULE_KEY.ONE_TIME_PAYMENTS,
    SUBMODULE_KEY.DEDUCTION_PAYEE_PAYMENTS
  ],
  [ModuleKey.App]: [SUBMODULE_KEY.APP_CONTENT_MANAGEMENT],
  [ModuleKey.Admin]: [
    SUBMODULE_KEY.ACCESS_MANAGEMENT,
    SUBMODULE_KEY.EXTERNAL_PAYMENT,
    SUBMODULE_KEY.RESOURCE_CENTER,
    SUBMODULE_KEY.ORGANIZATION_MANAGEMENT,
    SUBMODULE_KEY.DOCUMENT_CONFIGURATION,
    SUBMODULE_KEY.PAS_MANAGEMENT,
    SUBMODULE_KEY.OST_INTEGRATION,
  ],
  [ModuleKey.PensionApp]: [SUBMODULE_KEY.PARTICIPANT_IMPERSONATION],
};
