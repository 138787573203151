import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, delay, map, switchMap } from 'rxjs/operators';

import { GetVoidedReasonDetailsResponse, GetVoidedReasonsResponse, SummaryList } from '../../services/models/register.model';
import { RegisterService } from '../../services/register.service';
import {
  createReissueTransactionsAction,
  createReissueTransactionsFailureAction,
  createReissueTransactionsSuccessAction,
  createRejectedTransactionsAction,
  createRejectedTransactionsFailureAction,
  createRejectedTransactionsSuccessAction,
  downloadTransactionRegister,
  downloadTransactionRegisterFailure,
  downloadTransactionRegisterSuccess,
  exportTransactionRegister,
  exportTransactionRegisterFailure,
  getPayrollBenefitTypesAction,
  getPayrollBenefitTypesFailureAction,
  getPayrollBenefitTypesSuccessAction,
  getSummaryListAction,
  getSummaryListFailureAction,
  getSummaryListSuccessAction,
  getVoidedReasonDetailsAction,
  getVoidedReasonDetailsFailureAction,
  getVoidedReasonDetailsSuccessAction,
  getVoidedReasonsAction,
  getVoidedReasonsFailureAction,
  getVoidedReasonsSuccessAction,
} from '../actions/register.action';
import { downloadFile } from '@ptg-shared/utils/common.util';
import { MemberListService } from '@ptg-member/services/member-list.service';

@Injectable()
export class RegisterEffect {
  constructor(
    private actions$: Actions,
    private registerService: RegisterService,
    private memberListService: MemberListService
  ) {}

  getListTransaction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getSummaryListAction),
      switchMap(({ query }) => {
        return this.registerService.getSummaryList(query).pipe(
          map((summaryList: SummaryList) => {
            return getSummaryListSuccessAction({ summaryList });
          }),
          catchError((error) => {
            return of(getSummaryListFailureAction({ error }));
          }),
        );
      }),
    ),
  );

  createRejectedTransactions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createRejectedTransactionsAction),
      switchMap(({ request }) =>
        this.registerService.createRejectedTransactions(request).pipe(
          map(() => createRejectedTransactionsSuccessAction()),
          catchError((error) => of(createRejectedTransactionsFailureAction({ error }))),
        ),
      ),
    ),
  );

  getVoidedReasons$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getVoidedReasonsAction),
      switchMap(({ request, query}) =>
        this.registerService.getVoidedReasons(request, query).pipe(
          map((response: GetVoidedReasonsResponse) => getVoidedReasonsSuccessAction({ response })),
          catchError((error) => of(getVoidedReasonsFailureAction({ error }))),
        ),
      ),
    ),
  );

  getVoidedReasonDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getVoidedReasonDetailsAction),
      switchMap(({ request }) =>
        this.registerService.getVoidedReasonDetails(request).pipe(
          map((response: GetVoidedReasonDetailsResponse) => getVoidedReasonDetailsSuccessAction({ response })),
          catchError((error) => of(getVoidedReasonDetailsFailureAction({ error })))
        )
      )
    )
  );

  createReissueTransactions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createReissueTransactionsAction),
      switchMap(({ request }) =>
        this.registerService.createReissueTransactions(request).pipe(
          map((id) => {
            return createReissueTransactionsSuccessAction({ id });
          }),
          catchError((error) => of(createReissueTransactionsFailureAction({ error }))),
        ),
      ),
    ),
  );

  getPayrollBenefitTypes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getPayrollBenefitTypesAction),
      switchMap(() =>
        this.registerService.getPayrollBenefitTypes().pipe(
          map((response) => getPayrollBenefitTypesSuccessAction({ response })),
          catchError((error) => of(getPayrollBenefitTypesFailureAction({ error }))),
        ),
      ),
    ),
  );

  exportMemberFilter$ = createEffect(() =>
    this.actions$.pipe(
      ofType(exportTransactionRegister),
      switchMap(({ request }) => {
        return this.registerService.exportTransactionRegister(request).pipe(
          map((data: any) => {
            if (data?.FileName) {
              return downloadTransactionRegister({
                fileName: data.FileName,
              });
            } else {
              return exportTransactionRegisterFailure();
            }
          }),
          catchError(() => {
            return of(exportTransactionRegisterFailure());
          })
        );
      })
    )
  );

  downloadMemberFilter$ = createEffect(() =>
    this.actions$.pipe(
      ofType(downloadTransactionRegister),
      delay(2500),
      switchMap(({ fileName }) => {
        return this.memberListService.downloadExportFile(fileName).pipe(
          map((res: Blob) => {
            if (res) {
              downloadFile.call(this, res, fileName);
              return downloadTransactionRegisterSuccess();
            } else {
              return downloadTransactionRegister({ fileName });
            }
          }),
          catchError(() => {
            return of(downloadTransactionRegisterFailure());
          })
        );
      })
    )
  );
}
